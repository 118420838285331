import { CalciteLabel, CalciteSwitch } from '@esri/calcite-components-react';
import { Controller } from 'react-hook-form';

const ControlledSwitch = (props) => (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
            <CalciteLabel layout="inline">
                <CalciteSwitch
                    checked={field.value || undefined}
                    {...field}
                    onCalciteSwitchChange={(e) => {
                        field.onChange(e.target.checked);
                        field.onBlur(e.target.checked);
                    }}
                />
                {props.label}
            </CalciteLabel>
        )}
    />
);

export default ControlledSwitch;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.searchOverlay-appear {
    opacity: 0;
}

.searchOverlay-appear-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.searchOverlay-enter {
    opacity: 0;
}

.searchOverlay-enter-active {
    opacity: 1;
    transition: opacity 1000ms;
}

.searchOverlay-exit {
    opacity: 1;
}

.searchOverlay-exit-active {
    opacity: 0;
    transition: opacity 1000ms;
}`, "",{"version":3,"sources":["webpack://./components/controls/overlaysearch/style.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,0BAA0B;AAC9B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,0BAA0B;AAC9B;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;IACV,0BAA0B;AAC9B","sourcesContent":[".searchOverlay-appear {\n    opacity: 0;\n}\n\n.searchOverlay-appear-active {\n    opacity: 1;\n    transition: opacity 1000ms;\n}\n\n.searchOverlay-enter {\n    opacity: 0;\n}\n\n.searchOverlay-enter-active {\n    opacity: 1;\n    transition: opacity 1000ms;\n}\n\n.searchOverlay-exit {\n    opacity: 1;\n}\n\n.searchOverlay-exit-active {\n    opacity: 0;\n    transition: opacity 1000ms;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

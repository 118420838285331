import { CalciteIcon } from '@esri/calcite-components-react';
import { JsonEditor } from 'json-edit-react';

const ConsoleJsonEditor = (props) => (
    <JsonEditor
        minWidth="100%"
        maxWidth="100%"
        icons={{
            copy: <CalciteIcon scale="s" icon="copy-to-clipboard" />,
            add: <CalciteIcon scale="s" icon="plus-circle" />,
            edit: <CalciteIcon scale="s" icon="pencil-square" />,
            delete: <CalciteIcon scale="s" icon="minus-circle" />,
            ok: <CalciteIcon scale="s" icon="check-circle-f" />,
            cancel: <CalciteIcon scale="s" icon="x-circle-f" />,
            chevron: <CalciteIcon scale="s" icon="chevron-down" />,
        }}
        {...props}
    />
);

export default ConsoleJsonEditor;

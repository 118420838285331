import {
    CalciteAction,
    CalciteBlock, CalciteButton, CalcitePanel,
} from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { wktToGeoJSON } from '@terraformer/wkt';
import { feature, featureCollection } from '@turf/helpers';

import MapPanel from '../MapPanel';
import ControlledInputText from '../UI/ControlledInputText';
import ControlledSwitch from '../UI/ControlledSwitch';
import ControlledComboBox from '../UI/ControlledComboBox';
import {
    setArea, setUpdateAreaDialogState, useGetArea, useGetClients,
} from '../../../../stores/clients';
import { clearConfirmationDialog, updateConfirmationDialog } from '../../../../stores/dialogs';
import { useGetEnvironment } from '../../../../stores/environment';
import {
    useArea, useAreaSize, useDeleteArea, useStatuses, useUpdateArea,
} from '../../../../queries/els/queries';

const validationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .test('Name-test', 'Name needs to be more than 5 characters', (value) => value.trim().length > 5),
});

const UpdateArea = () => {
    const env = useGetEnvironment();

    const { areaId } = useGetArea();

    const updateArea = useUpdateArea(areaId);
    const deleteArea = useDeleteArea();

    const { statuses, statusesIsFetching } = useStatuses(env);
    const { area, areaIsFetching } = useArea(env, areaId);
    const { client } = useGetClients();

    const [areaWKT, setAreaWKT] = useState(null);
    const { areaSizeIsFetching, areaSize } = useAreaSize(env, areaWKT, 'wkt');

    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: area,
    });

    useEffect(() => {
        if (!area) return;

        const geometry = wktToGeoJSON(area.area);
        const geojson = featureCollection([feature(geometry)]);
        setArea(geojson);
        setAreaWKT(area.area);

        setValue('name', area.name);
        setValue('enabled', area.enabled);
        setValue('areaStatusId', area.areaStatusId);
    }, [area, getValues, setValue]);

    const saveArea = async () => {
        const result = await trigger();
        if (!result) {
            return;
        }

        const { name, enabled, areaStatusId } = getValues();

        updateArea({
            env, areaId, name, enabled, areaStatusId: Array.isArray(areaStatusId) ? areaStatusId[0] : areaStatusId,
        });
    };

    const removeArea = () => {
        updateConfirmationDialog(
            'Confirm Delete',
            `Are you sure you want to delete ${area.name}?`,
            'danger',
            'Delete',
            () => {
                deleteArea({ env, areaId });
                clearConfirmationDialog();
                setUpdateAreaDialogState(null);
            },
        );
    };

    const close = () => {
        setUpdateAreaDialogState(null);
    };

    return (
        <CalcitePanel
            heading={'Update Area'}
            description={area ? `${area.name} for ${client.name}` : 'Loading...'}
            loading={areaIsFetching || undefined} >
            <CalciteAction
                slot="header-actions-end"
                loading={areaSizeIsFetching || undefined}
                text={areaSizeIsFetching ? 'Calculating Area Size...' : areaSize ? `Area Size: ${areaSize.toLocaleString()} m²` : 'Calculate Area Size'}
                icon="select-visible"
                textEnabled={true} />
            <CalciteBlock open={true}>
                <ControlledInputText
                    name="name"
                    control={control}
                    required={true}
                    label="Area Name"
                    placeholder="Area Name"
                />
                {!statusesIsFetching && statuses && (
                    <ControlledComboBox
                        name="areaStatusId"
                        control={control}
                        clearDisabled={true}
                        placeholder="Status"
                        label="Status"
                        icon="automation"
                        options={statuses}
                        selectionMode="single-persist"
                    />
                )}
                <ControlledSwitch
                    name="enabled"
                    control={control}
                    label="Enabled"
                />
            </CalciteBlock>
            <CalciteButton slot="footer-start" kind="neutral" onClick={close}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-end" kind="danger" onClick={removeArea}>
                Delete Area
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={saveArea}>
                Save
            </CalciteButton>
            <MapPanel />
        </CalcitePanel>
    );
};

export default UpdateArea;

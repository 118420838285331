export const findClient = (clients, clientId) => {
    if (!clients || !clientId) return null;

    let client = clients.find((c) => c.clientId === clientId);
    if (client) return client;

    client = clients
        .map((c) => findClient(c.clients, clientId))
        .find((c) => c);

    if (client) return client;

    return null;
};

import { CalciteInputMessage, CalciteLabel, CalciteTextArea } from '@esri/calcite-components-react';
import { Controller } from 'react-hook-form';
import RequiredIcon from './RequiredIcon';

const ControlledInputTextArea = (props) => (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field, fieldState }) => (
            <CalciteLabel>
                <span>
                    {props.label}
                    {props.required && (<RequiredIcon />)}
                </span>
                <CalciteTextArea
                    autocomplete="off"
                    icon={props.icon}
                    placeholder={props.placeholder}
                    disabled={props.disabled || undefined}
                    {...field}
                    onCalciteTextAreaInput={(e) => {
                        field.onChange(e);
                        field.onBlur(e);
                    }}
                />
                {fieldState.error && (
                    <CalciteInputMessage status="invalid" icon="exclamation-mark-triangle-f">{fieldState.error.message}</CalciteInputMessage>
                )}
            </CalciteLabel>
        )}
    />
);

export default ControlledInputTextArea;

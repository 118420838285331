import { createQueryKeys } from '@lukemorales/query-key-factory';
import { getWithHeaders, post } from '../../services/webhelpers';

export const elsClientsQueryKeys = createQueryKeys('elsClients', {
    all: (env) => ({
        queryKey: [env],
        queryFn: () => getWithHeaders('/ELS/Clients', { env }),
    }),
});

export const elsStatusesQueryKeys = createQueryKeys('elsStatuses', {
    all: (env) => ({
        queryKey: [env],
        queryFn: async () => {
            const data = await getWithHeaders('/ELS/Statuses', { env });
            return data?.map(({ areaStatusId, name }) => ({ value: `${areaStatusId}`, label: name }));
        },
    }),
});

export const elsAreaQueryKeys = createQueryKeys('elsArea', {
    detail: (env, areaId) => ({
        queryKey: [env, areaId],
        queryFn: () => getWithHeaders(`/ELS/Areas/${areaId}`, { env }),
    }),
    shape: (env, payload, type) => ({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: [env, payload, type],
        queryFn: () => {
            if (!payload || !type) {
                return null;
            }
            if (type === 'geojson') {
                return post('/ELS/Areas/Shape', { payload: JSON.stringify(payload.features[0]), type: 'feature' }, { env });
            }
            return post('/ELS/Areas/Shape', { payload, type }, { env });
        },
    }),
});

export const elsChartsQueryKeys = createQueryKeys('elsCharts', {
    inbound: (env, back, type, interval) => ({
        queryKey: [env, back, type, interval],
        queryFn: () => {
            if (!env || !back || !type || !interval) {
                return null;
            }
            return post('/ELS/Clients/Metrics/Inbound', { back, type, interval }, { env });
        },
    }),
    outbound: (env, clientId, back, type, interval) => ({
        queryKey: [env, clientId, back, type, interval],
        queryFn: () => {
            if (!env || !back || !type || !interval) {
                return null;
            }
            return post('/ELS/Clients/Metrics/Outbound', {
                clientId, back, type, interval,
            }, { env });
        },
    }),
});

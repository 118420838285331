import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

const returnWithPrefix = (prefix, {
    error, data, isFetching,
}) => ({
    [`${prefix}Error`]: error,
    [prefix]: data,
    [`${prefix}Loading`]: isFetching,
});

export const useQueryWithPrefixedResults = (prefix, key, enabled, initialData) => {
    const {
        isPending, error, data, isFetching,
    } = useQuery({ ...key, enabled, initialData });
    return returnWithPrefix(prefix, {
        isPending, error, data, isFetching,
    });
};

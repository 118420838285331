import { useEffect, useMemo } from 'react';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { useQueryClient } from '@tanstack/react-query';
import { CalciteLoader } from '@esri/calcite-components-react';
import styled from 'styled-components';
import dayjs from '../../services/dayjs';
import { parseJwt } from '../../services/webhelpers';
import { usePowerBiConfig, usePowerReports } from '../../queries/powerbi/queries';
import { queries } from '../../queries/queries';
import { setPageTitle } from '../../services/pagescripts';

export const FullSizeContainer = styled.div`
    height: 100%;
    width: 100%;
`;

const PowerBi = () => {
  useEffect(() => {
    setPageTitle('PowerBI Reports');
  }, []);

  const queryClient = useQueryClient();

  const { reportsLoading, reports } = usePowerReports();

  const key = useMemo(() => (reportsLoading || !reports ? null : reports[0].key), [reportsLoading, reports]);

  const { configLoading, config } = usePowerBiConfig(key);

  const embedConfig = useMemo(() => (configLoading || !config ? null : {
    type: 'report',
    ...config,
    permissions: models.Permissions.All,
    tokenType: models.TokenType.Embed,
    viewMode: models.ViewMode.View,
    settings: {
      panes: {
        bookmarks: {
          visible: false,
        },
        fields: {
          expanded: false,
        },
        filters: {
          expanded: false,
          visible: true,
        },
        pageNavigation: {
          visible: true,
        },
        selection: {
          visible: true,
        },
        syncSlicers: {
          visible: true,
        },
        visualizations: {
          expanded: false,
        },
      },
    },
  }), [configLoading, config]);

  useEffect(() => {
    if (reportsLoading || !reports || configLoading || !config
    ) return;
    const { exp } = parseJwt(config.accessToken);
    if (dayjs().unix() < exp) return;
    queryClient.invalidateQueries(queries.powerbi.config(key));
  }, [key, config, configLoading]);

return (configLoading || !embedConfig)
  ? <CalciteLoader type="indeterminate" text="Loading..." />
  : (
    <FullSizeContainer id='embed-container'>
      <PowerBIEmbed embedConfig={embedConfig} />
    </FullSizeContainer>
  );
};

export default PowerBi;

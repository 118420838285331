import styled from 'styled-components';
import { themeQuartz } from '@ag-grid-community/theming';

// to use myTheme in an application, pass it to the theme grid option
export const consoleTheme = themeQuartz.withParams({
    browserColorScheme: 'light',
    fontFamily: {
        googleFont: 'Roboto',
    },
    fontSize: '11px',
    headerBackgroundColor: '#F8F8F8',
    headerFontSize: '12px',
    headerFontWeight: 500,
    iconSize: '14px',
    spacing: '5px',
    wrapperBorder: false,
    wrapperBorderRadius: '0px',
});

export const FullSizeGrid = styled.div`
    height: 100%;
    width: 100%;
    --ag-borders: none;
`;

export const sideBar = {
    toolPanels: [
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            minWidth: 225,
            maxWidth: 225,
            width: 225,
        },
        {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            minWidth: 180,
            maxWidth: 400,
            width: 250,
        },
    ],
    position: 'right',
};

export const statusBar = {
    statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent' },
        { statusPanel: 'agTotalRowCountComponent' },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' },
    ],
};

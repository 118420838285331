import { CalciteBlock, CalciteButton, CalcitePanel } from '@esri/calcite-components-react';
import { CountryRegionData } from 'react-country-region-selector';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import ControlledInputText from '../googleels/components/UI/ControlledInputText';
import ControlledComboBox from '../googleels/components/UI/ControlledComboBox';
import CountryFlag from './CountryFlag';

const validationSchema = () => Yup.object().shape({
    streetNumber: Yup.string().required('Street Number is required.'),
    street: Yup.string().required('Street is required.'),
    city: Yup.string().required('City is required.'),
});

const ForwardPanel = ({ setValues }) => {
    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            streetNumber: null,
            street: null,
            city: null,
            state: null,
            country: 'US',
        },
    });

    useEffect(() => {
        setValue('streetNumber', null);
        setValue('street', null);
        setValue('city', null);
        setValue('state', null);
        setValue('country', 'US');
    }, []);

    const onForwardQuery = async () => {
        const result = await trigger();
        if (!result) return;
        setValues(getValues());
    };

    const options = CountryRegionData.map(([label, value]) => ({ value, label, content: <CountryFlag countryCode={value} /> }));

    return <CalcitePanel heading='Query'>
        <CalciteBlock open={true} heading="Forward Geocode">
            <ControlledInputText
                name="streetNumber"
                control={control}
                required={true}
                label="Street Number"
            />
            <ControlledInputText
                name="street"
                control={control}
                required={true}
                label="Street"
            />
            <ControlledInputText
                name="city"
                control={control}
                required={true}
                label="City"
            />
            <ControlledInputText
                name="state"
                control={control}
                label="State"
            />
            <ControlledComboBox
                name="country"
                control={control}
                clearDisabled={true}
                label="Country"
                options={options}
                selectionMode="single-persist"
            />
            <CalciteButton onClick={onForwardQuery}>Query</CalciteButton>
        </CalciteBlock>
    </CalcitePanel>;
};

export default ForwardPanel;

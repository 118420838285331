import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get, post } from '../../services/webhelpers';

export const searchQueryKeys = createQueryKeys('search', {
    tenants: (query) => ({
        queryKey: [query],
        queryFn: () => post('/legacy-tenant-search', { query }),
    }),
    eccs: (query) => ({
        queryKey: [query],
        queryFn: () => post('/v5-tenant-search', { query }),
    }),
    v4Users: (query) => ({
        queryKey: [query],
        queryFn: () => post('/legacy-user-search', { query }),
    }),
    v5users: (query) => ({
        queryKey: [query],
        queryFn: () => post('/v5-user-search', { query }),
    }),
    devices: (query) => ({
        queryKey: [query],
        queryFn: () => post('/device-search', { query }),
    }),
    tags: () => ({
        queryKey: [],
        queryFn: () => get('/tags/all'),
    }),
});

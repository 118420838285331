import { mergeQueryKeys } from '@lukemorales/query-key-factory';
import { callHandlingQueryKeys } from './callhandling/keys';
import { restDebuggerQueryKeys } from './restdebugger/keys';
import { edgQueryKeys } from './edg/keys';
import { searchQueryKeys } from './search/keys';
import { metricsQueryKeys } from './metrics/keys';
import { loggingConfigurationQueryKeys } from './loggingconfiguration/keys';
import { changeLogQueryKeys } from './changelog/keys';
import { consoleOnlineUsersQueryKeys } from './consoleonlineusers/keys';
import {
    elsAreaQueryKeys, elsChartsQueryKeys, elsClientsQueryKeys, elsStatusesQueryKeys,
} from './els/keys';
import { utilizationQueryKeys } from './utilization/keys';
import { powerBiQueryKeys } from './powerbi/keys';
import { DirectionsQueryKeys } from './directions/keys';
import { floorPickerQueryKeys } from './floorpicker/keys';
import { consoleQueryKeys } from './console/keys';
import { mobileLocationsQueryKeys } from './mobilelocations/keys';

export const queries = mergeQueryKeys(
    restDebuggerQueryKeys,
    elsClientsQueryKeys,
    elsStatusesQueryKeys,
    elsAreaQueryKeys,
    elsChartsQueryKeys,
    changeLogQueryKeys,
    loggingConfigurationQueryKeys,
    consoleOnlineUsersQueryKeys,
    edgQueryKeys,
    utilizationQueryKeys,
    callHandlingQueryKeys,
    powerBiQueryKeys,
    searchQueryKeys,
    metricsQueryKeys,
    DirectionsQueryKeys,
    floorPickerQueryKeys,
    consoleQueryKeys,
    mobileLocationsQueryKeys,
);

import { AgGridReact } from 'ag-grid-react';
import {
 useState, useEffect, useRef, useMemo, useCallback,
} from 'react';
import {
    CalciteAction, CalciteActionBar, CalciteActionGroup, CalciteAlert, CalciteBlock, CalciteButton,
    CalciteIcon, CalciteList, CalciteListItem, CalciteListItemGroup, CalciteModal, CalciteNavigationLogo,
    CalciteOption, CalcitePanel, CalciteProgress, CalciteSelect, CalciteSheet, CalciteShell,
    CalciteShellPanel, CalciteSwitch,
} from '@esri/calcite-components-react';
import Swal from 'sweetalert2';
import duration from 'dayjs/plugin/duration';
import { findIana } from 'windows-iana';
import styled from 'styled-components';
import { useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useInterval } from 'usehooks-ts';
import dayjs from '../../services/dayjs';
import { launchInNewWindow } from '../../services/webhelpers';
import { copyToClipboard, formatBytes, GetInstance } from '../../services/helperscripts';
import { InstanceNames } from '../../instances/names';
import {
    useAddEDGNote, useEDGActionWithNotes, useEDGConfigComponents, useEDGDevice, useEdgDeviceStream,
    useEDGGridEvents, useEDGInterfaces, useEDGNotes, useEdgParsePolicy, useEDGParser, useEDGPing,
    useEDGReboot, useEDGStatuses, useUpdateEDGDeviceStatus,
} from '../../queries/edg/queries';
import ControlledInputText from '../googleels/components/UI/ControlledInputText';
import ControlledInputTextArea from '../googleels/components/UI/ControlledInputTextArea';
import { queries } from '../../queries/queries';
import ConsoleJsonEditor from '../consolejsoneditor';
import { FullSizeGrid, sideBar, statusBar } from '../griddefaults';
import Grid from '../grid';

const messagingInstance = await GetInstance(InstanceNames.Messaging);

dayjs.extend(duration);

const StyledCalciteModal = styled(CalciteModal)`
    z-index: 900;
`;

const StyledCalciteShell = styled(CalciteShell)`
    min-height: 100%;
`;

const replacements = [
    { char: 'NUL', code: 0, text: 'null character' },
    { char: 'SOH', code: 1, text: 'start of header' },
    { char: 'STX', code: 2, text: 'start of text' },
    { char: 'ETX', code: 3, text: 'end of text' },
    { char: 'EOT', code: 4, text: 'end of transmission' },
    { char: 'ENQ', code: 5, text: 'enquiry' },
    { char: 'ACK', code: 6, text: 'acknowledge' },
    { char: 'BEL', code: 7, text: 'bell (ring)' },
    { char: 'BS', code: 8, text: 'backspace' },
    { char: 'HT', code: 9, text: 'horizontal tab' },
    { char: 'LF', code: 10, text: 'line feed' },
    { char: 'VT', code: 11, text: 'vertical tab' },
    { char: 'FF', code: 12, text: 'form feed' },
    {
        char: 'CR', code: 13, text: 'carriage return', ignore: true,
    },
    { char: 'SO', code: 14, text: 'shift out' },
    { char: 'SI', code: 15, text: 'shift in' },
    { char: 'DLE', code: 16, text: 'data link escape' },
    { char: 'DC1', code: 17, text: 'device control 1' },
    { char: 'DC2', code: 18, text: 'device control 2' },
    { char: 'DC3', code: 19, text: 'device control 3' },
    { char: 'DC4', code: 20, text: 'device control 4' },
    { char: 'NAK', code: 21, text: 'negative acknowledge' },
    { char: 'SYN', code: 22, text: 'synchronize' },
    { char: 'ETB', code: 23, text: 'end transmission block' },
    { char: 'CAN', code: 24, text: 'cancel' },
    { char: 'EM', code: 25, text: 'end of medium' },
    { char: 'SUB', code: 26, text: 'substitute' },
    { char: 'ESC', code: 27, text: 'escape' },
    { char: 'FS', code: 28, text: 'file separator' },
    { char: 'GS', code: 29, text: 'group separator' },
    { char: 'RS', code: 30, text: 'record separator' },
    { char: 'US', code: 31, text: 'unit separator' },
    { char: 'DEL', code: 127, text: 'delete (rubout)' },
];

const priviledgedActionValidationSchema = () => Yup.object().shape({
    workItemReference: Yup.string(),
    reason: Yup.string()
        .required('Reason is required.')
        .test('Reason-test', 'Reason needs to be more than 3 characters', (value) => value.trim().length > 3),
});

const validationSchema = () => Yup.object().shape({
    ticketDetails: Yup.string(),
    notes: Yup.string()
        .required('Notes are required.')
        .test('Notes-test', 'Notes needs to be more than 3 characters', (value) => value.trim().length > 3),
});

const EDGDeviceInfo = ({
    iotHubId, deviceId, showModal, onClose,
}) => {
    const queryClient = useQueryClient();

    const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const [parsingProfileJSON, setParsingProfileJSON] = useState({});
    const [parsingResultJSON, setParsingResultJSON] = useState({});

    const [parsingProfileCollapsed, setParsingProfileCollapsed] = useState(false);
    const [parsingResultCollapsed, setParsingResultCollapsed] = useState(true);

    const [gridIsReady, setGridIsReady] = useState(false);
    const [filteredLogs, setFilteredLogs] = useState(false);

    const [autoRefresh, setAutoRefresh] = useState(false);

    const [twinIsOnline, setTwinIsOnline] = useState(false);
    const [dmModuleIsOnline, setDmModuleIsOnline] = useState(false);

    const [isPingError, setPingError] = useState(false);
    const [pingLatency, setPingLatency] = useState(null);

    const [connectionState, setConnectionState] = useState('Unknown');
    const [connectionStatus, setConnectionStatus] = useState(null);

    const [twinInfo, setTwinInfo] = useState(null);
    const [parsers, setParsers] = useState([]);
    const [parserId, setParserId] = useState(null);
    const [parsedEvent, setParsedEvent] = useState(null);

    const [heartbeat, setHeartbeat] = useState(null);

    const [priviledgedActionType, setPriviledgedActionType] = useState(null);

    const timeStampInTimeZone = useCallback((timestamp) => (twinInfo?.timeZone
        ? dayjs(timestamp).tz(twinInfo?.timeZone)
        : dayjs(timestamp)), [twinInfo?.timeZone]);

    const config = useMemo(() => ({
        actions: {
            deviceInfo: 'Device Info',
            heartbeat: 'Heartbeat',
            interfaces: 'Interfaces',
            parsingPolicy: 'Parsing Policy',
            parsedEvent: 'Parsed Event',
            notes: 'Notes',
        },
        grids: {
            autoSizeStrategy: {
                type: 'fitCellContents',
                skipHeader: true,
            },
            ali: {
                name: 'ALI Logs',
                colDefs: [
                    {
                        headerName: 'Timestamp',
                        children: [
                            {
                                headerName: `EDG (${twinInfo?.timeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).toDate(),
                            },
                            {
                                sort: 'desc',
                                headerName: `Mine (${myTimeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => dayjs(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => dayjs(timestamp).toDate(),
                            },
                        ],
                    },
                    {
                        headerName: 'Listener Type',
                        field: 'listenerType',
                    },
                    {
                        headerName: 'Listener Name',
                        field: 'listenerName',
                    },
                    {
                        headerName: 'Parsed Payload',
                        field: 'message',
                    },
                    {
                        headerName: 'Payload',
                        field: 'payload',
                        filter: false,
                        hide: true,
                    },
                ],
            },
            i3: {
                name: 'i3 Logs',
                colDefs: [
                    {
                        headerName: 'Timestamp',
                        children: [
                            {
                                headerName: `EDG (${twinInfo?.timeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).toDate(),
                            },
                            {
                                sort: 'desc',
                                headerName: `Mine (${myTimeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => dayjs(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => dayjs(timestamp).toDate(),
                            },
                        ],
                    },
                    {
                        headerName: 'Message',
                        field: 'message',
                    },
                ],
            },
            cdr: {
                name: 'CDR Logs',
                colDefs: [
                    {
                        headerName: 'Timestamp',
                        children: [
                            {
                                headerName: `EDG (${twinInfo?.timeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).toDate(),
                            },
                            {
                                sort: 'desc',
                                headerName: `Mine (${myTimeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => dayjs(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => dayjs(timestamp).toDate(),
                            },
                        ],
                    },
                    {
                        headerName: 'Message',
                        field: 'message',

                    },
                ],
            },
            telemetry: {
                name: 'Telemetry Events',
                colDefs: [
                    {
                        headerName: 'Timestamp',
                        children: [
                            {
                                headerName: `EDG (${twinInfo?.timeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).toDate(),
                            },
                            {
                                sort: 'desc',
                                headerName: `Mine (${myTimeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => dayjs(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => dayjs(timestamp).toDate(),
                            },
                        ],
                    },
                    {
                        headerName: 'Endpoint',
                        field: 'url',
                    },
                    {
                        headerName: 'Result Code',
                        field: 'resultCode',
                        enableRowGroup: true,
                    },
                    {
                        headerName: 'Properties',
                        field: 'content',
                    },
                    {
                        headerName: 'Message',
                        field: 'message',
                    },
                ],
            },
            serial: {
                name: 'Serial Logs',
                colDefs: [
                    {
                        headerName: 'Timestamp',
                        children: [
                            {
                                headerName: `EDG (${twinInfo?.timeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => timeStampInTimeZone(timestamp).toDate(),
                            },
                            {
                                sort: 'desc',
                                headerName: `Mine (${myTimeZone})`,
                                filter: 'agDateColumnFilter',
                                cellRenderer: ({ data: { timestamp } }) => dayjs(timestamp).format('L LTS'),
                                valueGetter: ({ data: { timestamp } }) => dayjs(timestamp).toDate(),
                            },
                        ],
                    },
                    {
                        headerName: 'Listener Type',
                        field: 'listenerType',
                    },
                    {
                        headerName: 'Listener Name',
                        field: 'listenerName',
                    },
                    {
                        headerName: 'Parsed Payload',
                        field: 'payloadBytes',
                    },
                    {
                        headerName: 'Payload',
                        field: 'payload',
                        filter: false,
                        hide: true,
                    },
                ],
            },
        },
        deviceTypes: {
            serial: {
                name: 'serial',
                grids: ['ALI Logs', 'CDR Logs', 'Serial Logs', 'Telemetry Events'],
            },
            i3: {
                name: 'i3',
                grids: ['i3 Logs'],
            },
        },
        priviledgedActions: {
            restartEdgApplication: {
                viaAppRestart: 'edg-apprestart',
                viaDeviceManager: 'edg-devicemanager',
            },
            restartDeviceManager: {
                viaAppRestart: 'dm-apprestart',
                viaEdgApplication: 'dm-edgapp',

            },
            reboot: 'reboot',
            ssh: 'ssh',
            scp: 'scp',
            tunnel: 'tunnel',
        },
    }), [twinInfo?.timeZone, myTimeZone]);

    const [activeActionTab, setActiveActionTab] = useState(config.actions.deviceInfo);
    const [activeGridTab, setActiveGridTab] = useState(config.grids.ali.name);

    const gridRef = useRef();

    const activeGridSlug = useMemo(() => {
        switch (activeGridTab) {
            case config.grids.ali.name:
                return `logs/ali${filteredLogs ? '/filtered' : ''}`;
            case config.grids.cdr.name:
                return 'logs/cdr';
            case config.grids.telemetry.name:
                return 'telemetry';
            case config.grids.serial.name:
                return 'serialData/1d';
            case config.grids.i3.name:
                return 'logs/i3';
            default:
                return null;
        }
    }, [activeGridTab, filteredLogs]);

    const setActiveActionTabState = ({ target: { text } }) => { setActiveActionTab(text); };
    const setActiveGridTabState = ({ target: { text } }) => { setActiveGridTab(text); };

    const { notesLoading, notes } = useEDGNotes(iotHubId, deviceId);
    const { pingLoading, ping } = useEDGPing(iotHubId, deviceId);
    const { statusesLoading, statuses } = useEDGStatuses(iotHubId, deviceId);
    const { deviceLoading, device } = useEDGDevice(iotHubId, deviceId);
    const { componentsLoading, components } = useEDGConfigComponents(iotHubId, deviceId);
    const { interfacesLoading, interfaces } = useEDGInterfaces(iotHubId, deviceId);
    const { parserLoading, parser } = useEDGParser(iotHubId, deviceId, parserId);
    const { eventsLoading, events } = useEDGGridEvents(iotHubId, deviceId, activeGridSlug);

    const addEDGNote = useAddEDGNote(iotHubId, deviceId);
    const updateEDGDeviceStatus = useUpdateEDGDeviceStatus(iotHubId, deviceId);
    const actionWithNotes = useEDGActionWithNotes(iotHubId, deviceId);
    const rebootDevice = useEDGReboot(iotHubId, deviceId);

    const deviceStreamLauncher = useEdgDeviceStream();
    const { isSuccess: deviceStreamIsSuccess, data: deviceStreamData, isPending: deviceStreamIsPending } = deviceStreamLauncher;

    const parsingPolicyEvaluator = useEdgParsePolicy();
    const { isSuccess: parsingPolicyEvaluatorIsSuccess, data: parsingPolicyEvaluatorData, isPending: parsingPolicyEvaluatorIsPending } = parsingPolicyEvaluator;

    useEffect(() => {
        gridRef.current?.api?.updateGridOptions({ loading: eventsLoading });
    }, [activeGridTab, eventsLoading]);

    const {
        control: priviledgedActionControl,
        trigger: priviledgedActionTrigger,
        getValues: priviledgedActionGetValues,
        reset: priviledgedActionReset,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(priviledgedActionValidationSchema()),
        defaultValues: {
            reason: '',
            workItemReference: '',
        },
    });

    const {
        control, trigger, getValues, reset,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            notes: '',
            ticketDetails: '',
        },
    });

    const addNote = async () => {
        const result = await trigger();
        if (!result) {
            return;
        }
        addEDGNote(getValues());
        reset();
    };

    const closePriviledgedAction = () => setPriviledgedActionType(null);

    const resetPriviledgedActionType = (type) => {
        priviledgedActionReset();
        setPriviledgedActionType(type);
    };

    const restartEDGApplication = () => {
        const { reason, workItemReference } = priviledgedActionGetValues();
        actionWithNotes({
            channel: 'app-restart',
            application: 'edg-app',
            reason,
            workItemReference,
        });
        Swal.fire('Warning!', `The EDG Application on ${deviceId} will be restarted now...`, 'success');
        closePriviledgedAction();
    };

    const requestRestartEDGApplication = () => {
        resetPriviledgedActionType(config.priviledgedActions.restartEdgApplication.viaAppRestart);
        queryClient.invalidateQueries(queries.edg.statuses(iotHubId, deviceId));
    };

    const restartEDGApplicationFromDmModule = () => {
        const { reason, workItemReference } = priviledgedActionGetValues();
        actionWithNotes({
            channel: 'restart',
            application: 'edg-app',
            reason,
            workItemReference,
        });
        Swal.fire('Warning!', `The EDG Application on ${deviceId} will be restarted now...`, 'success');
        closePriviledgedAction();
    };

    const requestRestartEDGApplicationFromDmModule = () => {
        resetPriviledgedActionType(config.priviledgedActions.restartEdgApplication.viaDeviceManager);
        queryClient.invalidateQueries(queries.edg.statuses(iotHubId, deviceId));
    };

    const restartDeviceManagerApplication = () => {
        const { reason, workItemReference } = priviledgedActionGetValues();
        actionWithNotes({
            channel: 'app-restart',
            application: 'dm-app',
            reason,
            workItemReference,
        });
        Swal.fire('Warning!', `The DeviceManager Application on ${deviceId} will be restarted now...`, 'success');
        closePriviledgedAction();
    };

    const requestRestartDeviceManagerApplication = async () => {
        resetPriviledgedActionType(config.priviledgedActions.restartDeviceManager.viaAppRestart);
        queryClient.invalidateQueries(queries.edg.statuses(iotHubId, deviceId));
    };

    const restartDeviceManagerFromDeviceTwin = async () => {
        const { reason, workItemReference } = priviledgedActionGetValues();
        actionWithNotes({
            channel: 'restart',
            application: 'dm-app',
            reason,
            workItemReference,
        });
        Swal.fire('Warning!', `The DeviceManager Application on ${deviceId} will be restarted now...`, 'success');
        closePriviledgedAction();
    };

    const requestRestartDeviceManagerFromDeviceTwin = () => {
        resetPriviledgedActionType(config.priviledgedActions.restartDeviceManager.viaEdgApplication);
        queryClient.invalidateQueries(queries.edg.statuses(iotHubId, deviceId));
    };

    const rebootEdgDevice = () => {
        const { reason, workItemReference } = priviledgedActionGetValues();
        rebootDevice({ reason, workItemReference });
        Swal.fire('Warning!', `${deviceId} will reboot in 2 mins time...`, 'success');
        closePriviledgedAction();
    };

    const requestRebootEdgDevice = async () => {
        resetPriviledgedActionType(config.priviledgedActions.reboot);
        queryClient.invalidateQueries(queries.edg.ping(iotHubId, deviceId));
    };

    useEffect(() => {
        switch (connectionState.toLowerCase()) {
            case 'inactive':
                setConnectionStatus({ state: false, message: `Inactive on ${iotHubId}` });
                break;
            case 'connected':
            case 'deviceconnected':
                setConnectionStatus({ state: true, message: `Connected to ${iotHubId}` });
                break;
            case 'disconnected':
            case 'devicedisconnected':
                setConnectionStatus({ state: false, message: `Disconnected from ${iotHubId}` });
                break;
            default:
                setConnectionStatus({ state: false, message: `${iotHubId} - ${connectionState}` });
                break;
        }
    }, [connectionState, iotHubId]);

    const clearGridEvents = () => {
        const grid = gridRef.current?.api;
        if (!grid) {
            return;
        }

        const remove = [];
        grid.forEachNode(({ data }) => { remove.push(data); });

        grid.applyTransaction({ remove });
    };

    useEffect(() => {
        if (deviceLoading) {
            clearGridEvents();
            setConnectionStatus({ state: false, message: `Checking connection to ${iotHubId}` });
            return;
        }

        try {
            setActiveGridTab(device.deviceType === config.deviceTypes.i3.name ? config.grids.i3.name : config.grids.ali.name);

            setConnectionState(device.connectionState);

            const [iana] = findIana(device.timeZone);

            setTwinInfo({
                cradlePointStatus: device.routerStatusVisibility === 'Collapsed' ? null : { routerName: device.routerName, routerStatus: device.routerStatus },
                deviceStreamAvailable: device.streamEnabled,
                deviceType: device.deviceType === config.deviceTypes.i3.name ? config.deviceTypes.i3.name : config.deviceTypes.serial.name,
                timeZone: iana,
                appVersion: device.appVersion,
                tenantId: device.tenantId,
                psapId: device.psapId,
                organization: device.tags.organization,
                agencyname: device.tags.agencyName,
                location: device.tags.location,
                activeStatus: device.tags.activeStatus,
                installDate: device.tags.installDate,
                updateGroup: device.tags.updateGroup,
                changeStatusText: device.tags.activeStatus === 'active' ? 'Deactivate' : 'Activate',
            });

            reset();
        } catch (e) {
            console.error(e);
        }
    }, [deviceLoading, device]);

    useEffect(() => {
        if (componentsLoading || !components) {
            setParsers([]);
            return;
        }

        const data = components
            .filter(({ type }) => type === 'RD.IoT.EDG.Common.Parsers.StreamParser, RD.IoT.EDG.Common')
            .map(({ services, properties }) => ({ value: services[0].key, text: `${services[0].key} ${properties.parserConfigurationUri}` }));

        setParsers([{ text: 'Please select a parser', value: '' }, ...data]);
    }, [componentsLoading, components]);

    const toggleStatusEdgDevice = async () => {
        const { tags: { activeStatus: deviceActiveStatus } } = device;
        const status = deviceActiveStatus === 'active' ? 'inactive' : 'active';

        updateEDGDeviceStatus({ status });
    };

    useEffect(() => {
        if (statusesLoading || !statuses) {
            setTwinIsOnline(false);
            setDmModuleIsOnline(false);
            return;
        }

        const { twin, dmModule } = statuses;

        setTwinIsOnline(twin);
        setDmModuleIsOnline(dmModule);
    }, [statuses]);

    useEffect(() => {
        if (pingLoading) return;
        setPingLatency(ping?.pingStatus.endsWith('error') ? 'Error' : `${Number(ping?.latency).toLocaleString()} ms`);
        setPingError(ping?.pingStatus.endsWith('error'));
    }, [ping]);

    const pingEdgDevice = () => {
        queryClient.invalidateQueries(queries.edg.ping(iotHubId, deviceId));
    };

    const streamEdgDevice = () => {
        const winPath = `/#/device-stream/${iotHubId}/${deviceId}`;
        const winName = `device-stream-${iotHubId}-${deviceId}`;
        const winFeature = 'width=1100,height=600,noopener,noreferrer';

        const popup = window.open(winPath, winName, winFeature, true);
        try {
            popup?.focus();
        } catch (e) {
            // do nothing
        }
    };

    useEffect(() => {
        if (!gridIsReady || eventsLoading || !events) return;

        const grid = gridRef.current?.api;

        const columnDefs = (() => {
            switch (activeGridTab) {
                case config.grids.ali.name:
                    return config.grids.ali.colDefs;
                case config.grids.cdr.name:
                    return config.grids.cdr.colDefs;
                case config.grids.telemetry.name:
                    return config.grids.telemetry.colDefs;
                case config.grids.serial.name:
                    return config.grids.serial.colDefs;
                case config.grids.i3.name:
                    return config.grids.i3.colDefs;
                default:
                    return null;
            }
        })();

        const remove = [];
        grid.forEachNode(({ data }) => { remove.push(data); });

        switch (activeGridTab) {
            case config.grids.serial.name:
            case config.grids.ali.name:
                {
                    const remappedAdd = events.map((item) => {
                        const payloadBytes = item.payload
                            ? item.payload.replace('[', '').replace(']', '').split(' ').map((hex) => parseInt(hex, 16))
                            : [];

                        return ({
                            ...item,
                            payloadBytes,
                            message: payloadBytes.map((byte) => (replacements.find(({ code }) => code === byte) == null ? String.fromCharCode(byte) : '')).join(''),
                        });
                    });

                    grid.applyTransaction({
                        remove,
                        add: remappedAdd,
                    });
                    break;
                }
            default:
                grid.applyTransaction({ remove, add: events });
                break;
        }

        grid.updateGridOptions({ columnDefs, context: { activeGridTab } });
    }, [activeGridSlug, eventsLoading, events, twinInfo?.timeZone, gridIsReady]);

    useEffect(() => {
        if (parserLoading || !parser) {
            setParsingProfileJSON({});
            return;
        }

        setParsingProfileJSON(parser);
    }, [parserLoading, parser]);

    useEffect(() => {
        if (!iotHubId || !deviceId) { return; }

        setActiveActionTab(config.actions.deviceInfo);
    }, [iotHubId, deviceId]);

    useEffect(() => {
        if (!messagingInstance || !iotHubId || !deviceId) return;

        if (!showModal) {
            setAutoRefresh(false);
            messagingInstance?.unsubscribeFromMqttWithoutChecks(`edg/heartbeats/${iotHubId}/${deviceId}`);
            messagingInstance?.unsubscribeFromMqttWithoutChecks(`edg/iotconnectionstatus/${iotHubId}/${deviceId}`);
            return;
        }

        setAutoRefresh(true);

        messagingInstance.subscribeToMqtt(`edg/heartbeats/${iotHubId}/${deviceId}`, (event) => setHeartbeat(event));
        messagingInstance.subscribeToMqtt(`edg/iotconnectionstatus/${iotHubId}/${deviceId}`, ({ status }) => setConnectionState(status));
    }, [showModal, messagingInstance, iotHubId, deviceId]);

    const closeModal = () => {
        onClose();
    };

    useEffect(() => {
        if (!parsingPolicyEvaluatorIsSuccess || !parsingPolicyEvaluatorData) {
            setParsingResultJSON({});
            setParsingResultCollapsed(true);
            return;
        }
        const { parsedResult: { ali }, validationExceptions, scoredPolicies } = parsingPolicyEvaluatorData;
        setParsingResultJSON({ ali, validationExceptions, scoredPolicies });
        setParsingProfileCollapsed(true);
        setParsingResultCollapsed(false);
    }, [parsingPolicyEvaluatorIsSuccess, parsingPolicyEvaluatorData, parsingPolicyEvaluatorIsPending]);

    const onAsyncTransactionsFlushed = () => {
        gridRef.current?.api?.autoSizeAllColumns();
    };

    const onGridReady = () => {
        setGridIsReady(true);
    };

    const onCellClicked = async ({ data: { content, payload, payloadBytes } }) => {
        setAutoRefresh(false);
        switch (activeGridTab) {
            case config.grids.ali.name:
                {
                    if (payload.length < 20) return;
                    setParsedEvent(payloadBytes.map((b) => String.fromCharCode(b)).join(''));

                    if (!parserId) {
                        setActiveActionTab(config.actions.parsedEvent);
                        return;
                    }

                    const parsepayload = {
                        ALI: payload.replace('[', '').replace(']', ''),
                        StreamPolicy: JSON.stringify(parsingProfileJSON),
                    };

                    parsingPolicyEvaluator.mutate(parsepayload);

                    setActiveActionTab(config.actions.parsingPolicy);

                    break;
                }
            case config.grids.telemetry.name:
                setParsedEvent(JSON.stringify(JSON.parse(content), undefined, 2));
                setActiveActionTab(config.actions.parsedEvent);

                break;
            default:
        }
    };

    const copyDescriptionToClipboard = ({ target: { description } }) => copyToClipboard(description);
    const toggleFilteredLogs = () => setFilteredLogs(!filteredLogs);
    const toggleAutoRefresh = () => setAutoRefresh(!autoRefresh);
    const setParserValue = ({ target: { value } }) => setParserId(value);

    const installSSH = () => launchInNewWindow('https://console-storage.rapiddeploy.com/edgssh/setup.exe');

    const [deviceStreamsLaunchMode, setDeviceStreamsLaunchMode] = useState();

    useEffect(() => {
        if (deviceStreamIsPending || !deviceStreamIsSuccess) {
            return;
        }
        const { url, authorizationToken } = deviceStreamData;

        const params = new URLSearchParams([]);
        params.append('url', url);
        params.append('token', authorizationToken);
        params.append('port', '3030');
        params.append('mode', deviceStreamsLaunchMode);
        window.location.href = `https://console-storage.rapiddeploy.com/edgssh/DeviceStreamsTerminalClient.application?${params.toString()}`;
    }, [deviceStreamsLaunchMode, deviceStreamIsSuccess, deviceStreamData, deviceStreamIsPending]);

    const loadClient = async (mode) => {
        setDeviceStreamsLaunchMode(mode);
        closePriviledgedAction();
        deviceStreamLauncher.mutate({ iotHubId, deviceId });
    };

    const sshUsingClient = async () => loadClient('ssh');
    const scpUsingClient = async () => loadClient('scp');

    const requestSshUsingClient = () => {
        resetPriviledgedActionType(config.priviledgedActions.ssh);
    };

    const requestScpUsingClient = () => {
        resetPriviledgedActionType(config.priviledgedActions.scp);
    };

    useInterval(() => {
        queryClient.invalidateQueries(queries.edg.gridEvents(iotHubId, deviceId, activeGridSlug));
    }, autoRefresh ? 15000 : null);

    useInterval(() => {
       setHeartbeat({ ...heartbeat });
    }, heartbeat ? 30000 : null);

    const [connectionStateThumbnail, setConnectionStateThumbnail] = useState('/images/svgs/cloud-slash.svg');

    const [pingStatus, setPingStatus] = useState({ message: '', thumbnail: '/images/svgs/ping.svg' });
    const [twinStatus, setTwinStatus] = useState({ message: '', thumbnail: '/images/svgs/twin.svg' });
    const [dmModuleStatus, setDmModuleStatus] = useState({ message: '', thumbnail: '/images/svgs/dmmodule.svg' });

    useEffect(() => {
        setConnectionStateThumbnail(connectionStatus?.state ? '/images/svgs/cloud.svg' : '/images/svgs/cloud-slash.svg');
    }, [connectionStatus?.state]);

    useEffect(() => {
        if (pingLoading) {
            setPingStatus({
                message: 'Pinging',
                thumbnail: '/images/svgs/ping.svg',
            });
            return;
        }

        if (isPingError) {
            setPingStatus({
                message: 'Ping Failed',
                thumbnail: '/images/svgs/ping-offline.svg',
            });
            return;
        }

        setPingStatus({
            message: 'Ping Success',
            thumbnail: '/images/svgs/ping-online.svg',
        });
    }, [pingLoading, isPingError]);

    useEffect(() => {
        if (statusesLoading) {
            setTwinStatus({ message: 'Checking', thumbnail: '/images/svgs/twin.svg' });
            return;
        }

        if (!twinIsOnline) {
            setTwinStatus({ message: 'Twin is offline', thumbnail: '/images/svgs/twin-offline.svg' });
            return;
        }

        setTwinStatus({ message: 'Twin is online', thumbnail: '/images/svgs/twin-online.svg' });
    }, [statusesLoading, twinIsOnline]);

    useEffect(() => {
        if (statusesLoading) {
            setDmModuleStatus({ message: 'Checking', thumbnail: '/images/svgs/dmmodule.svg' });
            return;
        }

        if (!dmModuleIsOnline) {
            setDmModuleStatus({ message: 'dmModule is offline', thumbnail: '/images/svgs/dmmodule-offline.svg' });
            return;
        }

        setDmModuleStatus({ message: 'dmModule is online', thumbnail: '/images/svgs/dmmodule-online.svg' });
    }, [statusesLoading, dmModuleIsOnline]);

    const OfflineListItem = ({ label }) => <CalciteListItem label={label} disabled={true}>
        <CalciteIcon scale="s" slot="content-end" icon="offline" />
    </CalciteListItem>;

    const CondensedListItem = ({ label, description, labelDescription }) => <CalciteListItem label={label} description={labelDescription} onCalciteListItemSelect={() => copyToClipboard(description)}>
        <CalciteListItem slot="content-end" label={description} />
    </CalciteListItem>;

    const PriviledgedActionButton = () => {
        const result = (() => {
            switch (priviledgedActionType) {
                case config.priviledgedActions.restartEdgApplication.viaAppRestart:
                    return {
                        action: restartEDGApplication,
                        text: 'Restart EDG Application via app-restart',
                    };
                case config.priviledgedActions.restartEdgApplication.viaDeviceManager:
                    return {
                        action: restartEDGApplicationFromDmModule,
                        text: 'Restart EDG Application via dmModule',
                    };
                case config.priviledgedActions.restartDeviceManager.viaAppRestart:
                    return {
                        action: restartDeviceManagerApplication,
                        text: 'Restart Device Manager via app-restart',
                    };
                case config.priviledgedActions.restartDeviceManager.viaEdgApplication:
                    return {
                        action: restartDeviceManagerFromDeviceTwin,
                        text: 'Restart Device Manager via Main Device Twin',
                    };
                case config.priviledgedActions.reboot:
                    return {
                        action: rebootEdgDevice,
                        text: 'Reboot Device',
                    };
                case config.priviledgedActions.ssh:
                    return {
                        action: sshUsingClient,
                        text: 'SSH into device using DeviceStreams',
                    };
                case config.priviledgedActions.scp:
                    return {
                        action: scpUsingClient,
                        text: 'SFTP into device using DeviceStreams',
                    };
                default:
                    return null;
            }
        })();

        const validateForm = async () => {
            const formIsValid = await priviledgedActionTrigger();
            if (!formIsValid) {
                return;
            }
            result.action();
        };

        return <CalciteButton slot='footer' width="full" onClick={validateForm}>{result.text}</CalciteButton>;
    };

    const priviledgedServiceOnline = useMemo(() => {
        if (statusesLoading || !statuses) {
            return null;
        }

        const { twin, dmModule } = statuses;

        switch (priviledgedActionType) {
            case config.priviledgedActions.restartEdgApplication.viaAppRestart:
            case config.priviledgedActions.restartDeviceManager.viaAppRestart:
            case config.priviledgedActions.restartDeviceManager.viaEdgApplication:
            case config.priviledgedActions.reboot:
                return !twin ? 'EDG Application not online - unable to continue...' : null;
            case config.priviledgedActions.restartEdgApplication.viaDeviceManager:
                return !dmModule ? 'Device Manager not online - unable to continue...' : null;
            default:
                return null;
        }
    }, [statuses, priviledgedActionType]);

    const DeviceInfoPanel = () => (
        <CalcitePanel>
            <CalciteList selectionMode='none'>
                <CalciteListItemGroup heading="Connectivity">
                    <CondensedListItem label="Ping Latency" description={pingLoading ? 'Pinging...' : pingLatency} />
                    <CondensedListItem label="CradlePoint" description={deviceLoading
                        ? 'Loading...'
                        : twinInfo?.cradlePointStatus
                            ? `${twinInfo?.cradlePointStatus?.routerName} ${twinInfo?.cradlePointStatus?.routerStatus}`
                            : 'Not Connected'
                    } />
                    <CondensedListItem label="Device Twin" description={statusesLoading ? 'Loading...' : twinIsOnline ? 'Online' : 'Offline'} />
                    <CondensedListItem label="dmModule" description={statusesLoading ? 'Loading...' : dmModuleIsOnline ? 'Online' : 'Offline'} />
                </CalciteListItemGroup>
                <CalciteListItemGroup heading="Twin Information">
                    <CondensedListItem label="App Version" description={deviceLoading ? 'Loading...' : twinInfo?.appVersion} />
                    <CondensedListItem label="Organization" description={deviceLoading ? 'Loading...' : twinInfo?.organization} />
                    <CondensedListItem label="Agency Name" description={deviceLoading ? 'Loading...' : twinInfo?.agencyname} />
                    <CondensedListItem label="PSAP Id" description={deviceLoading ? 'Loading...' : twinInfo?.psapId} />
                    <CondensedListItem label="Tenant Id" description={deviceLoading ? 'Loading...' : twinInfo?.tenantId} />
                    <CondensedListItem label="Location" description={deviceLoading ? 'Loading...' : twinInfo?.location} />
                    <CondensedListItem label="Active Status" description={deviceLoading ? 'Loading...' : twinInfo?.activeStatus} />
                    <CondensedListItem label="Install Date" description={deviceLoading ? 'Loading...' : twinInfo?.installDate} />
                    <CondensedListItem label="Update Group" description={deviceLoading ? 'Loading...' : twinInfo?.updateGroup} />
                </CalciteListItemGroup>
            </CalciteList>
        </CalcitePanel>
    );

    const HeartbeatPanel = () => (
        <CalcitePanel>
            <CalciteListItemGroup heading={`${heartbeat?.appId}${heartbeat?.version ? `, v${heartbeat.version}` : ''}`}>
                <CalciteList>
                    <CondensedListItem label="Timestamp" description={heartbeat?.timestamp ? dayjs(heartbeat.timestamp).fromNow() : 'Unknown'} />
                    <CondensedListItem label="System Uptime" description={heartbeat?.systemUpTimeInMinutes ? dayjs.duration(-heartbeat.systemUpTimeInMinutes, 'minutes').humanize() : 'Unknown'} />
                    <CondensedListItem label="Application Uptime" description={heartbeat?.applicationUpTimeInMinutes ? dayjs.duration(-heartbeat.applicationUpTimeInMinutes, 'minutes').humanize() : 'Unknown'} />
                    <CondensedListItem label="Last Heartbeat" description={heartbeat?.minutesSinceLastHeartbeat ? dayjs.duration(-heartbeat.minutesSinceLastHeartbeat, 'minutes').humanize(true) : 'Unknown'} />
                    <CondensedListItem label="Total Messages In Buffer" description={typeof heartbeat?.DispatchInfo?.totalMessagesInBuffer === 'number' ? Number(heartbeat.DispatchInfo.totalMessagesInBuffer).toLocaleString() : 'Unknown'} />
                </CalciteList>
            </CalciteListItemGroup>
            <CalciteListItemGroup heading='Metrics'>
                <CalciteList>
                    <CondensedListItem label="CPU Usage" description={heartbeat?.deviceMetrics?.cpuUsagePercentage ? `${heartbeat.deviceMetrics.cpuUsagePercentage} %` : 'Unknown'} />
                    <CondensedListItem label="Memory Usage" description={heartbeat?.deviceMetrics?.memoryUsagePercentage ? `${heartbeat.deviceMetrics.memoryUsagePercentage} %` : 'Unknown'} />
                    <CondensedListItem label="Disk Usage" description={heartbeat?.deviceMetrics?.availableSpaceInGB ? `${heartbeat.deviceMetrics.availableSpaceInGB} GB` : 'Unknown'} />
                </CalciteList>
            </CalciteListItemGroup>
            {heartbeat?.listenersInfo.map((listener) => (
                <CalciteListItemGroup key={listener.listenerName} heading={listener.listenerName}>
                    <CalciteList>
                        <CondensedListItem label="Last processed time" description={dayjs(listener.lastProcessedTime).utc(true).fromNow()} />
                        <CondensedListItem label="Last message size" description={formatBytes(listener.lastMessageTotalBytes)} />
                        <CondensedListItem label="Total size since last heartbeat" description={formatBytes(listener.totalBytesSinceLastHearbeat)} />
                        <CondensedListItem label="Total size received" description={formatBytes(listener.totalBytesReceived)} />
                        <CondensedListItem label="Last heartbeat" description={dayjs.duration(-parseInt(listener.minutesSinceLastMessage, 10), 'minutes').humanize(true)} />
                        <CondensedListItem label="Queued messages since last heartbeat" description={Number(listener.queuedMessagesSinceLastHeartbeat).toLocaleString()} />
                        <CondensedListItem label="Total queued messages" description={Number(listener.queuedMessages).toLocaleString()} />
                        <CalciteListItem label="Transports">
                            <CalciteList group='Transports'>
                                {listener.transports.map((transport) => (
                                    <CalciteListItem key={transport.transportName} label={transport.transportName}>
                                        <CalciteList group={transport.transportName}>
                                            <CondensedListItem label="Last message delivered" description={dayjs(transport.lastMessageDelivered).utc(true).fromNow()} />
                                            <CondensedListItem label="Total messages" description={Number(transport.messageCount).toLocaleString()} />
                                            <CondensedListItem label="Total messages since last heartbeat" description={Number(transport.messageCountSinceLastHeartbeat).toLocaleString()} />
                                            <CondensedListItem label="Last Message" description={dayjs.duration(-parseInt(transport.minutesSinceLastMessage, 10), 'minutes').humanize(true)} />
                                        </CalciteList>
                                    </CalciteListItem>
                                ))}
                            </CalciteList>
                        </CalciteListItem>
                    </CalciteList>
                </CalciteListItemGroup>
            ))}
        </CalcitePanel>
    );

    const ParsingPolicyPanel = () => (
        <CalcitePanel>
            <CalciteBlock heading="Parsers" collapsible={false} open={true}>
                <CalciteSelect value={parserId} onCalciteSelectChange={setParserValue}>
                    {parsers.map((item, id) => (<CalciteOption key={id} value={item.value}>{item.text}</CalciteOption>))}
                </CalciteSelect>
            </CalciteBlock>
            <CalciteBlock heading="Policy" collapsible={false} open={true}>
                <ConsoleJsonEditor data={parsingProfileJSON} setData={setParsingProfileJSON} collapse={parsingProfileCollapsed} />
            </CalciteBlock>
            <CalciteBlock heading="Result" collapsible={false} open={true}>
                <ConsoleJsonEditor
                    data={parsingResultJSON}
                    setData={setParsingResultJSON}
                    collapse={parsingResultCollapsed}
                    restrictAdd={true}
                    restrictEdit={true}
                    restrictDelete={true}
                    />
            </CalciteBlock>
        </CalcitePanel>
    );

    const ParsedEventPanel = () => (
        <CalcitePanel>
            <CalciteBlock heading="Parsed Event" collapsible={false} open={true}>
                <pre className="parsedEvent">{parsedEvent ?? 'Please click on a parsed payload in the ALI Log or Telemetry Events tab to show here..'}</pre>
            </CalciteBlock>
        </CalcitePanel>
    );

    const InterfacesPanel = () => (
        <CalcitePanel loading={interfacesLoading || undefined}>
            <CalciteList selectionMode='none' heading='Interfaces'>
                {interfaces?.map(({ key, values }) => (
                    <CalciteListItemGroup key={key} heading={key}>
                        {values.map(([label, description], index) => (
                            <CalciteListItem key={index} label={label} description={description} onClick={copyDescriptionToClipboard} />
                        ))}
                    </CalciteListItemGroup>
                ))}
            </CalciteList>
        </CalcitePanel>
    );

    const NotesPanel = () => (
        <CalcitePanel>
            <CalciteBlock heading='Add a new note' collapsible={false} open={true} loading={notesLoading || undefined}>
                <ControlledInputText
                    name="ticketDetails"
                    control={control}
                    label="Workitem Reference"
                    placeholder="Workitem Reference"
                />
                <ControlledInputTextArea
                    name="notes"
                    control={control}
                    required={true}
                    label="Reason"
                    placeholder="Reason"
                />

                <CalciteButton onClick={addNote}>Save Note</CalciteButton>
            </CalciteBlock>
            <CalciteList selectionMode='none'>
                {notes?.map((note, id) => (<CondensedListItem label={note.author} description={dayjs(note.timeStamp).fromNow()} labelDescription={`${note.notes}${note.ticketDetails ? `, ${note.ticketDetails}` : ''}`} key={id} />))}
            </CalciteList>
        </CalcitePanel>
    );

    return (
        <StyledCalciteModal id="edg-modal" escapeDisabled={true} open={showModal} fullscreen={true} onCalciteModalClose={closeModal} >
            <div slot="header">
                <CalciteNavigationLogo title={connectionStatus?.message} thumbnail={connectionStateThumbnail} heading={deviceId.toUpperCase()} description={connectionStatus?.message} />
                <CalciteActionBar scale="s" expandDisabled={true} expanded={true} overflowActionsDisabled={true} layout="horizontal">
                    <CalciteAction compact={true} appearance='transparent' title={pingStatus.message} textEnabled={false}>
                        <CalciteNavigationLogo thumbnail={pingStatus.thumbnail} />
                    </CalciteAction>
                    <CalciteAction compact={true} appearance='transparent' title={twinStatus.message} textEnabled={false}>
                        <CalciteNavigationLogo thumbnail={twinStatus.thumbnail} />
                    </CalciteAction>
                    <CalciteAction compact={true} appearance='transparent' title={dmModuleStatus.message} textEnabled={false}>
                        <CalciteNavigationLogo thumbnail={dmModuleStatus.thumbnail} />
                    </CalciteAction>
                    {!deviceLoading && twinInfo?.cradlePointStatus && (
                        <CalciteAction compact={true} appearance='transparent' title={twinInfo?.cradlePointStatus?.routerStatus?.indexOf('online') > -1 ? 'cradlepoint is online' : 'cradlepoint is offline'} textEnabled={false}>
                            <CalciteNavigationLogo thumbnail={twinInfo?.cradlePointStatus?.routerStatus?.indexOf('online') > -1 ? '/images/svgs/router-online.svg' : '/images/svgs/router-offline.svg'} />
                        </CalciteAction>
                    )}
                    {deviceLoading
                        ? (<CalciteAction text='Loading...' loading={true} />)
                        : (
                            <>
                                {twinInfo?.deviceType === config.deviceTypes.serial.name && config.deviceTypes.serial.grids.map((name, index) => <CalciteAction key={index} text={name} onClick={setActiveGridTabState} active={activeGridTab === name || undefined} />)}
                                {twinInfo?.deviceType === config.deviceTypes.i3.name && config.deviceTypes.i3.grids.map((name, index) => <CalciteAction key={index} text={name} onClick={setActiveGridTabState} active={activeGridTab === name || undefined} />)}
                                <CalciteAction text="Filtered Logs" textEnabled={true} onClick={toggleFilteredLogs}>
                                    <CalciteSwitch checked={filteredLogs || undefined} scale='s' />
                                </CalciteAction>
                                <CalciteAction text="Auto Refresh" textEnabled={true} onClick={toggleAutoRefresh}>
                                    <CalciteSwitch checked={autoRefresh || undefined} scale='s' />
                                </CalciteAction>
                            </>
                        )}
                </CalciteActionBar>
            </div>
            <StyledCalciteShell slot="content">
                <CalciteShellPanel slot='panel-end' resizable={true} widthScale='l'>
                    <CalciteActionBar layout='horizontal' expanded={true} expandDisabled={true} overflowActionsDisabled={true}>
                        <CalciteActionGroup>
                            <CalciteAction onClick={setActiveActionTabState} text={config.actions.deviceInfo} active={activeActionTab === config.actions.deviceInfo || undefined} />
                        </CalciteActionGroup>
                        <CalciteActionGroup>
                            <CalciteAction onClick={setActiveActionTabState} text={config.actions.heartbeat} active={activeActionTab === config.actions.heartbeat || undefined} indicator={heartbeat} />
                        </CalciteActionGroup>
                        <CalciteActionGroup>
                            <CalciteAction onClick={setActiveActionTabState} text={config.actions.interfaces} active={activeActionTab === config.actions.interfaces || undefined} />
                        </CalciteActionGroup>
                        <CalciteActionGroup>
                            <CalciteAction onClick={setActiveActionTabState} text={config.actions.parsingPolicy} active={activeActionTab === config.actions.parsingPolicy || undefined} />
                        </CalciteActionGroup>
                        <CalciteActionGroup>
                            <CalciteAction onClick={setActiveActionTabState} text={config.actions.parsedEvent} active={activeActionTab === config.actions.parsedEvent || undefined} indicator={parsedEvent} />
                        </CalciteActionGroup>
                        <CalciteActionGroup>
                            <CalciteAction onClick={setActiveActionTabState} text={config.actions.notes} active={activeActionTab === config.actions.notes || undefined} indicator={notes?.length > 0 || undefined} />
                        </CalciteActionGroup>
                    </CalciteActionBar>
                    {activeActionTab === config.actions.deviceInfo && (<DeviceInfoPanel />)}
                    {activeActionTab === config.actions.heartbeat && (<HeartbeatPanel />)}
                    {activeActionTab === config.actions.parsingPolicy && (<ParsingPolicyPanel />)}
                    {activeActionTab === config.actions.parsedEvent && (<ParsedEventPanel />)}
                    {activeActionTab === config.actions.interfaces && (<InterfacesPanel />)}
                    {activeActionTab === config.actions.notes && (<NotesPanel />)}
                </CalciteShellPanel>
                <CalciteShellPanel slot='panel-start' widthScale='s'>
                    <CalciteList selectionMode='none'>
                        <CalciteListItemGroup heading='Actions'>
                            <CalciteListItem label={pingLoading ? 'Pinging...' : 'Ping'} disabled={pingLoading || undefined} onClick={pingEdgDevice} />
                            <CalciteListItem label="Stream" onClick={streamEdgDevice} disabled={!twinInfo?.deviceStreamAvailable || undefined} />
                            <CalciteListItem label={deviceLoading ? 'Loading...' : twinInfo?.changeStatusText} onClick={toggleStatusEdgDevice} />
                            {statusesLoading
                                ? (<CalciteListItem label="Reboot" />)
                                : twinIsOnline
                                    ? <CalciteListItem label="Reboot" onClick={requestRebootEdgDevice} />
                                    : <OfflineListItem label="Reboot" />}
                        </CalciteListItemGroup>
                        {statusesLoading && (<CalciteProgress type='indeterminate' />)}
                        <CalciteListItemGroup heading='Restart EDG Application'>
                            {!statusesLoading && (
                                <>
                                    {twinIsOnline
                                        ? <CalciteListItem label="via app-restart" onClick={requestRestartEDGApplication} />
                                        : <OfflineListItem label="EDG Application Offline" />}
                                    {dmModuleIsOnline
                                        ? <CalciteListItem label="via Device Manager" onClick={requestRestartEDGApplicationFromDmModule} />
                                        : <OfflineListItem label="Device Manager Offline" />}
                                </>
                            )}
                        </CalciteListItemGroup>
                        {statusesLoading && (<CalciteProgress type='indeterminate' />)}
                        <CalciteListItemGroup heading="Restart Device Manager">
                            {!statusesLoading && (
                                <>
                                    {dmModuleIsOnline
                                        ? <CalciteListItem label="via app-restart" onClick={requestRestartDeviceManagerApplication} />
                                        : <OfflineListItem label="Device Manager Offline" />
                                    }
                                    {twinIsOnline
                                        ? <CalciteListItem label="via EDG Application" onClick={requestRestartDeviceManagerFromDeviceTwin} />
                                        : <OfflineListItem label="EDG Application Offline" />}

                                </>)}
                        </CalciteListItemGroup>
                        {statusesLoading && (<CalciteProgress type='indeterminate' />)}
                        <CalciteListItemGroup heading="SSH Actions">
                            <CalciteListItem label="SSH" onClick={requestSshUsingClient} disabled={!twinInfo?.deviceStreamAvailable || undefined} />
                            <CalciteListItem label="SFTP" onClick={requestScpUsingClient} disabled={!twinInfo?.deviceStreamAvailable || undefined} />
                            {/* <CalciteListItem label="Tunnel" onClick={tunnelUsingClient} disabled={!deviceStreamAvailable || undefined} /> */}
                            <CalciteListItem label="Install EDG SSH" onClick={installSSH} />
                        </CalciteListItemGroup>
                    </CalciteList>
                </CalciteShellPanel>
                <Grid
                    innerRef={gridRef}
                    onGridReady={onGridReady}
                    onCellClicked={onCellClicked}
                    autoSizeStrategy={config.grids.autoSizeStrategy}
                    onAsyncTransactionsFlushed={onAsyncTransactionsFlushed}
                />
                {priviledgedActionType && (
                    <>
                        {priviledgedServiceOnline
                            ? (
                                <CalciteAlert slot="alerts" open={true} icon="exclamation-mark-circle-f" kind="danger">
                                    <div slot="title">Error!</div>
                                    <div slot="message">
                                        {priviledgedServiceOnline}
                                    </div>
                                </CalciteAlert>
                            )
                            : (
                                <CalciteSheet slot="sheets" position="block-end" display-mode="float" open={true} onCalciteSheetClose={closePriviledgedAction}>
                                    <CalcitePanel closable={true} heading={`${deviceId} on ${iotHubId}`} loading={statusesLoading || undefined} onCalcitePanelClose={closePriviledgedAction}>
                                        <CalciteBlock heading='Please include a reason and workitem reference for this action.' open={true}>
                                            <ControlledInputText
                                                name="workItemReference"
                                                control={priviledgedActionControl}
                                                label="Workitem Reference"
                                                placeholder="Workitem Reference"
                                            />
                                            <ControlledInputTextArea
                                                name="reason"
                                                control={priviledgedActionControl}
                                                required={true}
                                                label="Reason"
                                                placeholder="Reason"
                                            />

                                        </CalciteBlock>
                                        <PriviledgedActionButton />
                                    </CalcitePanel>
                                </CalciteSheet>
                            )
                        }
                    </>
                )}
            </StyledCalciteShell>
        </StyledCalciteModal>
    );
};

export default EDGDeviceInfo;

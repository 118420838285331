import { CalciteLoader } from '@esri/calcite-components-react';
import { useEDGSavedCredentials } from '../../queries/edg/queries';
import DeviceStreamCredentials from './DeviceStreamCredentials';

const DeviceStreamContainer = ({
    iotHubId, deviceId, connectionId, setTerminalStatus,
}) => {
    const { hasSavedCredentialsLoading, hasSavedCredentials } = useEDGSavedCredentials(iotHubId, deviceId);

    if (hasSavedCredentialsLoading) {
        return (
            <CalciteLoader scale="s" text="Checking for saved credentials..." />
        );
    }

    return (<DeviceStreamCredentials
        hasSavedCredentials={hasSavedCredentials}
        connectionId={connectionId}
        iotHubId={iotHubId}
        deviceId={deviceId}
        setTerminalStatus={setTerminalStatus} />);
};

export default DeviceStreamContainer;

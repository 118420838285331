import { CalciteShellPanel, CalcitePanel, CalciteAction } from '@esri/calcite-components-react';
import { useCallback, useMemo, useRef } from 'react';
import dayjs from 'dayjs';
import { useHubSpotTenants, useRadiusEccs, useRadiusMonthlyUtilizationBreakdown } from '../../queries/utilization/queries';
import { getHubSpotCompanyLink } from '../../services/hubspot';
import Grid from '../grid';

const RadiusMonthlyBreakdown = ({ date, tenantId, onClose }) => {
    const gridRef = useRef();

    const defaultColDef = useMemo(() => ({ filter: true }), []);

    const { hubspotLoading, hubspot } = useHubSpotTenants();
    const { eccsLoading, eccs } = useRadiusEccs();
    const { detailLoading, detail } = useRadiusMonthlyUtilizationBreakdown(date, tenantId);

    const company = useMemo(() => hubspot?.find((hs) => hs.tenantId === tenantId), [hubspot, tenantId]);
    const ecc = useMemo(() => eccs?.find(({ eccId }) => eccId === tenantId), [eccs, tenantId]);
    const seats = useMemo(() => Number(company?.concurrentSeats || company?.seats), [company]);

    const cellStyler = useCallback(({ value }) => (!seats
        ? { color: '#000000', backgroundColor: '#dfdfdf' }
        : (value > seats) && { color: '#ffffff', backgroundColor: '#d90012' }), [seats]);

    const colDefs = useMemo(() => [
        {
            headerName: 'Date',
            field: 'date',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => dayjs(value).format('L'),
        },
        {
            headerName: 'Average',
            children: [
                {
                    headerName: 'Users',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    valueGetter: ({ data: { avgUsers } }) => Math.round(avgUsers),
                    cellStyle: cellStyler,
                },
            ],
        },
        {
            headerName: 'Min',
            children: [
                {
                    headerName: 'Users',
                    field: 'minUsers',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    cellStyle: cellStyler,
                },
                {
                    headerName: 'Time',
                    field: 'minTimeStamp',
                    cellRenderer: ({ value }) => dayjs(value).format('LTS'),
                },
                {
                    headerName: '%',
                    field: 'minPercent',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    cellRenderer: ({ value }) => `${Math.ceil(value * 100)}%`,
                },
            ],
        },
        {
            headerName: 'Max',
            children: [
                {
                    headerName: 'Users',
                    field: 'maxUsers',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    cellStyle: cellStyler,
                },
                {
                    headerName: 'Time',
                    field: 'maxTimeStamp',
                    cellRenderer: ({ value }) => dayjs(value).format('LTS'),
                },
                {
                    headerName: '%',
                    field: 'maxPercent',
                    cellRenderer: ({ value }) => `${Math.ceil(value * 100)}%`,
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                },
            ],
        },
    ], [detail]);

    const launchHubSpot = useCallback(() => {
        window.open(getHubSpotCompanyLink(company?.id), '_blank');
    }, [company]);

    const exportToExcel = useCallback(() => {
        gridRef.current.api.exportDataAsExcel();
    }, [gridRef]);

    return (
        <CalciteShellPanel slot='panel-end' widthScale='l'>
            <CalcitePanel heading='Monthly Report'
                description={`${ecc?.name} for ${dayjs(date).format('MMM YYYY')}`}
                loading={detailLoading || hubspotLoading || eccsLoading || undefined}
                closable={true}
                onCalcitePanelClose={onClose}
            >
                <CalciteAction
                    slot="header-actions-start"
                    title={`${seats} HubSpot seats recorded`}
                    onClick={launchHubSpot}
                    icon="users"
                    text={seats}
                    textEnabled={true} />
                <CalciteAction
                    slot="header-actions-end"
                    onClick={exportToExcel}
                    icon="export"
                    text="Export"
                    textEnabled={true} />
                <Grid
                    innerRef={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}
                    rowData={detail}
                />
            </CalcitePanel>
        </CalciteShellPanel>
    );
};

export default RadiusMonthlyBreakdown;

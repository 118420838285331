import {
    CalciteBlock, CalcitePanel, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import { useEffect, useState } from 'react';
import Alerts from './components/Alerts';
import Tree from './components/Tree/Tree';
import AddArea from './components/Areas/AddArea';
import UpdateArea from './components/Areas/UpdateArea';
import AddClient from './components/Client/AddClient';
import UpdateClient from './components/Client/UpdateClient';
import AddEndPoint from './components/Endpoints/AddEndPoint';
import UpdateEndPoint from './components/Endpoints/UpdateEndPoint';
import ConfirmationDialog from './components/UI/ConfirmationDialog';
import { useGetClients, useShouldShowAddArea, useShouldShowUpdateArea } from '../../stores/clients';
import { updateAlertState } from '../../stores/alerts';
import { setStoreStateValues, STORE_NAME } from '../../stores/store';
import { useClients } from '../../queries/els/queries';
import Charts from './components/Charts';
import { setPageTitle } from '../../services/pagescripts';

const GoogleEls = ({ router }) => {
    const [env, setEnv] = useState();

    useEffect(() => {
        setEnv(router.currentPage.params[0]);
    }, []);

    useEffect(() => {
        if (!env) return;

        console.log('env', env);

        setStoreStateValues(STORE_NAME.ENVIRONMENT, { env });
        setPageTitle(`Google ELS Partner Management - ${env.charAt(0).toUpperCase() + env.slice(1)}`);
    }, [env]);

    const showAddArea = useShouldShowAddArea();
    const showUpdateArea = useShouldShowUpdateArea();
    const {
        showAddClient, showUpdateClient, showAddEndpoint, showUpdateEndpoint, chartClient,
    } = useGetClients();

    const { error: clientsError, clients, loading } = useClients(env);

    useEffect(() => {
        if (!clientsError) return;

        updateAlertState('Error', clientsError, 'danger', 'top');
    }, [clientsError]);

    return (
        <CalciteShell>
            <CalciteShellPanel
                slot="panel-start"
                position="start"
                widthScale="l">
                <CalcitePanel
                    loading={loading}
                    disabled={clientsError}
                    heading="Clients">
                    <CalciteBlock open={true}>
                        <Tree clients={clients} />
                    </CalciteBlock>
                </CalcitePanel>
            </CalciteShellPanel>
            {showAddArea && (<AddArea />)}
            {showUpdateArea && (<UpdateArea />)}
            {showAddClient && (<AddClient />)}
            {showUpdateClient && (<UpdateClient />)}
            {showAddEndpoint && (<AddEndPoint />)}
            {showUpdateEndpoint && (<UpdateEndPoint />)}
            {!showAddArea && !showUpdateArea && (
                <Charts env={env} client={chartClient} />
            )}
            <ConfirmationDialog />
            <Alerts />
        </CalciteShell>
    );
};

export default GoogleEls;

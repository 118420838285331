export const createBlobUrl = (object, type) => {
    const json = typeof object === 'string'
        ? object
        : JSON.stringify(object);

    const blob = type
        ? new Blob([json], { type })
        : new Blob([json]);

    return URL.createObjectURL(blob);
};

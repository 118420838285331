import { useEffect, useMemo, useState } from 'react';
import { CalcitePanel, CalciteShell } from '@esri/calcite-components-react';
import { setPageTitle, toTitleCase } from '../../services/pagescripts';
import { post } from '../../services/webhelpers';
import Grid from '../grid';

let deviceAlertsContinuationToken = null;

const EDGAlerts = ({ router }) => {
    const [alertType, setAlertType] = useState();

    useEffect(() => {
        setAlertType(router.currentPage.params[0]);
        deviceAlertsContinuationToken = null;
        setPageTitle(`EDG Alerts | ${toTitleCase(router.currentPage.params[0])}`);
    }, []);

    const defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        filter: true,
        enableCellChangeFlash: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
          headerName: 'Alert Rule',
          field: 'alertRuleName',
          enableRowGroup: true,
        },
        {
          headerName: 'Severity',
          field: 'severity',
          enableRowGroup: true,
        },
        {
          headerName: 'Fired Time',
          field: 'lastModifiedDateTime',
          enableRowGroup: true,
        },
        {
          headerName: 'Monitor Condition',
          field: 'monitorCondition',
          enableRowGroup: true,
        },
        {
          headerName: 'Resource Name',
          field: 'targetResourceName',
          enableRowGroup: true,
        },
        {
          headerName: 'Monitor Service',
          field: 'monitorService',
          enableRowGroup: true,
        },
        {
          headerName: 'Signal Type',
          field: 'signalType',
          enableRowGroup: true,
        },
        { headerName: 'Alert Id', field: 'id', enableRowGroup: true },
        {
          headerName: 'Is Suppressed',
          field: 'isSuppressed',
          enableRowGroup: true,
        },
    ]);

    const dataSource = {
        rowCount: null,
        getRows: async (params) => {
          const { alerts, continuationToken } = await post(`/devices/alerts/${alertType || 'production'}`, { continuationToken: deviceAlertsContinuationToken });
          deviceAlertsContinuationToken = continuationToken;
          params.successCallback(alerts);
        },
    };

    return (
        <CalciteShell>
            <CalcitePanel>
                <Grid
                    rowModelType="infinite"
                    cacheBlockSize={50}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    datasource={dataSource}
                />
            </CalcitePanel>
        </CalciteShell>
    );
};

export default EDGAlerts;

import { CalcitePanel, CalciteSheet } from '@esri/calcite-components-react';
import styled from 'styled-components';
import ConsoleOnlineUsers from './ConsoleOnlineUsers';
import { toggleQuickView } from '../../stores/quickview';

const StyledCalciteSheet = styled(CalciteSheet)`
  z-index: 900;
`;

const QuickView = () => {
  const closePanel = () => {
    toggleQuickView(false);
  };

  return (
    <StyledCalciteSheet displayMode='float' widthScale='m' position='inline-end' open={true} onCalciteSheetClose={closePanel}>
      <CalcitePanel closable={true} heading='Online Users' onCalcitePanelClose={closePanel}>
        <ConsoleOnlineUsers />
      </CalcitePanel>
    </StyledCalciteSheet>
  );
};

export default QuickView;

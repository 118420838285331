import {
    CalciteBlock, CalciteButton, CalciteInput, CalciteInputMessage, CalciteLabel, CalcitePanel,
} from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {
 useEffect, useMemo, useRef, useState,
} from 'react';
import { FilePond } from 'react-filepond';
import { geojsonToWKT } from '@terraformer/wkt';
import MapPanel from '../MapPanel';
import ControlledInputText from '../UI/ControlledInputText';

import { updateAlertState } from '../../../../stores/alerts';
import { setAddAreaDialogState, setArea, useGetClients } from '../../../../stores/clients';
import { useGetEnvironment } from '../../../../stores/environment';
import { useAddArea } from '../../../../queries/els/queries';

import 'filepond/dist/filepond.min.css';

const validationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .test('Name-test', 'Name needs to be more than 5 characters', (value) => value.trim().length > 5),
});

const AddArea = () => {
    const env = useGetEnvironment();
    const downloadRef = useRef();

    const addArea = useAddArea();

    const {
        client, geojson, areaIsValid, error: areaIsValidError,
    } = useGetClients();

    const [uploadValidationChecks, setUploadValidationChecks] = useState(null);

    const [contentsToCheck, setContentsToCheck] = useState(null);

    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        setValue('name', '');
        setValue('url', '');

        return () => {
            setValue('name', '');
            setValue('url', '');
        };
    }, [setValue]);

    const [filename, setFilename] = useState(null);
    const [files, setFiles] = useState([]);

    const clearUpload = () => {
        setFilename(null);
        setArea(null);
    };

    const saveArea = async () => {
        const result = await trigger();
        if (!result || !areaIsValid) {
            updateAlertState('Error', 'Please correct the area before trying to save.', 'danger', 'top');
            return;
        }

        const { name } = getValues();

        const area = geojsonToWKT(geojson.features[0].geometry);

        addArea({
            env, clientId: client.clientId, name, area,
        });
        setAddAreaDialogState(null);
    };

    const close = () => {
        setAddAreaDialogState(null);
    };

    const isFileIsValidFeatureCollection = (parsedContents) => {
        setUploadValidationChecks(null);

        try {
            if (parsedContents.type !== 'FeatureCollection') {
                return ({
                    valid: false,
                    message: 'Upload is not a valid FeatureCollection.',
                });
            }

            if (parsedContents.features.length === 0) {
                return ({
                    valid: false,
                    message: 'Upload is a valid FeatureCollection but it does not contain any features.',
                });
            }

            if (parsedContents.features.some((f) => f.geometry.type !== 'Polygon' && f.geometry.type !== 'MultiPolygon')) {
                return ({
                    valid: false,
                    message: 'Upload is a valid FeatureCollection but it contains features that are not Polygons or MultiPolygons.',
                });
            }

            // if (parsedContents.features.some((feature) => feature.geometry.type === 'MultiPolygon' && feature.geometry.coordinates.length == 1)) {
            //     return ({
            //         valid: false,
            //         message: 'Upload is a valid FeatureCollection but it contains a MultiPolygon with only 1 ring. MultiPolygons and Polygons do not follow the same ordering rules.',
            //     });
            // }

            if (parsedContents.features.length > 1) {
                return ({
                    valid: false,
                    message: 'Upload is a valid FeatureCollection but it contains more than one feature.',
                });
            }

            return ({
                valid: true,
                message: 'Upload is a valid FeatureCollection with one feature.',
            });
        } catch (error) {
            return ({
                valid: false,
                message: 'Upload is not a valid FeatureCollection.',
            });
        }
    };

    useEffect(() => {
        if (!contentsToCheck) return;
        try {
            const parsedContents = JSON.parse(contentsToCheck);

            const validationChecks = isFileIsValidFeatureCollection(parsedContents);
            setUploadValidationChecks(validationChecks);

            if (!validationChecks.valid) {
                return;
            }

            setArea(JSON.parse(contentsToCheck));
        } catch (error) {
            updateAlertState('Error', error.message, 'danger', 'top');
        }
    }, [contentsToCheck]);

    useEffect(() => {
        if (files.length === 0) return;

        const [{ file }] = files;

        const reader = new FileReader();
        reader.onload = ({ target: { result: contents } }) => { setContentsToCheck(contents); };
        reader.readAsText(file);
        setFilename(file.name);
    }, [files]);

    const isUploadDisabled = useMemo(() => {
        if (!filename) return true;
        if (!uploadValidationChecks) return true;
        if (!uploadValidationChecks.valid) return true;
        if (!areaIsValid) return true;
        return undefined;
    }, [filename, uploadValidationChecks, areaIsValid]);

    return (
        <CalcitePanel
            heading={'Add Area'}
            description={client.name} >
            <CalciteBlock open={true}>
                <ControlledInputText
                    name="name"
                    control={control}
                    required={true}
                    label="Area Name"
                    placeholder="Area Name"
                />
                {filename && (
                    <CalciteLabel>
                        File Upload
                        <CalciteInput readOnly={true} value={filename} scale="m">
                            <CalciteButton iconStart="reset" slot="action" scale="m" onClick={clearUpload}>
                                Clear Upload
                            </CalciteButton>
                        </CalciteInput>
                        {uploadValidationChecks && !uploadValidationChecks.valid && (
                            <CalciteInputMessage status={'invalid'} icon={'exclamation-mark-triangle-f'}>{uploadValidationChecks.message}</CalciteInputMessage>
                        )}
                        {(areaIsValidError || areaIsValid) && (
                            <CalciteInputMessage status={areaIsValid ? 'valid' : 'invalid'} icon={areaIsValid ? 'check-circle-f' : 'exclamation-mark-triangle-f'}>{areaIsValid ? 'Area is valid' : areaIsValidError || 'Area is invalid'}</CalciteInputMessage>
                        )}
                    </CalciteLabel>
                )}
                {!filename && (
                    <FilePond ref={downloadRef} allowMultiple={false} maxFiles={1} onupdatefiles={setFiles} />
                )}
            </CalciteBlock>
            <CalciteButton slot="footer-start" kind='neutral' onClick={close}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={saveArea} disabled={isUploadDisabled}>
                Upload Area
            </CalciteButton>
            <MapPanel />
        </CalcitePanel>
    );
};

export default AddArea;

import { CalciteAction, CalciteListItem } from '@esri/calcite-components-react';
import { useMemo, useState } from 'react';
import EncodedTable from './EncodedTable';
import JsonTable from './JsonTable';

const BodyTable = ({ headers, body }) => {
    const isJsonTableValid = useMemo(() => {
        try {
            JSON.parse(body.Raw);
            return true;
        } catch (e) {
            return false;
        }
    }, [body]);

    const isEncodedTableValid = useMemo(() => {
        try {
            const isValid = (headers['Content-Type'] && headers['Content-Type'].indexOf('application/x-www-form-urlencoded') > -1);
            if (!isValid) return false;
            const parsedValues = body.Raw.split('&');
            return parsedValues.length > 0;
        } catch (error) {
            return false;
        }
    }, [headers]);

    const [showValues, setShowValues] = useState(null);

    return (
        <>
            {showValues === 'url' && (<EncodedTable body={body} />)}
            {showValues === 'json' && (<JsonTable body={body} />)}
            <CalciteListItem label='Body' description={body.Formatted ?? body.Raw}>
                {(isEncodedTableValid || isJsonTableValid)
                ? (<CalciteAction slot="actions-start" icon={showValues ? 'view-hide' : 'view-visible'} onClick={() => setShowValues(showValues ? null : isEncodedTableValid ? 'url' : 'json')} />)
                : (<CalciteAction slot="actions-start" icon="code" />)}
            </CalciteListItem>
        </>
    );
};

export default BodyTable;

import { CalciteTree, CalciteTreeItem } from '@esri/calcite-components-react';
import StatusToggle from '../UI/StatusToggle';
import Action from './Action';
import TreeItem from './TreeItem';
import { setAddEndpointDialogState, setUpdateEndPointDialogState } from '../../../../stores/clients';

const Endpoints = ({ client }) => client.endpoints.length > 0 && (
    <CalciteTreeItem expanded={true}>
        <Action
            slot="actions-end"
            icon="plus"
            scale="s"
            textEnabled={true}
            text="Add Endpoint"
            onClick={() => setAddEndpointDialogState({ ...client })}
        />
        Endpoints
        <CalciteTree slot="children" lines={true}>
            {client.endpoints.map(((endpoint) => (
                <TreeItem
                    key={endpoint.endpointId}
                    onClick={() => setUpdateEndPointDialogState(endpoint)}
                    iconStart="webhook">
                    <StatusToggle enabled={endpoint.enabled} withoutSlot={true} />
                    {endpoint.name}
                </TreeItem>
            )))}
        </CalciteTree>
    </CalciteTreeItem>
);

export default Endpoints;

import {
  CalciteAction, CalciteAvatar, CalciteIcon, CalciteList, CalciteListItem, CalciteLoader,
} from '@esri/calcite-components-react';
import { useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { launchInNewWindow } from '../../services/webhelpers';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { GetInstance } from '../../services/helperscripts';
import dayjs from '../../services/dayjs';
import { userPresenceChannel } from '../../instances/channels';
import { InstanceNames } from '../../instances/names';
import { queries } from '../../queries/queries';
import { useConsoleOnlineUsers } from '../../queries/consoleonlineusers/queries';

const StyledCalciteListItem = styled(CalciteListItem)`
  margin-bottom: 10px;
`;

const StyledCalciteAvatar = styled(CalciteAvatar)`
  margin-left: 10px;
`;

const AvailableIcon = styled(CalciteIcon)`
  color: #13a10e;
  margin-right: 10px;
`;

const AwayIcon = styled(CalciteIcon)`
  color: #d13438;
  margin-right: 10px;
`;

const { getPhoto } = await GetInstance(InstanceNames.Cache);

const ConsoleOnlineUsers = () => {
  const { onlineUsers, onlineUsersLoading } = useConsoleOnlineUsers();
  const queryClient = useQueryClient();

  usePostalEvent(userPresenceChannel, 'update', async ({ topic, payload }) => {
    if (topic.endsWith('remove')) {
      queryClient.invalidateQueries(queries.consoleOnlineUsers.all());
      return;
    }

    const user = onlineUsers.find(({ emailAddress }) => emailAddress === payload.emailAddress);
    if (user === undefined) return;

    if (user.blob == null) {
      try {
        user.blob = await getPhoto(user.emailAddress);
      } catch (error) {
        console.log('userpresence', 'blob error', user, error);
      }
    }

    const connection = user.connections && user.connections.find(({ connectionId }) => connectionId === payload.clientId);
    if (connection === undefined) {
      queryClient.invalidateQueries(queries.consoleOnlineUsers.all());
      return;
    }

    connection.detail = payload.detailOverride;
    connection.hash = payload.hash;
    connection.isVisible = payload.isVisible;
    connection.path = payload.path;
  }, [onlineUsers]);

  return (
    <CalciteList selectionMode='none' loading={onlineUsersLoading || undefined}>
      {onlineUsersLoading && (<CalciteLoader type='indeterminite' />)}

      {!onlineUsersLoading && onlineUsers
        .sort(({ firstName: a }, { firstName: b }) => a.localeCompare(b))
        .map(({
          emailAddress, firstName, lastName, connections, blob,
        }) => (
          <StyledCalciteListItem key={emailAddress} label={`${firstName} ${lastName}`} description={emailAddress} open={true}>
            {blob
              ? <StyledCalciteAvatar slot='content-start' thumbnail={URL.createObjectURL(blob)} />
              : <StyledCalciteAvatar slot='content-start' fullName={`${firstName} ${lastName}`} />
            }
            {connections.filter(({ isVisible }) => isVisible).length > 0
              ? <AvailableIcon slot='content-end' scale='s' icon='check-circle-f' />
              : <AwayIcon slot='content-end' scale='s' icon='minus-circle-f' />
            }
            <CalciteList selectionMode='none'>
              {connections
                .filter(({ path }) => path != null)
                .map(({
                  connectionId, hash, path, detail, dateLastUpdated, browserInformation,
                }) => {
                  let linkIcon = <i className="fa-duotone fa-browser" title="Unknown Browser"></i>;
                  let title = '';
                  if (browserInformation) {
                    const {
                      platform, platformVersionName, brand, version,
                    } = browserInformation;

                    title = platform === null
                      ? 'Unknown Browser'
                      : platform === 'Windows'
                        ? `${brand} ${version} on ${platformVersionName}`
                        : `${brand} ${version} on ${platform} ${platformVersionName}`;

                    switch (brand) {
                      case 'Chromium':
                      case 'Google Chrome':
                        linkIcon = 'fa-brands fa-chrome fa-lg';
                        break;
                      case 'Microsoft Edge':
                        linkIcon = 'fa-brands fa-edge fa-lg';
                        break;
                      default:
                        linkIcon = 'fa-duotone fa-browser fa-lg';
                        break;
                    }
                  }
                  const launchInNewWindowWithHash = () => launchInNewWindow(hash === '' ? path : `${path}#${hash}`);
                  return (
                    <CalciteListItem key={connectionId} label={detail} description={dayjs(dateLastUpdated).fromNow()} open={true}>
                      <div slot='content-start'>
                        <i className={linkIcon} title={title}></i>
                      </div>
                      <CalciteAction slot='actions-end' icon='link' text='Open' onClick={launchInNewWindowWithHash} />
                    </CalciteListItem>
                  );
                })}
            </CalciteList>
          </StyledCalciteListItem>
        ))}
    </CalciteList>
  );
};

export default ConsoleOnlineUsers;

import { useMutation } from '@tanstack/react-query';
import { post } from '../../services/webhelpers';
import { queries } from '../queries';
import { useQueryWithPrefixedResults } from '../../customhooks/usequerywithprefixedresults';
import { useUpdate } from '../../customhooks/useupdate';

export const useNcrStream = (environment) => useQueryWithPrefixedResults('stream', queries.edg.ncr(environment));
export const useEDGInvalidLogsForSourceDateTime = () => useQueryWithPrefixedResults('invalidLogs', queries.edg.invalidLogsForSourceDateTime());
export const useEDGInvalidLogsForNoAgentId = () => useQueryWithPrefixedResults('invalidLogs', queries.edg.invalidLogsForNoAgentId());
export const useEDGInvalidLogsForInvalidCoords = () => useQueryWithPrefixedResults('invalidLogs', queries.edg.invalidLogsForInvalidCoords());

export const useEDGSavedCredentials = (iotHubId, deviceId) => useQueryWithPrefixedResults('hasSavedCredentials', queries.edg.hasSavedCredentials(iotHubId, deviceId));
export const useEDGNotes = (iotHubId, deviceId) => useQueryWithPrefixedResults('notes', queries.edg.notes(iotHubId, deviceId));
export const useEDGStatuses = (iotHubId, deviceId) => useQueryWithPrefixedResults('statuses', queries.edg.statuses(iotHubId, deviceId));
export const useEDGPing = (iotHubId, deviceId) => useQueryWithPrefixedResults('ping', queries.edg.ping(iotHubId, deviceId));
export const useEDGDevice = (iotHubId, deviceId) => useQueryWithPrefixedResults('device', queries.edg.device(iotHubId, deviceId));
export const useEDGInterfaces = (iotHubId, deviceId) => useQueryWithPrefixedResults('interfaces', queries.edg.interfaces(iotHubId, deviceId));
export const useEDGConfigComponents = (iotHubId, deviceId) => useQueryWithPrefixedResults('components', queries.edg.components(iotHubId, deviceId));
export const useEDGGridEvents = (iotHubId, deviceId, slug) => useQueryWithPrefixedResults('events', queries.edg.gridEvents(iotHubId, deviceId, slug));
export const useEDGParser = (iotHubId, deviceId, parserId) => useQueryWithPrefixedResults('parser', queries.edg.parser(iotHubId, deviceId, parserId), parserId != null);
export const useEdgParsePolicy = () => useMutation({
    mutationFn: (activeParsingPolicy) => post('/devices/parse', activeParsingPolicy),
});
export const useEdgDeviceStream = () => useMutation({
    mutationFn: ({ iotHubId, deviceId }) => post('/devices/streams/session', { iotHubId, deviceId }),
});

export const useAddEDGNote = (iotHubId, deviceId) => useUpdate(({ notes, ticketDetails }) => post(`/devices/${iotHubId}/${deviceId}/notes`, {
    iotHubId, deviceId, notes, ticketDetails,
}), [queries.edg.notes(iotHubId, deviceId)]);

export const useUpdateEDGDeviceStatus = (iotHubId, deviceId) => useUpdate(({ status }) => post(`/devices/${iotHubId}/${deviceId}/status/${status}`), [queries.edg.device(iotHubId, deviceId)]);
export const useEDGActionWithNotes = (iotHubId, deviceId) => useUpdate(({
    channel, application, reason, workItemReference,
}) => post(`/devices/${iotHubId}/${deviceId}/${channel}/${application}`, { reason, workItemReference }), []);
export const useEDGReboot = (iotHubId, deviceId) => useUpdate(({ reason, workItemReference }) => post(`/devices/${iotHubId}/${deviceId}/reboot`, { reason, workItemReference }), []);

const createLegacyStream = ({
    iotHubId, deviceId, connectionId, reason, workItemReference, username, password, columns, rows,
}) => post(`/devices/${iotHubId}/${deviceId}/streams/create/credentials`, {
    connectionId, reason, workItemReference, username, password, columns, rows,
});

const createSecureStream = ({
    iotHubId, deviceId, connectionId, reason, workItemReference, columns, rows,
}) => post(`/devices/${iotHubId}/${deviceId}/streams/create/secure`, {
    connectionId, reason, workItemReference, columns, rows,
});

export const useCreateLegacyStream = () => useUpdate(createLegacyStream);
export const useCreateSecureStream = () => useUpdate(createSecureStream);

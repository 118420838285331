import { useState } from 'react';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {
    CalciteBlock, CalciteButton, CalcitePanel,
} from '@esri/calcite-components-react';
import ControlledInputText from '../googleels/components/UI/ControlledInputText';
import DeviceStreamTerminal from './DeviceStreamTerminal';

const legacyValidationSchema = () => Yup.object().shape({
    workItemReference: Yup.string(),
    reason: Yup.string().required('Reason is required.'),
    username: Yup.string().required('Username is required.'),
    password: Yup.string().required('Password is required.'),
});

const securedValidationSchema = () => Yup.object().shape({
    workItemReference: Yup.string(),
    reason: Yup.string().required('Reason is required.'),
});

const DeviceStreamCredentials = ({
    iotHubId, deviceId, connectionId, setTerminalStatus, hasSavedCredentials,
}) => {
    const [showCredentials, setShowCredentials] = useState(true);

    const {
        control, trigger, getValues,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(
            hasSavedCredentials
                ? securedValidationSchema()
                : legacyValidationSchema(),
        ),
        defaultValues: {
            username: 'Administrator',
        },
    });

    const connectToDevice = async () => {
        const result = await trigger();
        if (!result) return;
        setShowCredentials(false);
    };

    if (showCredentials) {
        return (
            <CalcitePanel heading='Credentials'>
                <CalciteBlock open={true}>
                    <ControlledInputText
                        name="workItemReference"
                        control={control}
                        label="Workitem Reference"
                        placeholder="Zendesk Ticket Number"
                    />
                    <ControlledInputText
                        name="reason"
                        control={control}
                        required={true}
                        label="Reason"
                        placeholder="Reason"
                    />
                    {!hasSavedCredentials && (
                        <>
                            <ControlledInputText
                                name="username"
                                control={control}
                                required={true}
                                label="Username"
                                placeholder="Username"
                            />
                            <ControlledInputText
                                name="password"
                                control={control}
                                required={true}
                                type="password"
                                label="Password"
                            />
                        </>
                    )}
                </CalciteBlock>
                <CalciteButton slot='footer' iconEnd="launch" width="full" onClick={connectToDevice}>Connect</CalciteButton>
            </CalcitePanel>
        );
    }

    return <DeviceStreamTerminal
        isSecure={hasSavedCredentials}
        iotHubId={iotHubId}
        deviceId={deviceId}
        connectionId={connectionId}
        setTerminalStatus={setTerminalStatus}
        {...getValues()}
    />;
};

export default DeviceStreamCredentials;

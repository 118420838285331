/* eslint-disable react/prop-types */
import { CalciteTreeItem } from '@esri/calcite-components-react';
import { useEffect, useRef } from 'react';

const TreeItem = (props) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        if (!buttonRef.current) {
            return null;
        }

        const buttonElement = buttonRef.current;
        const toggleClickInvoked = () => {
            props.onClick();
        };
        buttonElement.addEventListener('click', toggleClickInvoked);

        return () => buttonElement.removeEventListener('click', toggleClickInvoked);
    }, [props.children]);

    return (
        <CalciteTreeItem
            ref={buttonRef}
            {...props}
        >
            {props.children}
        </CalciteTreeItem>
    );
};

export default TreeItem;

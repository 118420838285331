import { CalciteTree, CalciteTreeItem } from '@esri/calcite-components-react';
import StatusToggle from '../UI/StatusToggle';
import ClientActions from './ClientActions';
import Endpoints from './Endpoints';
import Areas from './Areas';
import Clients from './Clients';

const Client = ({ client }) => {
    const hasChildren = client.clients.length + client.areas.length + client.endpoints.length > 0;
    const isRootLevel = client.parentClientId === null;

    if (isRootLevel) {
        return (
            <Clients client={client} />
        );
    }

    return (
        <CalciteTreeItem
            key={client.clientId}
            expanded={true}
        >
            <ClientActions client={client} />
            <StatusToggle enabled={client.enabled} withoutSlot={true} />
            {client.name}
            {hasChildren && (
                <CalciteTree slot="children" lines={true}>
                    <Endpoints client={client} />
                    <Areas client={client} />
                    <Clients client={client} />
                </CalciteTree>
            )}
        </CalciteTreeItem>
    );
};

export default Client;

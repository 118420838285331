import { CalciteAction } from '@esri/calcite-components-react';
import { useEffect, useState } from 'react';
import UtilizationPivotGrid from './UtilizationPivotGrid';
import UtilizationStandardGrid from './UtilizationStandardGrid';
import { FullSizeGrid } from '../griddefaults';

const UtilizationGrid = ({
    pivot, columnDefs, loading, rowData, onCellClicked, autoGroupColumnDef,
}) => {
    const [exportAction, setExportAction] = useState(false);

    useEffect(() => () => {
        setExportAction(false);
        return () => {
            setExportAction(false);
        };
    }, []);

    return (
        <>
            <CalciteAction icon="export" text="Export" slot="header-actions-end" textEnabled={true} onClick={setExportAction} />
            {pivot
                ? (
                    <UtilizationPivotGrid
                        columnDefs={columnDefs}
                        autoGroupColumnDef={autoGroupColumnDef}
                        exportAction={exportAction}
                        loading={loading}
                        rowData={loading ? [] : rowData}
                    />
                )
                : (
                    <UtilizationStandardGrid
                        exportAction={exportAction}
                        columnDefs={columnDefs}
                        onCellClicked={onCellClicked}
                        loading={loading}
                        rowData={loading ? [] : rowData}
                    />
                )}
        </>
    );
};

export default UtilizationGrid;

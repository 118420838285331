import { useMemo } from 'react';
import { useInboundMetrics } from '../../../../queries/els/queries';
import { chartOptionsDefaults } from './chatDefaults';
import Chart from './Chart';

const InboundMetrics = ({ env, reportValues }) => {
    const { inboundMetrics, inboundMetricsLoading } = useInboundMetrics(env, reportValues?.back, reportValues?.type, reportValues?.interval);

    const options = useMemo(() => ({
        ...chartOptionsDefaults,
        legend: {
            enabled: false,
        },
        series: [{
            name: 'Inbound Events',
            data: inboundMetrics?.map(({ receivedTimeStamp, count }) => [new Date(receivedTimeStamp).getTime(), count]).sort((a, b) => a[0] - b[0]) || [],
        }],
    }), [inboundMetrics]);

    return (
        <Chart
            heading='Inbound Volumes'
            loading={inboundMetricsLoading || undefined}
            options={options}
        />
    );
};

export default InboundMetrics;

import { CalciteAction } from '@esri/calcite-components-react';
import { useCallback, useState } from 'react';
import { breadcrumbClickChannel } from '../../instances/channels';

const ToggleAction = ({ item }) => {
    const [checked, setChecked] = useState(item.defaultChecked);

    const handleClick = useCallback(() => {
        breadcrumbClickChannel.publish(!checked ? item.trueResponse : item.falseResponse);
        setChecked(!checked);
    }, [checked]);

    return (
        <CalciteAction
            text={item.text}
            icon={checked ? 'toggle-on' : 'toggle-off'}
            onClick={handleClick} />
    );
};

export default ToggleAction;

import postal from 'postal';

export class Channels {
  constructor() {
    this.powerBiChannel = postal.channel('powerbi');
    this.pageChannel = postal.channel('page');
    this.userPresenceChannel = postal.channel('userpresence');
    this.deviceInfoChannel = postal.channel('device-info');
    this.breadcrumbClickChannel = postal.channel('breadcrumb-click');
    this.searchChannel = postal.channel('search');
    this.mqttChannel = postal.channel('mqtt');
    this.pushChannel = postal.channel('push');
    this.notificationsChannel = postal.channel('notifications');
    this.mapsChannel = postal.channel('maps');
    this.jsonEditorChannel = postal.channel('json-editor');

    this.channelSubcriptions = [];
  }

  static getInstance = () => {
    if (!this.instance) {
      this.instance = new Channels();
    }

    return this.instance;
  };

  jsonEditorChannel = () => this.jsonEditorChannel;

  powerBiChannel = () => this.powerBiChannel;

  pageChannel = () => this.pageChannel;

  userPresenceChannel = () => this.userPresenceChannel;

  deviceInfoChannel = () => this.deviceInfoChannel;

  breadcrumbClickChannel = () => this.breadcrumbClickChannel;

  searchChannel = () => this.searchChannel;

  mqttChannel = () => this.mqttChannel;

  pushChannel = () => this.pushChannel;

  notificationsChannel = () => this.notificationsChannel;

  mapsChannel = () => this.mapsChannel;

  subscribeToChannel = (channel, topic, callback) => {
    const sub = channel.subscribe(topic, callback);
    this.channelSubcriptions.push(sub);
  };

  unsubscribeFromChannels = () => {
    this.channelSubcriptions.forEach((sub) => sub.unsubscribe());
  };
}

export const {
  powerBiChannel, pageChannel, userPresenceChannel, deviceInfoChannel, breadcrumbClickChannel, searchChannel,
  mqttChannel, pushChannel, notificationsChannel, mapsChannel, subscribeToChannel, unsubscribeFromChannels,
  jsonEditorChannel,
} = Channels.getInstance();

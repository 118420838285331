import { CalciteTree, CalciteTreeItem } from '@esri/calcite-components-react';
import Client from './Client';
import Action from './Action';
import { setAddClientDialogState } from '../../../../stores/clients';

const Clients = ({ client }) => {
    const addClient = (parentClientId) => {
        setAddClientDialogState({ parentClientId });
    };

    return (
        <CalciteTreeItem expanded={true}>
            <Action
                slot="actions-end"
                icon="plus"
                scale="s"
                textEnabled={true}
                text="Add Client"
                onClick={() => addClient(client.clientId)}
            />
            Clients
            {client.clients.length > 0 && (
                <CalciteTree slot="children">
                    {client.clients?.map((child) => <Client key={child.clientId} client={child} />)}
                </CalciteTree>
            )}
        </CalciteTreeItem>
    );
};

export default Clients;

import { CalciteTree } from '@esri/calcite-components-react';
import Client from './Client';

const Tree = ({ clients }) => (
    <CalciteTree lines={true} selectionMode="none">
        {clients?.map((client) => <Client key={client.clientId} client={client} />)}
    </CalciteTree>
);

export default Tree;

import { createGrid } from 'ag-grid-community';
import { v4 as uuidv4 } from 'uuid';
import dayjs from './dayjs';

export const sideBar = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressPivots: true,
        suppressPivotMode: true,
        suppressRowGroups: true,
        suppressValues: true,
      },
    },
  ],
  defaultToolPanel: '',
};

export const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left',
    },
  ],
};

export const barPercentSparkline = {
  sparklineOptions: {
    type: 'bar',
    label: {
      enabled: true,
      placement: 'insideEnd',
      color: 'black',
      fontSize: 12,
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
      formatter: ({ value }) => `${value} %`,
    },
    paddingOuter: 0,
    padding: {
      top: 0,
      bottom: 0,
    },
    valueAxisDomain: [0, 100],
    axis: {
      strokeWidth: 0,
    },
    tooltip: {
      enabled: false,
    },
    formatter: ({ yValue }) => {
      let fill;
      if (yValue <= 60) {
        fill = '#19AD79';
      } else if (yValue < 80) {
        fill = '#FFd945';
      } else {
        fill = '#D83C31';
      }
      return {
        fill,
      };
    },
  },
};

export const comparator = (a, b) => {
  if (a === b) return 0;
  return a > b ? -1 : 1;
};

export const getManageLink = ({ env, tenantId }) => `/#/${env}/${tenantId}/manage`;

export const loadMonolithTenant = ({ colDef: { field }, data: { environment: env, tenantId } }) => {
  const launchFields = ['tenantName', 'name'];
  if (!launchFields.includes(field)) return;
  window.location.href = getManageLink({ env, tenantId });
};

export const createGridWithOptions = (eGridDiv, gridOptions, params) => {
  const options = {
    context: { gridId: eGridDiv.id },
    ...gridOptions,
  };
  return createGrid(eGridDiv, options, params);
};

export const addEventLogMessage = (grid, message) => {
  grid.applyTransactionAsync({ add: [{ id: uuidv4(), message, timeStamp: new Date() }] });
};

export const createEventLogGrid = (eGridDiv) => {
  const grid = createGrid(eGridDiv, {
    sideBar,
    statusBar,
    columnDefs: [
      {
        headerName: 'Timestamp',
        sortable: true,
        width: 200,
        valueGetter: ({ data: { timeStamp } }) => dayjs.utc(timeStamp).valueOf(),
        comparator,
        cellRenderer: ({ value }) => dayjs.utc(value).format('HH:mm:ss.SSS'),
      },
      {
        headerName: 'Message',
        field: 'message',
        width: 600,
      },
    ],
    getRowId: ({ data: { id } }) => id,
    overlayNoRowsTemplate: '<span class="ag-overlay-loading-center">There are no rows to show.</span>',
  });
  grid.updateGridOptions({ rowData: [] });
  grid.applyColumnState({
    state: [{ colId: '0', sort: 'asc' }],
    defaultState: { sort: null },
  });

  return grid;
};

export const getSimpleHtmlControl = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = `<span>${html}</span>`;
  return tempDiv.firstChild;
};

export const getRadiusAdminLink = (domainSuffix, id, name, type, section) => `<span class="show_link_on_hover"><a href="https://admin.${domainSuffix}/#/${type}/${id}/${section}" target="_blank" class="label label-info m-r-5 text-white"><i class="fa-duotone fa-external-link" aria-hidden="true"></i></a>${name}</span>`;
export const getRadiusAdminLinkAsHtmlControl = (domainSuffix, id, name, type, section) => getSimpleHtmlControl(getRadiusAdminLink(domainSuffix, id, name, type, section));
export const badgeLabel = (fontAwesomeIcon, label) => `<span class="label label-${label ?? 'default'} m-r-5"><i class="${fontAwesomeIcon}"></i></span>`;

export const cell = (text, styleId) => ({
  styleId,
  data: {
    type: /^\d+$/.test(text) ? 'Number' : 'String',
    value: String(text),
  },
});

export const getRowCount = (api) => {
  let count = 0;
  api.forEachLeafNode(() => { count += 1; });
  return Number(count).toLocaleString();
};

export const getRows = (api) => {
  const items = [];
  api.forEachLeafNode(({ data }) => { items.push(data); });
  return items;
};

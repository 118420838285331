import ReactCountryFlag from 'react-country-flag';

const CountryFlag = ({ countryCode }) => <ReactCountryFlag
    countryCode={countryCode}
    svg
    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
    cdnSuffix="svg"
    title={countryCode}
/>;
export default CountryFlag;

import { CalciteButton, CalciteDialog } from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import ControlledInputText from '../UI/ControlledInputText';
import ControlledSwitch from '../UI/ControlledSwitch';
import { useDeleteEndpoint, useUpdateEndpoint } from '../../../../queries/els/queries';
import { setUpdateEndPointDialogState, useGetClients } from '../../../../stores/clients';
import { clearConfirmationDialog, updateConfirmationDialog } from '../../../../stores/dialogs';
import { useGetEnvironment } from '../../../../stores/environment';
import HeadersTable from './HeadersTable';

const validationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .test('Name-test', 'Name needs to be more than 5 characters', (value) => value.trim().length > 5),
    url: Yup.string()
        .required('Url is required.')
        .test('https-test', 'Url needs to be secure and start with https://', (value) => {
            try {
                const url = new URL(value);
                return url.protocol === 'https:';
            } catch (e) {
                return false;
            }
        }),
});

const UpdateEndPoint = () => {
    const env = useGetEnvironment();
    const updateEndpoint = useUpdateEndpoint();
    const deleteEndpoint = useDeleteEndpoint();

    const { endpoint, showUpdateEndpoint } = useGetClients();

    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: endpoint,
    });

    useEffect(() => {
        if (!endpoint) return;

        setValue('name', endpoint.name);
        setValue('url', endpoint.url);
        setValue('enabled', endpoint.enabled);
    }, [setValue, endpoint]);

    const onCalciteDialogClose = () => {
        setUpdateEndPointDialogState(null);
    };

    const saveEndpoint = async () => {
        const result = await trigger();
        if (!result) return;

        const { name, url, enabled } = getValues();

        updateEndpoint({
            env, endpointId: endpoint.endpointId, clientId: endpoint.clientId, name, url, headers: '', enabled,
        });
        onCalciteDialogClose();
    };

    const removeEndpoint = async () => {
        updateConfirmationDialog(
            'Confirm Delete',
            `Are you sure you want to delete ${endpoint.name}?`,
            'danger',
            'Delete',
            () => {
                deleteEndpoint({ env, endpointId: endpoint.endpointId });
                onCalciteDialogClose();
                clearConfirmationDialog();
            },
        );
    };

    return (
        <CalciteDialog
            slot="dialogs"
            open={showUpdateEndpoint || undefined}
            modal={true}
            closeDisabled={true}
            onCalciteDialogClose={onCalciteDialogClose}
            heading={'Update Endpoint'}
            description={endpoint?.name}
        >
            <ControlledInputText
                name="name"
                control={control}
                label="Name"
                required={true}
                placeholder="Name"
            />
            <ControlledInputText
                name="url"
                control={control}
                label="Url"
                required={true}
                placeholder="HTTPS Webhook URL"
                icon="webhook"
            />
            <ControlledSwitch
                name="enabled"
                control={control}
                label="Enabled"
            />
            <HeadersTable clientId={endpoint?.clientId} />
            <CalciteButton slot="footer-start" kind="neutral" onClick={onCalciteDialogClose}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-start" kind="danger" onClick={removeEndpoint}>
                Delete
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={saveEndpoint}>
                Update Endpoint
            </CalciteButton>
        </CalciteDialog >
    );
};

export default UpdateEndPoint;

import { useStore } from '@tanstack/react-store';
import { setStoreState, store, STORE_NAME } from './store';

export const updateAlertState = (title, message, kind, placement) => {
    setStoreState(STORE_NAME.ALERTS, 'alert', {
        title,
        message,
        kind: kind ?? 'info',
        placement: placement ?? 'top',
    });
};

export const clearAlertState = () => {
    setStoreState(STORE_NAME.ALERTS, 'alert', null);
};

export const useAlert = () => useStore(store, ({ [STORE_NAME.ALERTS]: alerts }) => alerts.alert);

/* eslint-disable react/prop-types */
import { useState } from 'react';
import toast, { Toaster, resolveValue } from 'react-hot-toast';
import {
  CalciteAction, CalciteAlert, CalciteLink, CalciteMeter, CalciteNotice,
} from '@esri/calcite-components-react';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { notificationsChannel } from '../../instances/channels';
import { launchInNewWindow } from '../../services/webhelpers';

const Notifications = () => {
  const [toastId, setToastId] = useState();
  const [alertTitle, setAlertTitle] = useState();
  const [alertMessage, setAlertMessage] = useState();

  usePostalEvent(notificationsChannel, 'alert', ({ title, message }) => {
    console.debug('[notificationsChannel] alert', title, message);
    setAlertTitle(title);
    setAlertMessage(message);
  });

  usePostalEvent(notificationsChannel, 'dismiss-notifications', () => {
    console.debug('[notificationsChannel] dismiss-notifications');
    toast.remove();
    setToastId(null);
  });

  const onCloseClick = (event) => {
    console.debug('[notificationsChannel] onCloseClick', event);
    toast.remove();
    setToastId(null);
  };

  const getToast = (props) => {
    const {
      type, title, message, link, linkIcon, linkText, target, slot, value, max,
    } = props;
    console.debug('getToast', props);

    let icon = 'information';
    let kind = type;

    switch (type) {
      case 'success':
        icon = 'check-circle';
        break;
      case 'error':
        icon = 'exclamation-mark-triangle-f';
        kind = 'danger';
        break;
      case 'warning':
        icon = 'exclamation-mark-triangle';
        break;
      default:
        icon = 'information';
        kind = 'info';
        break;
    }

    const showProgress = value !== undefined && max !== undefined && value < max;

    return (
      <CalciteNotice open={true} kind={kind} icon={icon} closable={true} onCalciteNoticeBeforeClose={onCloseClick}>
        <span slot="title">{title}</span>
        <span slot="message">{message}</span>
        {showProgress && (
          <CalciteMeter rangeLabels={true} slot="link" value={value} max={max} fillType='single' rangeLabelType='units' appearance='solid' scale='s' />
        )}
        {link && (
          <>
            {(slot ?? 'link') === 'link'
              ? (<CalciteLink slot='link' title={linkText} href={link} target={target ?? '_blank'}>{linkText}</CalciteLink>)
              : (<CalciteAction slot='actions-end' icon={linkIcon ?? 'launch'} text={linkText} textEnabled={true} onClick={() => launchInNewWindow(link)} />)}
          </>

        )}
      </CalciteNotice>);
  };

  usePostalEvent(notificationsChannel, 'progress-notification', (object) => {
    console.debug('[notificationsChannel] progress-notification', object);

    if (toastId) {
      toast.loading(getToast(object), { id: toastId });
      return;
    }

    const id = toast.loading(getToast(object), { position: object.position ?? 'top-center' });
    setToastId(id);
  }, [toastId]);

  usePostalEvent(notificationsChannel, 'notification', (object) => {
    console.debug('[notificationsChannel] notification', object.position ?? 'top-center', object);
    toast.custom(getToast(object), { position: object.position ?? 'top-center', duration: object.duration ?? 5000 });
  });

  return <>
    <Toaster>
      {(t) => (
        <div style={{ opacity: t.visible ? 1 : 0 }}>
          {resolveValue(t.message, t)}
        </div>
      )}
    </Toaster>
    {alertTitle && alertMessage && (
      <CalciteAlert icon='spinner' kind='brand' open={true} autoClose={true} autoCloseDuration='fast'>
        <div slot="title">{alertTitle}</div>
        <div slot="message">{alertMessage}</div>
      </CalciteAlert>)}
  </>;
};

export default Notifications;

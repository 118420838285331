import { CalciteAction, CalcitePanel } from '@esri/calcite-components-react';
import { useEffect, useMemo, useState } from 'react';
import pluralize from 'pluralize';
import { useCountdown } from 'usehooks-ts';
import { useQueryClient } from '@tanstack/react-query';
import InboundMetrics from './InboundMetrics';
import OutboundMetrics from './OutboundMetrics';
import ReportValuesDialog from './ReportValuesDialog';
import { setUpdateChartClient } from '../../../../stores/clients';
import { queries } from '../../../../queries/queries';

const Charts = ({ env, client }) => {
    const queryClient = useQueryClient();

    const [count, { startCountdown, stopCountdown, resetCountdown }] = useCountdown({
      countStart: 60,
    });

    const [reportValues, setReportValues] = useState({
        clientId: client?.clientId, back: '7', type: 'day', interval: 'hour',
    });

    const text = useMemo(() => `${reportValues.back} ${pluralize(reportValues.type, reportValues.back)} back, 1 ${reportValues.interval} interval`, [reportValues]);

    const [showDialog, setShowDialog] = useState(false);

    const clearClientChartFilter = () => {
        setUpdateChartClient(null);
    };

    const refreshChart = () => {
        queryClient.invalidateQueries(queries.elsCharts.inbound(env, reportValues?.back, reportValues?.type, reportValues?.interval));
        queryClient.invalidateQueries(queries.elsCharts.outbound(env, reportValues?.clientId, reportValues?.back, reportValues?.type, reportValues?.interval));

        resetCountdown();
        startCountdown();
    };

    useEffect(() => {
        startCountdown();

        return () => {
            stopCountdown();
        };
    }, []);

    useEffect(() => {
        if (count === 0) {
            refreshChart();
        }
    }, [count, reportValues]);

    return (
        <>
        <CalcitePanel heading={'ELS Traffic Charts'}>
            {client && (
                <CalciteAction
                    text={`Clear ${client.name} filter`}
                    slot="header-actions-end"
                    icon="reset"
                    textEnabled={true}
                    onClick={clearClientChartFilter}
                />
            )}
            <CalciteAction
                slot="header-actions-end"
                icon="refresh"
                text={`Refreshing in ${count} ${pluralize('second', count)}...`}
                textEnabled={true}
                onClick={refreshChart}
                />
            <CalciteAction
                slot="header-actions-end"
                icon="time-filter"
                text={text}
                textEnabled={true}
                onClick={() => setShowDialog(true)}
            />
            {!client && (
                <InboundMetrics env={env} reportValues={reportValues} />
            )}
            <OutboundMetrics env={env} client={client} reportValues={reportValues} />
        </CalcitePanel>
        {showDialog && (
            <ReportValuesDialog
                reportValues={reportValues}
                setReportValues={setReportValues}
                setShowDialog={setShowDialog}
            />)}
        </>
    );
};

export default Charts;

import { CalciteButton, CalciteDialog } from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect } from 'react';
import ControlledInputText from '../UI/ControlledInputText';
import ControlledSwitch from '../UI/ControlledSwitch';
import { useUpdateClient } from '../../../../queries/els/queries';
import { setUpdateClientDialogState, useGetClients } from '../../../../stores/clients';
import { useGetEnvironment } from '../../../../stores/environment';

const validationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .test('Name-test', 'Name needs to be more than 5 characters', (value) => value.trim().length > 5),
    hashKey: Yup.string()
        .required('Hash Key is required.')
        .test('Hash-test', 'Hash Key needs to be more than 5 characters', (value) => value.trim().length > 5),
});

const UpdateClient = () => {
    const env = useGetEnvironment();

    const updateClient = useUpdateClient();

    const { client, showUpdateClient } = useGetClients();

    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: client,
    });

    useEffect(() => {
        if (!client || !showUpdateClient) return;
        setValue('name', client.name);
        setValue('hashKey', client.hashKey);
        setValue('enabled', client.enabled);
    }, [setValue, client, showUpdateClient]);

    const saveClient = async () => {
        const result = await trigger();
        if (!result) return;

        const { name, hashKey, enabled } = getValues();

        updateClient({
            env, clientId: client.clientId, name, hashKey, enabled,
        });
        setUpdateClientDialogState(null);
    };

    const onCalciteDialogClose = (e) => {
        e?.preventDefault();
        setUpdateClientDialogState(null);
    };

    return (
        <CalciteDialog
            slot="dialogs"
            open={showUpdateClient || undefined}
            modal={true}
            closeDisabled={true}
            onCalciteDialogClose={onCalciteDialogClose}
            heading={'Update Client'}
            description={client?.name}
        >
            <ControlledInputText
                name="name"
                control={control}
                required={true}
                label="Client Name"
                placeholder="Client Name"
            />
             <ControlledInputText
                name="hashKey"
                control={control}
                required={true}
                label="Hash Key"
                placeholder="Hash Key"
            />
            <ControlledSwitch
                name="enabled"
                control={control}
                label="Enabled"
            />
            <CalciteButton slot="footer-start" kind="neutral" onClick={onCalciteDialogClose}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={saveClient}>
                Update Client
            </CalciteButton>
        </CalciteDialog >
    );
};

export default UpdateClient;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '../../services/webhelpers';

export const floorPickerQueryKeys = createQueryKeys('floorPicker', {
    plans: (extent) => ({
        queryKey: [extent],
        queryFn: () => get('https://floorpicker.rpdy.us/api/query', extent),
    }),

});

import { createQueryKeys } from '@lukemorales/query-key-factory';
import dayjs from 'dayjs';
import { post } from '../../services/webhelpers';

export const mobileLocationsQueryKeys = createQueryKeys('mobileLocations', {
    locations: (service, userId, start, end) => ({
        queryKey: [service, userId, start, end],
        queryFn: () => post('/api/mobiletracking/locations/user', {
            service, userId, start: dayjs(start).startOf('day').format('YYYY-MM-DD HH:mm:ss'), end: dayjs(end).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        }),
    }),
});

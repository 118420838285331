import {
    CalciteAction, CalciteFlow, CalciteFlowItem, CalciteList, CalciteListItem,
    CalciteListItemGroup, CalcitePanel, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import { useState, useEffect } from 'react';
import pluralize from 'pluralize';
import dayjs from '../../services/dayjs';

import { setPageTitle } from '../../services/pagescripts';
import { GetInstance, showToast } from '../../services/helperscripts';
import { InstanceNames } from '../../instances/names';
import { useBin, useBins } from '../../queries/restdebugger/queries';
import EncodedTable from './EncodedTable';
import HeaderTable from './HeaderTable';
import BodyTable from './BodyTable';

const messagingInstance = await GetInstance(InstanceNames.Messaging);

const RestDebugger = () => {
    useEffect(() => {
    setPageTitle('Rest Debugger');
    }, []);

    const [showHeaders, setShowHeaders] = useState(false);
    const [bin, setBin] = useState(null);

    const { binsLoading, bins } = useBins();
    const { details, detailsLoading } = useBin(bin?.binId);

    const processNewMessage = async ({ binId, eventTime }) => {
        showToast({
            type: 'info',
            title: binId,
            message: `New event received ${dayjs(eventTime).fromNow()}`,
            duration: 8000,
            position: 'top-right',
        });
        if (binId !== bin?.binId) { return; }
        setBin({ binId, timeStamp: eventTime });
    };

    useEffect(() => {
        if (binsLoading) { return; }
        setBin(bins[0]);
    }, [binsLoading, bins]);

    useEffect(() => {
        if (bin == null) {
            return () => {
                messagingInstance?.unsubscribeFromMqttWithoutChecks('restdebugger/#');
            };
        }
        const { binId, timeStamp } = bin;

        setPageTitle(
            `Rest Debugger - ${binId}`,
            `Rest Debugger | ${binId} - last updated ${dayjs(timeStamp).fromNow()}`,
            [`https://consumer-testing.rapiddeploy.com/${binId}`],
        );

        messagingInstance.subscribeToMqtt('restdebugger/#', processNewMessage);

        return () => {
            messagingInstance?.unsubscribeFromMqttWithoutChecks('restdebugger/#');
        };
    }, [bin]);

    const onBinSelected = ({ target: { value: { binId, timeStamp } } }) => setBin({ binId, timeStamp });
    const onShowHeadersSelected = () => setShowHeaders(!showHeaders);

    const ShowUrlEncodedTable = ({ headers, body }) => headers['Content-Type'] && headers['Content-Type'].indexOf('application/x-www-form-urlencoded') > -1 && (
        <EncodedTable body={body} />
    );

    return (
        <CalciteShell>
            <CalciteShellPanel slot='panel-start' widthScale='m' resizable={true} loading={binsLoading || undefined}>
                <CalciteFlow>
                    {!binsLoading && (
                        <CalciteFlowItem heading='Bins' description={`${bins.length} ${pluralize('bin', bins.length)}`}>
                            <CalciteList selectionMode='single' selectionAppearance='border'>
                                {bins.map(({
                                    encodedBinId, binId, timeStamp, count,
                                }) => (<CalciteListItem key={encodedBinId} selected={binId === bin?.binId} label={binId} description={Number(count).toLocaleString()} value={({ encodedBinId, binId, timeStamp })} onCalciteListItemSelect={onBinSelected}></CalciteListItem>))}
                            </CalciteList>
                        </CalciteFlowItem>
                    )}
                </CalciteFlow>
            </CalciteShellPanel>
            <CalcitePanel
                heading={bin?.binId}
                description={`${details?.length ?? 0} ${pluralize('event', details?.length ?? 0)}`}
                loading={detailsLoading}
            >
                <CalciteAction
                    slot="header-actions-end"
                    icon={showHeaders ? 'toggle-on' : 'toggle-off'}
                    text={`${showHeaders ? 'Hide' : 'Show'} Headers`}
                    textEnabled={true}
                    onClick={onShowHeadersSelected} />
                <CalciteList>
                    {!detailsLoading && details?.map(({
                        token, method, path, timestamp, sourceIp, headers, body,
                    }, index) => (
                        <CalciteListItemGroup key={index} heading={`${method} ${path}`}>
                            <CalciteListItem label='Timestamp (UTC)' description={timestamp}>
                                <CalciteAction slot="actions-start" icon="clock" />
                            </CalciteListItem>
                            <CalciteListItem label='Source Address' description={sourceIp}>
                            <CalciteAction slot="actions-start" icon="all-servers" />
                            </CalciteListItem>
                            {token && (<CalciteListItem label='Token Details' description={JSON.stringify(token, null, '\t')} />)}
                            {showHeaders && (<HeaderTable headers={headers} />)}
                            <BodyTable headers={headers} body={body} />

                        </CalciteListItemGroup>
                    ))}
                </CalciteList>
            </CalcitePanel>
        </CalciteShell>
    );
};

export default RestDebugger;

import { useState } from 'react';
import { CalciteMenu, CalciteMenuItem, CalciteNavigation } from '@esri/calcite-components-react';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { pageChannel } from '../../instances/channels';
import { useMenuItems } from '../../queries/console/queries';

const Menu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { authenticatedMenuItemsLoading, authenticatedMenuItems } = useMenuItems([]);

  usePostalEvent(pageChannel, 'menubar', ({ extraMenuItems }) => {
    setMenuItems([...extraMenuItems, ...authenticatedMenuItems]);
  }, [authenticatedMenuItems]);

  const onCalciteMenuItemSelect = () => {
    [...document.getElementsByTagName('calcite-menu-item')].forEach((menuItem) => {
      menuItem.open = false;
    });
  };

  const listItem = (text, url, slot) => (slot
  ? (<CalciteMenuItem onCalciteMenuItemSelect={onCalciteMenuItemSelect} key={text} slot={slot} text={text} href={url} />)
  : (<CalciteMenuItem key={text} text={text} href={url} />));

  const subMenu = (title, children, slot) => (slot
  ? (
      <CalciteMenuItem key={title} slot={slot} text={title}>
        {children.map(({ children: subMenuChildren, url, text }) => (subMenuChildren ? subMenu(text, subMenuChildren, 'submenu-item') : listItem(text, url, 'submenu-item')))}
      </CalciteMenuItem>
    )
  : (
      <CalciteMenuItem key={title} text={title}>
        {children.map(({ children: subMenuChildren, url, text }) => (subMenuChildren ? subMenu(text, subMenuChildren, 'submenu-item') : listItem(text, url, 'submenu-item')))}
      </CalciteMenuItem>
    )
  );

  return (
    <CalciteNavigation slot="navigation-secondary">
      <CalciteMenu slot="content-start">
        {menuItems.map(({ children, url, text }) => (children ? subMenu(text, children) : listItem(text, url)))}
      </CalciteMenu>
    </CalciteNavigation>
  );
};

export default Menu;

import { useMemo } from 'react';
import { useOutboundMetrics } from '../../../../queries/els/queries';
import { chartOptionsDefaults } from './chatDefaults';
import Chart from './Chart';

const OutboundMetrics = ({ env, client, reportValues }) => {
    const { outboundMetrics, outboundMetricsLoading } = useOutboundMetrics(env, client?.clientId, reportValues?.back, reportValues?.type, reportValues?.interval);

    const volumeOptions = useMemo(() => ({
        ...chartOptionsDefaults,
        series: !outboundMetrics ? [] : Object.entries(Object.groupBy(outboundMetrics, ({ statusCode }) => statusCode)).map(([statusCode, metrics]) => ({
            name: statusCode,
            data: metrics.map(({ sentTimeStamp, count }) => [new Date(sentTimeStamp).getTime(), count]).sort((a, b) => a[0] - b[0]),
        })),
    }), [outboundMetrics]);

    const processingTimeOptions = useMemo(() => ({
        ...chartOptionsDefaults,
        yAxis: {
            gridLineWidth: 1,
            title: {
                text: null, // y-axis title
            },
            labels: {
                formatter() {
                    return `${this.axis.defaultLabelFormatter.call(this)} ms`;
                },
            },
        },
        legend: {
            enabled: false,
        },
        series: [{
            type: 'line',
            name: 'Avg. Processing Time',
            data: outboundMetrics?.map(({ sentTimeStamp, avgProcessingTime }) => [new Date(sentTimeStamp).getTime(), avgProcessingTime]).sort((a, b) => a[0] - b[0]) || [],
        }],
    }), [outboundMetrics]);

    return (
        <>
            <Chart heading={`${client?.name || ''} Outbound Volumes, by Status Code`.trim()} loading={outboundMetricsLoading} options={volumeOptions} />
            <Chart heading={`${client?.name || ''} Average Processing Time`.trim()} loading={outboundMetricsLoading} options={processingTimeOptions} />
        </>
    );
};

export default OutboundMetrics;

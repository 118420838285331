import {
  CalciteAction, CalciteActionBar, CalciteLoader, CalcitePanel, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import { useState, useEffect, useMemo } from 'react';
import {
  ArcgisMap, ArcgisExpand, ArcgisLayerList, ArcgisBasemapGallery,
} from '@arcgis/map-components-react';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import { v4 as uuidv4 } from 'uuid';
import { copyToClipboard } from '../../services/helperscripts';
import { getColorRenderer, listItemCreatedFunction } from '../../services/maphelpers';
import ReversePanel from './ReversePanel';
import { useForwardGeocodedAddress, useReverseGeocodedAddress } from '../../queries/directions/queries';
import ForwardPanel from './ForwardPanel';
import ConsoleJsonEditor from '../consolejsoneditor';
import { setPageTitle } from '../../services/pagescripts';

const Geocoders = () => {
  useEffect(() => {
    setPageTitle('Geocoders');
  }, []);

  const tabs = {
    reverse: 'Reverse',
    forward: 'Forward',
    google: 'Google',
    geocodio: 'Geocodio',
    azure: 'Azure',
  };

  const [activeTab, setActiveTab] = useState(tabs.reverse);
  const [activeResultsTab, setActiveResultsTab] = useState(tabs.google);

  const [map, setMap] = useState(null);
  const [reverseValues, setReverseValues] = useState({});
  const [forwardValues, setForwardValues] = useState({});

  const { reverseAddressLoading, reverseAddress } = useReverseGeocodedAddress(reverseValues);
  const { forwardAddressLoading, forwardAddress } = useForwardGeocodedAddress(forwardValues);

  const content = useMemo(() => (activeTab === tabs.reverse ? reverseAddress : forwardAddress), [activeTab, reverseAddress, forwardAddress]);
  const isLoading = useMemo(() => (activeTab === tabs.reverse ? reverseAddressLoading : forwardAddressLoading), [activeTab, reverseAddressLoading, forwardAddressLoading]);

  const setActiveTabState = ({ target: { text } }) => setActiveTab(text);
  const setActiveResultsState = ({ target: { text } }) => setActiveResultsTab(text);

  const onContentSelected = ({ target: { id: text } }) => copyToClipboard(content[text], `${text} Content Copied to Clipboard`);
  const onArcgisViewReadyChange = ({ target: { map: mapTarget } }) => setMap(mapTarget);

  const [onArcgisViewImmediateClick, setOnArcgisViewImmediateClick] = useState(null);

  const parseAzurePosition = (position) => {
    if (typeof position === 'string' || position instanceof String) {
      const [_lat, _lng] = position.split(',');

      return new Point({
        x: parseFloat(_lng),
        y: parseFloat(_lat),
        spatialReference: { wkid: 4326 },
      });
    }

    return new Point({
      x: position.lon,
      y: position.lat,
      spatialReference: { wkid: 4326 },
    });
  };

  const createLayer = (source, title, color, style) => new FeatureLayer({
    title,
    source,
    renderer: getColorRenderer(color, style, title),
    objectIdField: 'uniqueId',
    fields: [
      { name: 'uniqueId', alias: 'uniqueId', type: 'string' },
      { name: 'index', alias: 'index', type: 'string' },
      { name: 'address', alias: 'address', type: 'string' },
      { name: 'type', alias: 'type', type: 'string' },
    ],
    popupTemplate: {
      title,
      content: [
        {
          type: 'fields',
          fieldInfos: [
            {
              fieldName: 'type',
              label: 'Type',
            },
            {
              fieldName: 'address',
              label: 'Address',
            },
            {
              fieldName: 'index',
              label: 'Index',
            },
          ],
        },
      ],
    },
  });

  useEffect(() => {
    if (!content || !map) return;

    map.removeAll();

    if (content.Google) {
      const graphics = JSON.parse(content.Google)
        .results.map(
          ({ formatted_address: address, geometry: { location: { lng: x, lat: y } } }, index) => new Graphic({
            attributes: {
              type: 'Google',
              index: index + 1,
              uniqueId: uuidv4(),
              address,
            },
            geometry: new Point({ x, y, spatialReference: { wkid: 4326 } }),
          }),
        );

      map.add(createLayer(graphics, 'Google', 'red', 'circle'));
    }
    if (content.Geocodio) {
      const graphics = JSON.parse(content.Geocodio).results
        .map(
          ({ formatted_address: address, location: { lng: x, lat: y } }, index) => new Graphic({
            attributes: {
              type: 'Geocodio',
              index: index + 1,
              uniqueId: uuidv4(),
              address,
            },
            geometry: new Point({ x, y, spatialReference: { wkid: 4326 } }),
          }),
        );

      map.add(createLayer(graphics, 'Geocodio', 'green', 'diamond'));
    }
    if (content.Azure) {
      const azure = JSON.parse(content.Azure);
      const graphics = (azure.addresses ?? azure.results ?? azure)
        .map(
          ({ Position, Address: { FreeformAddress: address } }, index) => new Graphic({
            attributes: {
              type: 'Azure',
              index: index + 1,
              uniqueId: uuidv4(),
              address,
            },
            geometry: parseAzurePosition(Position),
          }),
        );

      map.add(createLayer(graphics, 'Azure', 'blue', 'square'));
    }
  }, [map, content]);

  return (
    <CalciteShell>
      <CalciteShellPanel slot="panel-start">
        <CalciteActionBar slot="action-bar" expanded={true} expandDisabled={true}>
          <CalciteAction text="Reverse" onClick={setActiveTabState} active={activeTab === tabs.reverse || undefined} />
          <CalciteAction text="Forward" onClick={setActiveTabState} active={activeTab === tabs.forward || undefined} />
        </CalciteActionBar>
        {activeTab === tabs.reverse && (<ReversePanel onArcgisViewImmediateClick={onArcgisViewImmediateClick} setValues={setReverseValues} />)}
        {activeTab === tabs.forward && (<ForwardPanel setValues={setForwardValues} />)}
      </CalciteShellPanel>
      {(isLoading || content) && (
        <CalcitePanel slot="panel-bottom">
          {isLoading && <CalciteLoader text='Loading' />}
          {!isLoading && content && (
            <CalciteShell>
              <CalciteShellPanel slot="panel-end">
                <CalciteActionBar expanded={true} expandDisabled={true} scale='s'>
                  <CalciteAction text={tabs.google} onClick={setActiveResultsState} active={activeResultsTab === tabs.google || undefined} />
                  <CalciteAction text={tabs.geocodio} onClick={setActiveResultsState} active={activeResultsTab === tabs.geocodio || undefined} />
                  <CalciteAction text={tabs.azure} onClick={setActiveResultsState} active={activeResultsTab === tabs.azure || undefined} />
                </CalciteActionBar>
              </CalciteShellPanel>
              <div>
                {activeResultsTab === tabs.google && (<ConsoleJsonEditor data={content?.Google && JSON.parse(content.Google)} restrictAdd={true} restrictEdit={true} restrictDelete={true}/>)}
                {activeResultsTab === tabs.geocodio && (<ConsoleJsonEditor data={content?.Geocodio && JSON.parse(content.Geocodio)} restrictAdd={true} restrictEdit={true} restrictDelete={true}/>)}
                {activeResultsTab === tabs.azure && (<ConsoleJsonEditor data={content?.Azure && JSON.parse(content.Azure)} restrictAdd={true} restrictEdit={true} restrictDelete={true}/>)}
              </div>
            </CalciteShell>)}
        </CalcitePanel>
      )}
      <CalcitePanel>
        <div style={{ height: '100%', width: '100%' }}>
          <ArcgisMap
            basemap='topo-vector'
            zoom={5}
            center={[-100.020353, 35.746512]}
            onArcgisViewImmediateClick={setOnArcgisViewImmediateClick}
            onArcgisViewReadyChange={onArcgisViewReadyChange}>
            <ArcgisExpand position="top-right">
              <ArcgisLayerList listItemCreatedFunction={listItemCreatedFunction} />
            </ArcgisExpand>
            <ArcgisExpand position="bottom-left">
              <ArcgisBasemapGallery />
            </ArcgisExpand>
          </ArcgisMap>
        </div>
      </CalcitePanel>
    </CalciteShell>
  );
};

export default Geocoders;

import { CalciteButton, CalciteDialog } from '@esri/calcite-components-react';
import { clearConfirmationDialog, useConfirmationDialog } from '../../../../stores/dialogs';

const ConfirmationDialog = () => {
    const dialog = useConfirmationDialog();

    const onCalciteDialogClose = () => {
        clearConfirmationDialog();
    };

    return dialog && (
        <CalciteDialog
            slot="dialogs"
            open={true}
            modal={true}
            closeDisabled={true}
            onCalciteDialogClose={onCalciteDialogClose}
            heading={dialog?.heading}
        >
            <p>
                {dialog.message}
            </p>
            <CalciteButton slot="footer-start" kind="neutral" onClick={onCalciteDialogClose}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={dialog.confirmAction}>
                {dialog.confirmText}
            </CalciteButton>
        </CalciteDialog >
    );
};

export default ConfirmationDialog;

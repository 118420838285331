/* eslint-disable react/prop-types */
import {
    CalciteInput, CalciteInputMessage, CalciteInputText, CalciteLabel,
} from '@esri/calcite-components-react';
import { Controller } from 'react-hook-form';
import RequiredIcon from './RequiredIcon';

const ControlledInputText = (props) => (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field, fieldState }) => (
            <CalciteLabel>
                <span>
                    {props.label}
                    {props.required && (<RequiredIcon />)}
                </span>
                {props.type
                    ? (<CalciteInput
                        autocomplete="off"
                        icon={props.icon}
                        placeholder={props.placeholder}
                        disabled={props.disabled || undefined}
                        type={props.type}
                        status={fieldState.error ? 'invalid' : undefined}
                        {...field}
                        onCalciteInputInput={(e) => {
                            field.onChange(e);
                            field.onBlur(e);
                        }}
                    />)
                    : (<CalciteInputText
                        autocomplete="off"
                        icon={props.icon}
                        placeholder={props.placeholder}
                        disabled={props.disabled || undefined}
                        status={fieldState.error ? 'invalid' : undefined}
                        {...field}
                        onCalciteInputTextInput={(e) => {
                            field.onChange(e);
                            field.onBlur(e);
                        }}
                    />)}

                {fieldState.error && (
                    <CalciteInputMessage status="invalid" icon="exclamation-mark-triangle-f">{fieldState.error.message}</CalciteInputMessage>
                )}
            </CalciteLabel>
        )}
    />
);

export default ControlledInputText;

/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import { createRoot } from 'react-dom/client';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { init as initFullStory, FullStory } from '@fullstory/browser';
import esriConfig from '@arcgis/core/config';
import Portal from '@arcgis/core/portal/Portal';
import pace from 'pace-js';
import Swal from 'sweetalert2';
import { LicenseManager } from 'ag-grid-enterprise';
import { defineCustomElements as defineCalciteElements } from '@esri/calcite-components/dist/loader';
import { defineCustomElements as defineMapElements } from '@arcgis/map-components/dist/loader';
import Router from './router';
import '../css/overrides.css';
import App from './controls/app';
import dayjs from './services/dayjs';
import {
  setupAuth0Client, handleAuth0Callback, getAccessToken, logout,
} from './services/authentication';
import {
  DynamicImport,
  DynamicImportWithInitialize,
  GetInstance,
  getRapidDeployCustomer,
  showToast,
} from './services/helperscripts';
import { get, baseURL } from './services/webhelpers';
import {
  initializeReactControl,
  resetBodyContainerFromFullScreen,
  setFullBodyContainerFromHtml,
  setMenuBar,
  setupPageFromContainerType,
  showProgressIndicator,
} from './services/pagescripts';

import { pageChannel, userPresenceChannel } from './instances/channels';

import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-balham.css';
import '@esri/calcite-components/dist/calcite/calcite.css';

import { InstanceNames } from './instances/names';
import CallHandling from './controls/callhandling';
import LoggingConfiguration from './controls/loggingconfiguration';
import Geocoders from './controls/geocoders';
import RestDebugger from './controls/restdebugger';
import PowerBi from './controls/powerbi';
import InvalidLogs from './controls/invalidlogs';
import UtilizationReporting from './controls/utilizationreporting';
import GoogleEls from './controls/googleels';
import NormalizedCallTakerRecordStream from './controls/nctr';
import NormalizedCallRecordStream from './controls/ncr';
import EDGAlerts from './controls/edgalerts';

const pushMessagingInstance = await DynamicImport('services/PushMessaging');
const messagingInstance = await GetInstance(InstanceNames.Messaging);
const userPresenceInstance = await GetInstance(InstanceNames.UserPresence);
const { dbCacheGetUri } = await GetInstance(InstanceNames.Cache);
const {
  setState, deleteState, initialize: initializeDatabase,
} = await GetInstance(InstanceNames.Database);

class ConsoleRouter {
  constructor() {
    LicenseManager.setLicenseKey(process.env.AGGRID_LICENSEKEY);
    pace.options = {
      ajax: {
        ignoreURLs: ['__browserLink', 'browserLinkSignalR'],
        trackWebSockets: false,
      },
    };
  }

  checkBuildStatus = async () => {
    const {
      buildNumber: completedBuildNumber, triggerInfo: { message: completedMessage },
    } = await get('/reports/ado/buildstatus/Website');
    if (this.runningBuildNumber && this.runningBuildNumber !== completedBuildNumber) {
      clearInterval(this.buildStatusPollingProcessor);

      showToast({
        title: 'Admin Console Updated!',
        message: `${completedBuildNumber} - ${completedMessage}`,
        link: 'javascript:window.location.reload()',
        linkText: 'Refresh',
        linkIcon: 'refresh',
        target: '_self',
        slot: 'actions-end',
        position: 'top-right',
        duration: 30000,
      });
    }

    this.runningBuildNumber = completedBuildNumber;

    await setState('build-number', completedBuildNumber);

    pageChannel.publish('setCompletedBuildDetails', { buildNumber: completedBuildNumber, message: completedMessage });

    const response = await get('/reports/ado/buildstatus/Website/filter/inProgress');
    if (!response) {
      pageChannel.publish('setInProgressBuildDetails', { id: null, buildNumber: null, message: null });

      return;
    }
    const {
      id, buildNumber, triggerInfo: { message },
    } = response;
    pageChannel.publish('setInProgressBuildDetails', { id, buildNumber, message });
  };

  setupBuildStatusPolling = async () => {
    this.buildStatusPollingProcessor = setInterval(async () => { await this.checkBuildStatus(); }, 60000);
    await this.checkBuildStatus();
  };

  setupEsriConfig = async () => {
    console.log('[ESRI Config] Initializing...');

    esriConfig.apiKey = process.env.ARCGIS_ADMINCONSOLE_APIKEY;
    esriConfig.portalUrl = process.env.ARCGIS_PORTALURL;

    defineCalciteElements(window, { resourcesUrl: 'https://js.arcgis.com/calcite-components/2.12.1/assets' });
    defineMapElements(window, { resourcesUrl: 'https://js.arcgis.com/map-components/4.30/assets' });

    const portal = new Portal();
    await portal.load();

    esriConfig.request.interceptors.push({
      urls: process.env.API_SERVER_URL,
      before: async (params) => {
        const token = await getAccessToken();
        params.requestOptions.headers = {
          Authorization: `Bearer ${token}`,
        };
      },
    });
    esriConfig.request.interceptors.push({
      urls: await baseURL(),
      before: async (params) => {
        const token = await getAccessToken();
        params.requestOptions.headers = {
          Authorization: `Bearer ${token}`,
        };
      },
    });
  };

  warmupDatabaseCache = async () => {
    console.log('[Cache Warmup] Initializing...');

    const labels = [];

    const runBackgroundTask = async (label, task) => {
      labels.push(label);
      this.showLoadingInformation('Initializing Local Database cache...');
      await task();
      labels.splice(labels.indexOf(label), 1);
      this.showLoadingInformation('Initializing Local Database cache...');
    };

    await initializeDatabase();

    try {
      const tasks = [
        runBackgroundTask('Production Geofences', dbCacheGetUri('/rc/geofences/production')),
        runBackgroundTask('Device AgentIds', dbCacheGetUri('/devices/agentids')),
        runBackgroundTask('Academy Enrollments', dbCacheGetUri('/api/academyenrollments/users')),
        runBackgroundTask('Pendo User Features', dbCacheGetUri('/api/pendofeatures/users')),
        runBackgroundTask('Pendo Features', dbCacheGetUri('/reports/pendo/features')),
        runBackgroundTask('Customer Tenants', dbCacheGetUri('/api/customertenants', dayjs().add(1, 'hour').toDate())),
        runBackgroundTask('Auth0 Event Codes', dbCacheGetUri('/api/auth0/logs/event-codes')),
        runBackgroundTask('ECC Device Mappings', dbCacheGetUri('/tenant/devices', dayjs().add(1, 'hour').toDate())),
        runBackgroundTask('Device Throughput', dbCacheGetUri('/devices/throughput/ali/1h', dayjs().add(1, 'hour').toDate())),
      ];

      await Promise.all(tasks);
    } catch (error) { /* */ }
  };

  setupWebAnalyticsAndConfig = async () => {
    if (window.location.host === process.env.LOCAL_ADDRESS) {
      console.log('[Web Analytics and Config] Runnning locally...');
      return;
    }
    console.log('[Web Analytics and Config] Initializing...');
    this.showLoadingInformation('Initializing Web Analytics and Configuration...');
    const {
      userIdentifier, emailAddress, firstName, lastName,
    } = await get('/account/mydetails');

    try {
      const orgId = process.env.FULLSTORY_ORGID;
      const host = 'fullstory-relay.rpdy.us';
      const script = 'fullstory-script.rpdy.us/s/fs.js';

      initFullStory({ orgId, host, script }, ({ sessionUrl }) => {
        this.fullStorySessionUrl = sessionUrl;
        console.log(`[Web Analytics and Config] FullStory session started, ${sessionUrl}`);
      });

      FullStory('setProperties', {
        type: 'user',
        properties: {
          displayName: `${firstName} ${lastName}`,
          email: emailAddress,
          tenant_str: 'RapidDeploy Console',
        },
      });
    } catch (e) {
      console.log('[Web Analytics and Config] Error setting up FullStory', e);
    }

    try {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: process.env.APPINSIGHTS_CONNECTIONSTRING,
          appUserId: userIdentifier,
          accountId: emailAddress,
        },
      });
      appInsights.loadAppInsights();
      appInsights.setAuthenticatedUserContext(userIdentifier);
      appInsights.addTelemetryInitializer((envelope) => {
        if (envelope.data) {
          envelope.data.Name = `${firstName} ${lastName}`;
          envelope.data.Email = emailAddress;
        }
      });
    } catch (e) {
      console.log('[Web Analytics and Config] Error setting up AppInsights', e);
    }
  };

  initializeRouter = async () => {
    console.log('[initializeRouter]');

    const router = new Router({
      mode: 'hash',
      hooks: {
        before() { setupPageFromContainerType(this.getPage()?.options?.containerType); },
      },
      page404: (path) => { console.log('router:404', path); },
    });

    const routeMap = [
      { rule: '/', handler: () => router.navigateTo('/#/usg2') },
      { rule: 'logout', handler: () => logout() },
      { rule: 'loggingconfiguration', control: <LoggingConfiguration /> },
      { rule: 'callhandling', control: <CallHandling /> },
      { rule: 'geocoders', control: <Geocoders /> },
      { rule: 'restdebugger', control: <RestDebugger /> },
      { rule: 'powerbi-reports', control: <PowerBi /> },
      { rule: 'edg/invalid-logs', control: <InvalidLogs /> },
      { rule: 'utilization-reports', control: <UtilizationReporting /> },
      { rule: 'google-els/{env}', control: <GoogleEls router={router} /> },
      { rule: 'edg/nctr/{alertType}', control: <NormalizedCallTakerRecordStream router={router} /> },
      { rule: 'edg/ncr/{alertType}', control: <NormalizedCallRecordStream router={router} /> },
      { rule: 'edg/alerts/{alertType}', control: <EDGAlerts router={router} /> },
      { rule: 'map/live', component: 'ConsoleMap', fullHeight: true },
      { rule: 'onlineusers', component: 'OnlineUsers' },
      { rule: 'map/coverage', component: 'CoverageMap' },
      { rule: 'edg/devices', component: 'Devices' },
      { rule: 'edg/provision', component: 'Provision' },
      { rule: 'edg/policies', component: 'Policies' },
      { rule: 'sales-reports/nena', component: 'Nena' },
      { rule: 'sales-reports/hubspot', component: 'HubSpotFootprint' },
      { rule: 'sales-reports/hubspot/state', component: 'HubSpotFootprintByState' },
      { rule: 'rc/geofences/all', component: 'Geofences' },
      { rule: 'rc/geofences/verify', component: 'GeofencesToVerify' },
      { rule: 'rc/geofences/promote', component: 'PromoteGeofences' },
      { rule: 'rc/capitalgeofences/{env}', component: 'CapitalGeofences', afterComponentInitialized: (instance, [env]) => instance.loadSummary(env) },
      { rule: 'rc/geofences/staging', component: 'StagingGeofences' },
      { rule: 'rc/geofences/tenants', component: 'GeofenceTenants' },
      { rule: 'rc/geofences/editor', component: 'GeofenceEditor' },
      { rule: 'rc/geofences/google', component: 'GeofenceGoogle', fullHeight: true },
      { rule: 'rc/geofences/upload', component: 'GeofencesUpload', fullHeight: true },
      { rule: '{env}/{tenantId}/manage', component: 'MonolithTenant', afterComponentInitialized: (instance, [env, tenantId]) => instance.loadTenant(env, tenantId) },
      { rule: '{env}/comms', component: 'Comms', afterComponentInitialized: (instance, [env]) => instance.loadEnvironment(env) },
      { rule: '{env}/radius-login-events/{email}', component: 'RadiusLoginEvents', afterComponentInitialized: (instance, [env, email]) => instance.loadEvents(env, email) },
      { rule: '{env}/{tenantId}/monolith-login-events/{email}', component: 'MonolithLoginEvents', afterComponentInitialized: (instance, [env, tenantId, email]) => instance.loadEvents(env, tenantId, email) },
      {
        rule: 'device-stream/{iotHubId}/{deviceId}', fullScreen: true, handler: ([iotHubId, deviceId]) => pageChannel.publish('startDeviceStream', { iotHubId, deviceId }),
      },
      { rule: '{env}', component: 'Dashboard', afterComponentInitialized: (instance, [env]) => instance.setEnvironment(env).then(() => instance.resetView()) },
    ];

    routeMap.forEach(({
      rule, handler, component, fullHeight, fullScreen, afterComponentInitialized, control,
    }) => {
      router.add(
        rule,
        async () => {
          if (control) {
            setupPageFromContainerType('full-height');
            setMenuBar();
            await setFullBodyContainerFromHtml('fullheight');
            this.control = initializeReactControl(control);
            return;
          }

          if (fullHeight) {
            setupPageFromContainerType('full-height');
          } else if (fullScreen) {
            setupPageFromContainerType('full-screen');
          }

          if (component) {
            this.activeComponent = await DynamicImportWithInitialize(component, async (instance) => {
              if (!afterComponentInitialized) { return; }
              await afterComponentInitialized(instance, router.currentPage.params);
            });
            return;
          }
          handler(router.currentPage.params);
        },
        {
          fullScreen: fullScreen ?? false,
          containerType: fullScreen
            ? 'full-screen'
            : fullHeight
              ? 'full-height'
              : 'regular',
          unloadCb: async () => {
            if (component) { await this.activeComponent.shutdown(); }
            if (control) {
              this.control?.unmount();
            }
            if (fullHeight || fullScreen || control) {
              resetBodyContainerFromFullScreen();
            }
          },
        },
      );
    });

    router.addUriListener();

    this.router = router;
  };

  isPageFullScreen = () => this.router.getPage()?.options?.fullScreen ?? false;

  showLoadingInformation = (text, title) => {
    if (this.isPageFullScreen) {
      return;
    }
    showProgressIndicator({ text, title: title ?? 'Loading Admin Console...' });
  };

  initialize = async () => {
    await this.initializeRouter();

    setupPageFromContainerType(this.router.getPage()?.options?.containerType);
    this.showLoadingInformation('Initializing Auth0...');

    await setupAuth0Client();
    await handleAuth0Callback();

    await this.setupEsriConfig();
    await this.setupWebAnalyticsAndConfig();
    await this.warmupDatabaseCache();

    this.showLoadingInformation('Initializing Messaging...');

    const rootContainer = createRoot(document.getElementById('root-container'));
    rootContainer.render(<App />);

    await Promise.all([
      messagingInstance.initialize(),
      pushMessagingInstance.initialize(),
      userPresenceInstance.initialize(),
    ]);

    this.liveCalls = {};
    await messagingInstance.subscribeToMqtt('calls/#', (call) => {
      pageChannel.publish('livecallevent', call);

      const { customerId, id, timeStamp } = call;
      if (customerId in this.liveCalls) {
        this.liveCalls[customerId][id] = timeStamp;
        return;
      }
      this.liveCalls[customerId] = { [id]: timeStamp };
    });

    window.setInterval(() => {
      const startTime = dayjs().subtract(30, 'minutes');
      const filteredCalls = Object.keys(this.liveCalls).flatMap((environment) => ({ environment: getRapidDeployCustomer(environment), count: Object.keys(this.liveCalls[environment]).filter((id) => dayjs(this.liveCalls[environment][id]).isAfter(startTime)).length }));
      pageChannel.publish('livecallsummary', filteredCalls);
    }, 5000);

    await messagingInstance.subscribeToMqtt('userpresence/onlineusersummary', (summary) => {
      userPresenceChannel.publish('onlineusersummary', { summary });
    });

    await messagingInstance.subscribeToMqtt('pendoguides/#', (guideUpdate) => {
      pageChannel.publish('pendoguidesupdated', guideUpdate);
      showToast({
        title: `Pendo Guide Viewed - ${guideUpdate.guideName}`,
        message: `by ${guideUpdate.name} ${guideUpdate.surname} from ${guideUpdate.eccName}`,
      });
    });

    resetBodyContainerFromFullScreen();

    Swal.close();

    this.router.check();

    await this.setupBuildStatusPolling();
  };
}

window.setLocalDebugging = async (port) => {
  await setState('API_SERVER_URL_OVERRRIDE', `https://localhost:${port ?? 63577}`);
  window.location.reload();
};

window.clearLocalDebugging = async () => {
  await deleteState('API_SERVER_URL_OVERRRIDE');
  window.location.reload();
};

const adminConsole = new ConsoleRouter();
await adminConsole.initialize();

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get, post } from '../../services/webhelpers';

export const utilizationQueryKeys = createQueryKeys('utilization', {
    eccs: () => ({
        queryKey: [],
        queryFn: async () => {
            const productionEnvironments = ['ca', 'usg2'];

            const [tenantGroups, customerTenants] = await Promise.all([get('/tenants/groups'), get('/api/customertenants')]);

            const v4groups = tenantGroups
                .filter(({ env }) => productionEnvironments.includes(env))
                .flatMap(({ env: environmentName, tenants }) => tenants.map(({ tenantId: eccId, name }) => ({ environmentName, eccId, name })));

            const v5groups = Object.entries(customerTenants)
                .filter(([environmentName]) => productionEnvironments.includes(environmentName))
                .flatMap(([environmentName, customers]) => customers.flatMap(({ eccs }) => eccs.map(({ eccId, name }) => ({ environmentName, eccId, name }))));

            const v5eccIds = [...new Set(v5groups.map(({ eccId }) => eccId))];

            const filteredV4Groups = v4groups.filter(({ eccId }) => !v5eccIds.includes(eccId));

            return [...filteredV4Groups, ...v5groups];
        },
    }),
    radius: (startTime, endTime) => ({
        queryKey: [`${startTime}-${endTime}`],
        queryFn: () => post('/reports/utilization/radius', { startTime, endTime }),
    }),
    radiusDetail: (date, tenantId) => ({
        queryKey: [`${date}-${tenantId}`],
        queryFn: () => post('/reports/utilization/radius/detail', { date, tenantId }),
    }),
    eclipse: (startTime, endTime) => ({
        queryKey: [`${startTime}-${endTime}`],
        queryFn: () => post('/reports/utilization/eclipse', { startTime, endTime }),
    }),
    eclipseDetail: (date, clientId) => ({
        queryKey: [`${date}-${clientId}`],
        queryFn: () => post('/reports/utilization/eclipse/detail', { date, clientId }),
    }),
    hubspot: () => ({
        queryKey: [],
        queryFn: () => get('/usg2/hubspot'),
    }),
});

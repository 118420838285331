/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import {
  useState, useEffect, useRef, useMemo,
  useCallback,
} from 'react';
import './style.css';
import {
  CalciteAction, CalciteChip, CalciteChipGroup, CalciteIcon, CalciteList, CalciteListItem, CalciteLoader,
  CalcitePanel, CalciteTab, CalciteTabNav, CalciteTabs, CalciteTabTitle,
} from '@esri/calcite-components-react';
import { launchInNewWindow } from '../../services/webhelpers';
import { getManageLink } from '../../services/gridhelpers';
import { orderBy } from '../../services/helperscripts';
import { deviceInfoChannel } from '../../instances/channels';
import {
  useCustomersSearch, useDevicesSearch, useTags, useTenantsSearch, useUsersSearch, useV5UsersSearch,
} from '../../queries/search/queries';

const OverlaySearch = () => {
  const searchInputField = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const [query, setQuery] = useState('');
  const [inProgressQuery, setInProgressQuery] = useState('');

  const { allTagsLoading, allTags } = useTags();

  const handleKeyPress = useCallback(({
    which, charCode, ctrlKey, metaKey, altKey, keyCode, target: { nodeName, classList, className },
  }) => {
    if (document.body.classList.contains('overlay-disabled')
      || classList.contains('js-input')
      || Object.values(classList).some((i) => i.indexOf('jsoneditor') > -1)
      || nodeName === 'INPUT'
      || nodeName === 'TEXTAREA'
      || nodeName.indexOf('CALCITE-') === 0
    ) {
      return;
    }

    setIsVisible(which !== 0 && charCode !== 0 && !ctrlKey && !metaKey && !altKey && keyCode !== 27 && className !== 'ql-editor' && className !== 'ql-editor ql-blank');
  }, []);

  const { usersLoading, users } = useUsersSearch(query, isVisible);
  const { v5usersLoading, v5users } = useV5UsersSearch(query, isVisible);
  const { tenantsLoading, tenants } = useTenantsSearch(query, isVisible);
  const { customersLoading, customers } = useCustomersSearch(query, isVisible);
  const { devicesLoading, devices } = useDevicesSearch(query, isVisible);

  const userQueryLoading = useMemo(() => usersLoading || v5usersLoading, [usersLoading, v5usersLoading]);
  const tenantQueryLoading = useMemo(() => tenantsLoading || customersLoading, [tenantsLoading, customersLoading]);

  const handleOverlaySearchExited = useCallback(() => {
    if (!isVisible) return;
    setIsVisible(false);
    setQuery('');
    setInProgressQuery('');
  }, [isVisible]);

  const handleKeyUp = useCallback(({ keyCode }) => {
    if (!isVisible) return;
    switch (keyCode) {
      case 13:
        console.log('setQuery', inProgressQuery);
        setQuery(inProgressQuery);
        break;
      case 27:
        handleOverlaySearchExited();
        break;
      default:
        break;
    }
  }, [isVisible, inProgressQuery]);

  const tenantsResults = useMemo(() => {
    if (allTagsLoading || customersLoading || tenantsLoading) { return []; }
    const allEccs = customers?.flatMap(({ env, eccs, name: customerName }) => eccs.map(({ eccId, name }) => ({
      type: 'Customer', link: `/#/${env}`, name, tags: allTags.filter(({ tenantId }) => tenantId === eccId).map(({ tagName }) => tagName), env, customerName, eccId,
    }))) || [];

    const allTenants = tenants?.map(({
      environment: env, tenantId, tenantName: name, domains,
    }) => ({
      type: 'Tenant', link: getManageLink({ env, tenantId }), tags: allTags.filter(({ tenantId: tagTenantId }) => tagTenantId === tenantId).map(({ tagName }) => tagName), name, domains, env, tenantId,
    })) || [];

    return orderBy([...allEccs, ...allTenants], ({ name }) => name);
  }, [customers, tenants]);

  const usersResults = useMemo(() => {
    if (v5usersLoading || usersLoading) { return []; }

    const _v4users = users?.map(({
      environment: env, tenantId, tenantName, name, email,
    }) => ({
      type: 'Tenant', link: getManageLink({ env, tenantId }), name, email, env, tenantName, tenantId,
    })) || [];

    const _v5users = (v5users || [])?.flatMap(({
      env, name: customerName, users: customerUsers, eccs, id,
    }) => {
      const _customerUsers = customerUsers.map(({ name, surname, email }) => ({
        type: 'Customer', link: `/#/${env}`, name: `${name} ${surname}`, email, env, customerName, id,
      })) || [];

      const _eccUsers = eccs?.flatMap(({ users: eccUsers, name: eccName, eccId }) => eccUsers.map(({
        name, surname, email, userId,
      }) => ({
        type: 'Ecc', link: `/#/${env}`, name: `${name} ${surname}`, email, env, customerName, eccName, eccId, userId,
      }))) || [];

      return [..._customerUsers, ..._eccUsers];
    });

    return orderBy([..._v4users, ..._v5users], 'name');
  }, [v5users, users, v5usersLoading, usersLoading]);

  useEffect(() => {
    if (isVisible) { searchInputField.current.focus(); }
  }, [isVisible]);

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keypress', handleKeyPress);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [inProgressQuery]);

  useEffect(() => {
    console.log('[Overlay Search] Initialized...');
  }, []);

  return isVisible && (
    <div className="overlay" data-pages="search">
      <div className="overlay-content has-results m-t-20">
        <div className="container-fluid">
          <CalciteIcon icon='x' onClick={handleOverlaySearchExited} />
        </div>
        <div className="container-fluid">
          <input id="overlay-search" onChange={(event) => setInProgressQuery(event.target.value)} value={inProgressQuery}
            className="no-border overlay-search bg-transparent" placeholder="Search..."
            autoComplete="off" spellCheck="false" ref={searchInputField} />
          <br />
          <div className="d-flex align-items-center">
            <p className="fs-13 hint-text m-t-10 m-b-10">Search for Customers, Eccs, Users or EDG Devices...</p>
          </div>
          <CalcitePanel>
            <CalciteTabs bordered={true}>
              <CalciteTabNav slot="title-group">
                <CalciteTabTitle selected={true} iconStart='organization'>
                  Eccs
                  {tenantQueryLoading
                    ? <CalciteChip scale='s' icon='search' className='m-l-5'></CalciteChip>
                    : <CalciteChip scale='s' className='m-l-5'>{Number(tenantsResults?.length || 0).toLocaleString()}</CalciteChip>}
                </CalciteTabTitle>
                <CalciteTabTitle iconStart='users'>
                  Users
                  {userQueryLoading
                    ? <CalciteChip scale='s' icon='search' className='m-l-5' kind="brand"></CalciteChip>
                    : <CalciteChip scale='s' className='m-l-5' kind="brand">{Number(usersResults?.length || 0).toLocaleString()}</CalciteChip>}
                </CalciteTabTitle>
                <CalciteTabTitle iconStart='server'>
                  Devices
                  {devicesLoading
                    ? <CalciteChip scale='s' icon='search' className='m-l-5' kind="inverse"></CalciteChip>
                    : <CalciteChip scale='s' className='m-l-5' kind="inverse">{Number(devices?.length || 0).toLocaleString()}</CalciteChip>}
                </CalciteTabTitle>
              </CalciteTabNav>
              <CalciteTab selected>
                {tenantQueryLoading
                  ? <CalciteLoader />
                  : <CalciteList selectionMode='none'>
                    {tenantsResults.length === 0
                      ? <>No Results</>
                      : tenantsResults.map(({
                        customerName, name, tags, link, env, eccId, tenantId,
                      }, key) => {
                        const launchLinkInNewWindow = () => { launchInNewWindow(link); };
                        return (
                          <CalciteListItem key={key} label={(customerName ? `${customerName}, ${name}` : name)} description={eccId ?? tenantId}>
                            <div slot="content-start">{env}</div>
                            <CalciteAction slot="actions-end" icon="launch" onClick={launchLinkInNewWindow}></CalciteAction>
                            <CalciteChipGroup slot="content-bottom" scale='s' className='m-b-5'>{tags.map((tag) => <CalciteChip key={tag} className='m-r-5'>{tag}</CalciteChip>)}</CalciteChipGroup>
                          </CalciteListItem>
                        );
                      })}
                  </CalciteList>
                }
              </CalciteTab>
              <CalciteTab>
                {userQueryLoading
                  ? <CalciteLoader />
                  : <CalciteList selectionMode='none'>
                    {usersResults.length === 0
                      ? <>No Results</>
                      : usersResults.map(({
                        type, customerName, name, email, link, env, eccId, tenantId, tenantName, eccName,
                      }, key) => {
                        const tags = [];
                        let description = '';
                        if (type === 'Tenant') {
                          description = tenantName;
                          tags.push(tenantId);
                        } else if (type === 'Ecc') {
                          description = `${customerName} - ${eccName}`;
                          tags.push(eccId);
                        } else if (type === 'Customer') {
                          description = customerName;
                        }

                        const launchLinkInNewWindow = () => { launchInNewWindow(link); };
                        return (
                          <CalciteListItem key={key} label={`${name} ${email}`} description={description}>
                            <div slot="content-start">{env}</div>
                            <CalciteAction slot="actions-end" icon="launch" onClick={launchLinkInNewWindow}></CalciteAction>
                            <CalciteChipGroup slot="content-bottom" scale='s' className='m-b-5'>
                              {tags.map((tag) => <CalciteChip key={tag} className='m-r-5'>{tag}</CalciteChip>)}
                            </CalciteChipGroup>
                          </CalciteListItem>
                        );
                      })}
                  </CalciteList>
                }
              </CalciteTab>
              <CalciteTab>
                {devicesLoading
                  ? <CalciteLoader />
                  : <CalciteList selectionMode='none'>
                    {devices?.length === 0
                      ? <>No Results</>
                      : devices?.map(({
                        ioTHubId, deviceId, manufacturer, model, isConnected,
                      }, key) => {
                        const loadDeviceInfo = () => {
                          deviceInfoChannel.publish('load-device-info', { ioTHubId, deviceId });
                          handleOverlaySearchExited();
                        };
                        return (
                          <CalciteListItem key={key} label={`${ioTHubId} ${deviceId}`} description={`${manufacturer} ${model}`}>
                            <CalciteAction slot="content-start">
                              {isConnected
                                ? <CalciteChip scale='s'>Connected</CalciteChip>
                                : <CalciteChip scale='s' kind='inverse'>Not Connected</CalciteChip>
                              }
                            </CalciteAction>
                            <CalciteAction slot="actions-end" icon="launch" onClick={loadDeviceInfo}></CalciteAction>
                          </CalciteListItem>
                        );
                      })
                    }
                  </CalciteList>
                }
              </CalciteTab>
            </CalciteTabs>
          </CalcitePanel>
        </div>
      </div>
    </div>
  );
};

export default OverlaySearch;

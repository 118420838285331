import { createQueryKeys } from '@lukemorales/query-key-factory';
import { orderBy } from '../../services/helperscripts';
import { get } from '../../services/webhelpers';

export const loggingConfigurationQueryKeys = createQueryKeys('loggingConfiguration', {
    envs: () => ({
        queryKey: [],
        queryFn: async () => {
            const loggingEnvironments = await get('/loggingconfiguration/envs');
            return [...new Set(loggingEnvironments.map(({ group }) => group))]
                .map((groupName) => ({
                    text: groupName,
                    children: orderBy(
                        loggingEnvironments
                            .filter(({ group }) => groupName === group)
                            .map(({ name: id, label }) => ({ id, text: label.indexOf('|') > -1 ? label.split('|')[0].trim() : label }))
                            .map(({ id, text }) => ({ id, text: text.indexOf(groupName) === -1 ? text : text.replace(groupName, '').trim() })),
                        'text',
                    ),
                }));
        },
    }),
    env: (env) => ({
        queryKey: [env],
        queryFn: () => get(`/${env}/loggingconfiguration`),
    }),
});

import { CalciteAction, CalciteBlock, CalciteTooltip } from '@esri/calcite-components-react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useId, useRef } from 'react';
import { downloadString } from '../utility/downloadString';

const Chart = ({ heading, loading, options }) => {
    const ref = useRef();

    const toggleFullScreen = () => ref.current?.chart?.fullscreen?.toggle();
    const printChart = () => ref.current?.chart?.print();
    const downloadPng = () => ref.current?.chart?.exportChart({ type: 'image/png' });
    const downloadPdf = () => ref.current?.chart?.exportChart({ type: 'application/pdf' });
    const exportExcel = () => ref.current?.chart?.downloadXLS();
    const exportCsv = () => {
        const csv = ref.current?.chart?.getCSV();
        downloadString(csv, 'text/csv', 'chart.csv');
    };

    window.chart = ref?.current?.chart;

    const fullScreenId = useId();
    const printId = useId();
    const downloadPngId = useId();
    const downloadPdfId = useId();
    const exportExcelId = useId();
    const exportCsvId = useId();

    return (
        <CalciteBlock
            open={true}
            heading={heading}
            loading={loading}>
            <CalciteAction
                slot="actions-end"
                icon="full-screen"
                id={fullScreenId}
                onClick={toggleFullScreen}
            />
            <CalciteAction
                slot="actions-end"
                icon="print"
                id={printId}
                onClick={printChart}
            />
            <CalciteAction
                slot="actions-end"
                icon="file-image"
                id={downloadPngId}
                onClick={downloadPng}
            />
            <CalciteAction
                slot="actions-end"
                icon="file-pdf"
                id={downloadPdfId}
                onClick={downloadPdf}
            />
            <CalciteAction
                slot="actions-end"
                icon="file-excel"
                id={exportExcelId}
                onClick={exportExcel}
            />
            <CalciteAction
                slot="actions-end"
                icon="file-csv"
                id={exportCsvId}
                onClick={exportCsv}
            />
            <CalciteTooltip referenceElement={fullScreenId}>
                Toggle Fullscreen
            </CalciteTooltip>
            <CalciteTooltip referenceElement={printId}>
                Print Chart
            </CalciteTooltip>
            <CalciteTooltip referenceElement={downloadPngId}>
                Download PNG
            </CalciteTooltip>
            <CalciteTooltip referenceElement={downloadPdfId}>
                Download PDF
            </CalciteTooltip>
            <CalciteTooltip referenceElement={exportExcelId}>
                Export Excel
            </CalciteTooltip>
            <CalciteTooltip referenceElement={exportCsvId}>
                Export CSV
            </CalciteTooltip>
            <HighchartsReact ref={ref} highcharts={Highcharts} options={options} />
        </CalciteBlock>
    );
};

export default Chart;

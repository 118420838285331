import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { getEnvironments, getProductionEnvironments } from '../../services/helperscripts';
import EnvironmentsBarLabel from './EnvironmentsBarLabel';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { pageChannel, userPresenceChannel } from '../../instances/channels';

const PaddedDiv = styled.div`
  margin-left: 10px;
`;

const EnvironmentsBar = () => {
  const [productionEnvironments, setProductionEnvironments] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  usePostalEvent(pageChannel, 'livecallsummary', (filteredCalls) => {
    if (data === null) return;
    const latestData = data.map(({
      label, name, count, showLink, link, isProductionsLabel,
    }) => ({
      label,
      name,
      showLink,
      link,
      isProductionsLabel,
      liveCalls: filteredCalls.filter(({ environment }) => (isProductionsLabel ? productionEnvironments : [name]).includes(environment)).map(({ count: envCount }) => envCount).reduce((a, b) => a + b, 0),
      count,
    }));
    setData([...latestData]);
  }, [data]);

  usePostalEvent(userPresenceChannel, 'onlineusersummary', ({ summary }) => {
    if (data === null) return;
    const latestData = data.map(({
      label, name, liveCalls, showLink, link, isProductionsLabel,
    }) => ({
      label,
      name,
      showLink,
      link,
      isProductionsLabel,
      liveCalls,
      count: summary.filter(({ environment }) => (isProductionsLabel ? productionEnvironments : [name]).includes(environment)).map(({ count: envCount }) => envCount).reduce((a, b) => a + b, 0),
    }));
    setData([...latestData]);
  }, [data]);

  useEffect(() => {
    (async () => {
      try {
        const environments = await getEnvironments();

        const getNameSortIndex = (name) => {
          switch (name.toLowerCase()) {
            case 'ca':
              return 1;
            case 'int':
              return 2;
            case 'staging':
              return 3;
            case 'usg2':
              return 4;
            default:
              return 100;
          }
        };

        const sortedData = environments
          .map(({ label, name }) => ({
            label, name, liveCalls: 0, count: 0, showLink: true, link: `/#/${name}`, isProductionsLabel: false, order: getNameSortIndex(name),
          }))
          .sort(({ order: a }, { order: b }) => a - b);
        const prodLabel = [{
          label: 'PROD', name: 'prod', liveCalls: 0, count: 0, showLink: false, isProductionsLabel: true,
        }];

        setProductionEnvironments(await getProductionEnvironments(true));
        setData([...sortedData, ...prodLabel]);
        setLoading(false);
      } catch (ex) {
        console.error(ex);
        setError(ex);
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (<></>);
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <PaddedDiv slot="content-start" />
      {data.map(({
        label, name, count, liveCalls, showLink, link,
      }) => <EnvironmentsBarLabel key={name} label={label} name={name} count={count} liveCalls={liveCalls} showLink={showLink} link={link} />)}
    </>
  );
};

export default EnvironmentsBar;

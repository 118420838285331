import {
    CalciteBlock, CalciteCardGroup, CalciteChip, CalciteChipGroup,
    CalciteFlow, CalciteFlowItem, CalciteInputDatePicker,
    CalciteNotice, CalcitePanel, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import {
    useState, useEffect, useRef, useMemo,
    useCallback,
} from 'react';
import pluralize from 'pluralize';

import dayjs from 'dayjs';

import { useQueryClient } from '@tanstack/react-query';
import { useInterval } from 'usehooks-ts';
import { queries } from '../../queries/queries';
import { useCallHandlingSummary, useCallHandlingSyncStatus } from '../../queries/callhandling/queries';
import { setPageTitle } from '../../services/pagescripts';
import Grid from '../grid';

const CallHandling = () => {
    useEffect(() => {
        setPageTitle('US Gov2 | OTT T2911 Report');
    }, []);

    const gridRef = useRef();
    const queryClient = useQueryClient();

    const defaultColDef = useMemo(() => ({ filter: true }), []);

    const colDefs = useMemo(() => [
        {
            headerName: 'EccId',
            field: 'eccId',
        },
        {
            headerName: 'Ecc Name',
            field: 'eccName',
        },
        {
            headerName: 'Call Identifier',
            field: 'callId',
        },
        {
            headerName: 'Phone Number',
            field: 'phoneNumber',
        },
        {
            headerName: 'ES AppId',
            field: 'esappId',
        },
        {
            headerName: 'TimeStamp',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ data: { timeStampUtc } }) => dayjs(timeStampUtc).format('L LTS'),
            valueGetter: ({ data: { timeStampUtc } }) => dayjs(timeStampUtc).toDate(),
        },
        {
            headerName: 'Offered',
            field: 'offered',
        },
        {
            headerName: 'Accepted',
            field: 'accepted',
        },
        {
            headerName: 'Cancelled',
            field: 'cancelled',
        },
        {
            headerName: 'Answered',
            field: 'answered',
        },
        {
            headerName: 'Unanswered',
            field: 'unAnswered',
        },
        {
            headerName: 'Released/Closed',
            field: 'releasedOrClosed',
        },
        {
            headerName: '30 Min/Disconnect',
            field: 'disconnect30Min',
        },
        {
            headerName: 'Rejected',
            field: 'rejected',
        },
        {
            headerName: 'Reject Reason',
            field: 'rejectReason',
        },
        {
            headerName: 'PSAP Transfer',
            children: [
                {
                    headerName: 'Request',
                    field: 'psapTransferRequest',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Success',
                    field: 'psapTransferSuccess',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Fail',
                    field: 'psapTransferFail',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Fail Reason',
                    field: 'psapTransferFailReason',
                    type: 'rightAligned',
                },
            ],
        },
        {
            headerName: 'Agent Transfer',
            children: [
                {
                    headerName: 'Request',
                    field: 'agentTransferRequest',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Success',
                    field: 'agentTransferSuccess',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Decline',
                    field: 'agentTransferDecline',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Failure',
                    field: 'agentTransferFailure',
                    type: 'rightAligned',
                },
            ],
        },
        {
            headerName: 'Messages',
            children: [
                {
                    headerName: 'Received',
                    field: 'receivedMessages',
                    type: 'rightAligned',
                },
                {
                    headerName: 'Sent',
                    field: 'sentMessages',
                    type: 'rightAligned',
                },
            ],
        },
    ], []);

    const [syncStatusText, setSyncStatusText] = useState();
    const [summary, setSummary] = useState();
    const [showSummary, setShowSummary] = useState(false);
    const [[startDate, endDate], setDateRange] = useState([dayjs.utc().startOf('month').toJSON(), dayjs.utc().endOf('day').toJSON()]);

    const { syncStatusLoading, syncStatus } = useCallHandlingSyncStatus();
    const { dataLoading, data } = useCallHandlingSummary(startDate, endDate);

    const updateSyncStatusText = useCallback(() => {
        if (!syncStatus) return;
        setSyncStatusText(`Last synced ${dayjs(syncStatus.lastSyncDate).fromNow()}, last polled ${dayjs(syncStatus.lastPollDate).fromNow()}, last inserted ${dayjs(syncStatus.lastInsertDate).fromNow()}`);
    }, [syncStatus]);

    useInterval(() => {
        updateSyncStatusText();
    }, syncStatus ? 1000 : null);

    useInterval(() => {
        queryClient.invalidateQueries(queries.callHandling.status());
    }, 30000);

    const onDateChanged = ({ target: { value } }) => {
        setDateRange(value);
    };

    const summarizeData = (list) => {
        setShowSummary(list && list.length > 0);

        if (!list || list.length === 0) {
            return;
        }

        const offered = list.filter((item) => item.offered).length;
        const answered = list.filter((item) => item.answered).length;
        const cancelled = list.filter((item) => item.cancelled).length;
        const unAnswered = list.filter((item) => item.unAnswered).length;
        const accepted = list.filter((item) => item.accepted).length;
        const rejected = list.filter((item) => item.rejected).length;
        const releasedOrClosed = list.filter((item) => item.releasedOrClosed).length;
        const disconnect30Min = list.filter((item) => item.disconnect30Min).length;

        const isOfferedValid = offered === accepted + rejected + cancelled;
        const isAcceptedValid = accepted === answered + unAnswered;
        const isAnsweredValid = answered === releasedOrClosed + disconnect30Min;

        setSummary({
            isOfferedValid, isAcceptedValid, isAnsweredValid, offered, cancelled, answered, unAnswered, accepted, rejected, releasedOrClosed, disconnect30Min,
        });
    };

    useEffect(() => {
        summarizeData(data);
    }, [data]);

    const getSummaryKind = useCallback((value) => (value === null ? 'info' : value ? 'success' : 'danger'), []);
    const getSummaryIcon = useCallback((value) => (value === null ? null : value ? 'check' : 'exclamation-mark-triangle'), []);

    const formatWords = useCallback((value, word) => (typeof value === 'number' ? `${Number(value).toLocaleString()} ${pluralize(word, value)}` : ''), []);

    const getSummaryMessages = useCallback((list) => list.map(({ value, word }, index) => (<CalciteChip key={index} scale='s'>{formatWords(value, word)}</CalciteChip>)), []);

    const Summary = ({
        kind, value, word, list,
    }) => {
        const values = list.map((item) => item.value).reduce((acc, curr) => acc + curr, 0);
        const diff = Math.abs(value - values);
        return (
            <CalciteNotice open={true} scale='s' kind={getSummaryKind(kind)} icon={getSummaryIcon(kind)}>
                <div slot="title">
                    {formatWords(value, word)}
                </div>
                <CalciteChipGroup slot="message" scale='s'>
                    {getSummaryMessages(list)}
                    {diff > 0 && (<CalciteChip scale='s' kind='inverse'>{diff} {diff === 1 ? 'different' : 'difference'}</CalciteChip>)}
                </CalciteChipGroup>
            </CalciteNotice>
        );
    };

    const onFilterChanged = useCallback(() => {
        const list = [];
        gridRef.current?.api?.forEachNodeAfterFilter((item) => {
            list.push(item.data);
        });
        summarizeData(list);
    }, [gridRef]);

    return (
        <CalciteShell>
            <CalciteShellPanel
                slot='panel-start'
                widthScale='s'
            >
                <CalciteFlow>
                    <CalciteFlowItem heading='Date Filter'>
                        <CalciteBlock collapsible={undefined} open={true} heading='Date Range'>
                            <CalciteInputDatePicker
                                range={true}
                                layout='vertical'
                                overlayPositioning='fixed'
                                onCalciteInputDatePickerChange={onDateChanged}
                                maxAsDate={new Date()}
                                value={[startDate, endDate]}
                            />
                        </CalciteBlock>
                    </CalciteFlowItem>
                </CalciteFlow>
            </CalciteShellPanel>
            <CalcitePanel
                heading='OTT T2911 Report'
                description={syncStatusText}
                loading={syncStatusLoading || dataLoading || undefined}>
                <CalciteCardGroup label='Summary' selectionMode='none' slot='content-top'>
                    {showSummary
                        ? (
                            <>
                                <Summary
                                    kind={summary?.isOfferedValid}
                                    value={summary?.offered}
                                    word='Offered'
                                    list={[{ value: summary?.accepted, word: 'Accepted' }, { value: summary?.rejected, word: 'Rejected' }, { value: summary?.cancelled, word: 'Cancelled' }]}
                                />
                                <Summary
                                    kind={summary?.isAcceptedValid}
                                    value={summary?.accepted}
                                    word='Accepted'
                                    list={[{ value: summary?.answered, word: 'Answered' }, { value: summary?.unAnswered, word: 'Unanswered' }]}
                                />
                                <Summary
                                    kind={summary?.isAnsweredValid}
                                    value={summary?.answered}
                                    word='Answered'
                                    list={[{ value: summary?.releasedOrClosed, word: 'Released/Closed' }, { value: summary?.disconnect30Min, word: '30 min/Disconnect' }]}
                                />
                            </>
                        )
                        : (
                            <CalciteNotice scale='s' open={true} kind='info' icon='exclamation-mark-triangle'>
                                <div slot="title">
                                    No data!
                                </div>
                                <div slot="message">
                                    Please adjust your filter or date range...
                                </div>
                            </CalciteNotice>
                        )}
                </CalciteCardGroup>
                <Grid
                    innerRef={gridRef}
                    defaultColDef={defaultColDef}
                    columnDefs={colDefs}
                    onFilterChanged={onFilterChanged}
                    rowData={data}
                />
            </CalcitePanel>
        </CalciteShell>
    );
};

export default CallHandling;

import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useUpdate = (mutationFn, invalidateQueries) => {
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn,
        onError: (error, variables, context) => {
            // An error happened!
            console.log(`rolling back optimistic update with id ${context.id}`, error, variables);
        },
        onSuccess: () => {
            if (!invalidateQueries) return;
            if (Array.isArray(invalidateQueries)) {
                invalidateQueries.forEach((query) => queryClient.invalidateQueries(query));
                return;
            }
            queryClient.invalidateQueries(invalidateQueries);
        },
    });
    return mutation.mutate;
};

import {
 CalciteBlock, CalciteFlow, CalciteFlowItem, CalciteList, CalciteListItem, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import { useEffect, useState } from 'react';
import pluralize from 'pluralize';
import { useQueryClient } from '@tanstack/react-query';
import ConsoleJsonEditor from '../consolejsoneditor';
import SourceDateTimeReport from './SourceDateTimeReport';
import { queries } from '../../queries/queries';
import MissingAgentIdReport from './MissingAgentIdReport';
import InvalidCoordinatesReport from './InvalidCoordinatesReport';
import { setPageTitle } from '../../services/pagescripts';

const InvalidLogs = () => {
    useEffect(() => {
        setPageTitle('EDG ALI Log Troubleshooter');
    }, []);

    const queryClient = useQueryClient();

    const reports = [
        { name: 'Source DateTime', period: 1 },
        { name: 'Missing AgentId', period: 7 },
        { name: 'Invalid Coordinates', period: 1 },
    ];
    const [payloadJson, setPayloadJson] = useState({});

    const [selectedReport, setSelectedReport] = useState(reports[0].name);

    const onReportSelected = ({ target: { value } }) => {
        const key = () => {
            switch (value) {
                case 'Source DateTime':
                 return queries.edg.invalidLogsForSourceDateTime();
                case 'Missing AgentId':
                    return queries.edg.invalidLogsForNoAgentId();
                case 'Invalid Coordinates':
                    return queries.edg.invalidLogsForInvalidCoords();
                default:
                    return null;
            }
        };

        queryClient.invalidateQueries(key);
        setSelectedReport(value);
    };

    return (
        <CalciteShell>
            <CalciteShellPanel slot='panel-start' widthScale='s'>
                <CalciteFlow>
                    <CalciteFlowItem heading='Reports'>
                        <CalciteList selectionMode='single' selectionAppearance='border'>
                            {reports.map(({ name, period }) => (
                                <CalciteListItem key={name} selected={name === selectedReport} label={name} value={name} description={`${period} ${pluralize('day', period)}`} onCalciteListItemSelect={onReportSelected} />
                            ))}
                        </CalciteList>
                    </CalciteFlowItem>
                </CalciteFlow>
            </CalciteShellPanel>
            {selectedReport === 'Source DateTime' && <SourceDateTimeReport setPayloadJson={setPayloadJson} />}
            {selectedReport === 'Missing AgentId' && <MissingAgentIdReport setPayloadJson={setPayloadJson} />}
            {selectedReport === 'Invalid Coordinates' && <InvalidCoordinatesReport setPayloadJson={setPayloadJson} />}
            <CalciteShellPanel slot='panel-end' widthScale='l'>
                <CalciteBlock open={true} heading='Payload'>
                    <ConsoleJsonEditor
                        data={payloadJson}
                        setData={setPayloadJson}
                        collapse={false}
                        restrictAdd={true}
                        restrictEdit={true}
                        restrictDelete={true} />
                </CalciteBlock>
            </CalciteShellPanel>
        </CalciteShell>
    );
};

export default InvalidLogs;

import { AgGridReact } from 'ag-grid-react';
import {
 consoleTheme, FullSizeGrid, sideBar, statusBar,
} from '../griddefaults';

const Grid = (props) => (
    <FullSizeGrid className="ag-theme-balham">
        <AgGridReact
            ref={props.innerRef}
            consoleTheme={consoleTheme}
            loadThemeGoogleFonts={true}
            animateRows={false}
            debounceVerticalScrollbar={true}
            sideBar={sideBar}
            statusBar={statusBar}
            {...props}
        />
    </FullSizeGrid>
);

export default Grid;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '../../services/webhelpers';

export const powerBiQueryKeys = createQueryKeys('powerbi', {
    config: (key) => ({
        queryKey: [key],
        queryFn: () => get(`/powerbi-reports/${key}/config`),
    }),
    all: () => ({
        queryKey: [],
        queryFn: async () => {
            const reports = await get('/powerbi-reports/list');
            return Object.entries(reports).map(([key, item]) => ({ key, item }));
        },
    }),
});

import { useState } from 'react';
import {
 CalciteAction, CalciteActionBar, CalciteNavigationLogo, CalciteTooltip,
} from '@esri/calcite-components-react';
import { useDocumentTitle } from 'usehooks-ts';
import { copyToClipboard } from '../../services/helperscripts';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { pageChannel } from '../../instances/channels';

const PageTitle = () => {
  const [pageTitle, setPageTitle] = useState('RapidDeploy Console');
  const [pageAttributes, setPageAttributes] = useState([]);

  useDocumentTitle(pageTitle);

  usePostalEvent(pageChannel, 'title', ({ title, documentTitle, attributes }) => {
    setPageTitle(title || documentTitle);
    setPageAttributes(attributes || []);
  });

  return (
    <>
      <CalciteNavigationLogo
        slot="logo"
        thumbnail="/images/rdlogo_s_2x.png"
        heading={pageTitle}
        />
      <CalciteActionBar
        slot="content-start"
        expandDisabled={true}
        expanded={true}
        overflowActionsDisabled={true}>
        {pageAttributes.map((attribute, index) => {
          const copyAttributeToClipboard = () => copyToClipboard(attribute);
          return (
            <div key={index}>
              <CalciteAction text={attribute} onClick={copyAttributeToClipboard} id={`attribute-${index}`} />
              <CalciteTooltip referenceElement={`attribute-${index}`} placement='bottom'>
                Click to copy to clipboard
              </CalciteTooltip>
            </div>
          );
        })}
      </CalciteActionBar>
    </>
  );
};

export default PageTitle;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get, getBlob } from '../../services/webhelpers';

export const consoleOnlineUsersQueryKeys = createQueryKeys('consoleOnlineUsers', {
    photo: (emailAddress) => ({
        queryKey: [emailAddress],
        queryFn: () => getBlob(`/reports/users/${emailAddress}/photo`, ' image/jpeg'),
    }),
    all: () => ({
        queryKey: [],
        queryFn: async () => {
            const list = await get('/console-online-users');
            const users = [];
            for await (const user of list) {
                let blob = null;
                try {
                    blob = await getBlob(`/reports/users/${user.emailAddress}/photo`, ' image/jpeg');
                } catch (error) {
                    // Handle the error here
                    console.error(error);
                }
                users.push({ ...user, blob });
            }
            return users;
        },
    }),
});

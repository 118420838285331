import {
    CalciteBlock, CalciteFlow, CalciteFlowItem, CalciteInputDatePicker, CalciteLabel,
    CalciteShell, CalciteShellPanel, CalciteSwitch,
} from '@esri/calcite-components-react';
import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import RadiusUtilization from './RadiusUtilization';
import RadiusMonthlyBreakdown from './RadiusMonthlyBreakdown';
import { setPageTitle } from '../../services/pagescripts';

const UtilizationReporting = () => {
    useEffect(() => {
        setPageTitle('Utilization Reports');
    }, []);

    const [detailState, setDetailState] = useState();
    const [pivot, setPivot] = useState(true);
    const [[startDate, endDate], setDateRange] = useState([dayjs.utc().startOf('year').toJSON(), dayjs.utc().subtract(1, 'month').endOf('month').toJSON()]);

    const onDateChanged = ({ target: { value } }) => {
        setDateRange(value);
    };

    const onPivotChanged = ({ target: { checked } }) => {
        setPivot(checked);
    };

    const onCellClicked = ({ data }) => {
        setDetailState({ ...data });
    };

    const onDetailClose = () => {
        setDetailState(null);
    };

    return (
        <CalciteShell>
            <CalciteShellPanel slot='panel-start'>
                <CalciteFlow>
                    <CalciteFlowItem heading='Filter'>
                        <CalciteBlock collapsible={undefined} open={true}>
                            <CalciteLabel>
                                Date Range
                                <CalciteInputDatePicker
                                    range={true}
                                    layout='vertical'
                                    overlayPositioning='fixed'
                                    onCalciteInputDatePickerChange={onDateChanged}
                                    maxAsDate={new Date()}
                                    value={[startDate, endDate]}
                                />
                            </CalciteLabel>
                            <CalciteLabel layout="inline">
                                <CalciteSwitch
                                    checked={pivot || undefined}
                                    onCalciteSwitchChange={onPivotChanged} />
                                Pivot
                            </CalciteLabel>
                        </CalciteBlock>
                    </CalciteFlowItem>
                </CalciteFlow>
            </CalciteShellPanel>
            <RadiusUtilization
                startDate={startDate}
                endDate={endDate}
                pivot={pivot}
                onCellClicked={onCellClicked} />
            {detailState && (<RadiusMonthlyBreakdown onClose={onDetailClose} {...detailState} />)}
        </CalciteShell>
    );
};

export default UtilizationReporting;

import {
    CalciteBlock, CalciteButton, CalcitePanel,
} from '@esri/calcite-components-react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ControlledInputText from '../googleels/components/UI/ControlledInputText';

const validationSchema = () => Yup.object().shape({
    latitude: Yup.number().required('Latitude is required.'),
    longitude: Yup.number().required('Longitude is required.'),
});

const ReversePanel = ({ onArcgisViewImmediateClick, setValues }) => {
    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            latitude: null,
            longitude: null,
        },
    });

    useEffect(() => {
        setValue('latitude', null);
        setValue('longitude', null);
    }, []);

    useEffect(() => {
        if (!onArcgisViewImmediateClick) return;
        const { detail: { mapPoint } } = onArcgisViewImmediateClick;
        console.log('onArcgisViewImmediateClick', mapPoint.latitude.toFixed(6), mapPoint.longitude.toFixed(6));
        setValue('latitude', mapPoint.latitude.toFixed(6));
        setValue('longitude', mapPoint.longitude.toFixed(6));
    }, [onArcgisViewImmediateClick]);

    const onReverseQuery = async () => {
        const result = await trigger();
        if (!result) return;
        setValues(getValues());
    };

    return <CalcitePanel open={true} heading='Query'>
        <CalciteBlock open={true} heading='Reverse Geocode'>
            <ControlledInputText
                name="latitude"
                control={control}
                required={true}
                label="Latitude"
            />
            <ControlledInputText
                name="longitude"
                control={control}
                required={true}
                label="Longitude"
            />
            <CalciteButton onClick={onReverseQuery}>Query</CalciteButton>
        </CalciteBlock>
    </CalcitePanel>;
};

export default ReversePanel;

import Highcharts from 'highcharts';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsExportData from 'highcharts/modules/export-data';
import { downloadString } from '../utility/downloadString';

NoDataToDisplay(Highcharts);
HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);

export const chartOptionsDefaults = {
    chart: {
        lang: {
            noData: 'No data points for this date range',
        },
        height: '200px', // Chart height
        type: 'area', // Defines the chart type as a spline (smoothed line chart)
        animation: Highcharts.svg, // Enable animation for SVG elements
        marginRight: 10, // Margin on the right side of the chart
    },
    time: {
        useUTC: false, // Use local time instead of UTC
    },
    title: {
        text: null, // Chart title
    },
    xAxis: {
        gridLineWidth: 1,
        type: 'datetime', // Configure x-axis to display dates and times
        tickPixelInterval: 150, // Distance between each tick mark on the x-axis
    },
    yAxis: {
        gridLineWidth: 1,
        title: {
            text: null, // y-axis title
        },
    },
    legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'top',
        x: 60,
        y: 8,
        floating: true,
        borderWidth: 1,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
    },
    credits: {
        enabled: false,
    },
    exporting: {
        enabled: false,
    },
};

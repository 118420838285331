import {
 useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
 CalciteBlock, CalcitePanel, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import dayjs from 'dayjs';
import { setPageTitle } from '../../services/pagescripts';
import ConsoleJsonEditor from '../consolejsoneditor';
import { useMqtt } from '../../customhooks/useMQTT';
import { useNcrStream } from '../../queries/edg/queries';
import Grid from '../grid';

const NormalizedCallTakerRecordStream = ({ router }) => {
    const [environment, setEnvironment] = useState();

    useEffect(() => {
        setEnvironment(router.currentPage.params[0]);
    }, []);

    useEffect(() => {
        if (!environment) return;

        const label = environment.replaceAll('Nctr', '');
        setPageTitle(`Normalized CallTaker Record Stream - ${label}`);
    }, [environment]);

    const [payloadJson, setPayloadJson] = useState({});
    const gridRef = useRef();
    const defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        filter: true,
        enableCellChangeFlash: true,
    }), []);

    const columnDefs = useMemo(() => [
        {
          field: 'IngestionId',
          headerName: 'Ingestion Id',
        },
        {
          headerName: 'Timestamps',
          children: [
            {
              field: 'IngestionTimestamp',
              headerName: 'Ingestion Timestamp',
              filter: 'agDateColumnFilter',
              cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
            },
            {
              field: 'CorrelationTimestamp',
              headerName: 'Correlation Timestamp',
              filter: 'agDateColumnFilter',
              cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
            },
            {
              field: 'EventTimestamp',
              headerName: 'Event Timestamp',
              filter: 'agDateColumnFilter',
              cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
            },
          ],
        },
        {
          field: 'MessageId',
          headerName: 'MessageId',
        },
        {
          field: 'Timezone',
          headerName: 'Timezone',
        },
        {
          headerName: 'Device',
          children: [
            {
              field: 'DeviceId',
              headerName: 'DeviceId',
            },
            {
              field: 'DeviceName',
              headerName: 'Device Name',
            },
            {
              field: 'DeviceExtension',
              headerName: 'Device Extension',
            },
          ],
        },
        {
          field: 'SubscriptionId',
          headerName: 'SubscriptionId',
        },
        {
          field: 'CustomerId',
          headerName: 'CustomerId',
        },
        {
          field: 'TenantId',
          headerName: 'TenantId',
        },
        {
          field: 'PsapId',
          headerName: 'PsapId',
        },
        {
          field: 'PsapName',
          headerName: 'Psap Name',
        },
        {
          field: 'ParsedResultPolicyKey',
          headerName: 'Parsed Result Policy Key',
        },
        {
          field: 'EventName',
          headerName: 'Event Name',
        },
        {
          field: 'EventTimezone',
          headerName: 'Event Timezone',
        },

        {
          field: 'IsDependency',
          headerName: 'IsDependency',
        },
        {
          field: 'JobInstanceId',
          headerName: 'Job InstanceId',
        },
        {
          field: 'Queues',
          headerName: 'Queues',
        },
        {
          field: 'RequestorId',
          headerName: 'RequestorId',
        },
        {
          headerName: 'CallTaker',
          children: [
            {
              field: 'CallTakerId',
              headerName: 'CallTaker Id',
            },
            {
              field: 'CallTakerName',
              headerName: 'CallTaker Name',
            },
            {
              field: 'CallTakerRole',
              headerName: 'CallTaker Role',
            },
            {
              field: 'CallTakerExtension',
              headerName: 'CallTaker Extension',
            },
          ],
        },
    ]);

    const getRowId = useCallback(({ data: { IngestionId } }) => IngestionId, []);
    const onCellClicked = useCallback(({ data }) => { setPayloadJson(data); }, []);

    const { streamLoading, stream } = useNcrStream(environment);

    const processMqttMessage = (item) => {
        const exists = stream?.some((a) => a.IngestionId === item.IngestionId);
        gridRef.current.api.applyTransaction(exists ? { update: [item] } : { add: [item] });
    };

    useMqtt(environment ? `nctr/logs/${environment}` : null, processMqttMessage, [environment, gridRef]);

    const onGridReady = () => {
        gridRef.current.api.applyColumnState({
            state: [{ colId: 'IngestionUnixTimestamp', sort: 'desc' }],
            defaultState: { sort: null },
        });
    };

    return (
        <CalciteShell>
            <CalciteShellPanel slot="panel-end" position="end" widthScale="l">
                <CalciteBlock open={true} heading='Payload'>
                    <ConsoleJsonEditor
                        data={payloadJson}
                        setData={setPayloadJson}
                        collapse={false}
                        restrictAdd={true}
                        restrictEdit={true}
                        restrictDelete={true} />
                </CalciteBlock>
            </CalciteShellPanel>
            <CalcitePanel>
              <Grid
                  innerRef={gridRef}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  getRowId={getRowId}
                  onCellClicked={onCellClicked}
                  onGridReady={onGridReady}
                  loading={streamLoading}
                  rowData={stream}
              />
            </CalcitePanel>
        </CalciteShell>
    );
};

export default NormalizedCallTakerRecordStream;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { orderBy } from '../../services/helperscripts';
import { get } from '../../services/webhelpers';

export const metricsQueryKeys = createQueryKeys('metrics', {
    academyEnrollments: () => ({
        queryKey: [],
        queryFn: async () => {
            const data = await get('/reports/enrollments');
            return (orderBy(data, 'courseName').map(({
                courseName, enrolled, inProgress, completed,
            }) => {
                const count = enrolled + inProgress + completed;
                return {
                    courseName,
                    count,
                    enrolled,
                    enrolledPercent: Math.round((enrolled / count) * 100),
                    inProgress,
                    inProgressPercent: Math.round((inProgress / count) * 100),
                    completed,
                    completedPercent: Math.round((completed / count) * 100),
                };
            }));
        },
    }),
});

import {
    CalciteListItem, CalciteListItemGroup, CalciteTable, CalciteTableCell, CalciteTableHeader, CalciteTableRow,
} from '@esri/calcite-components-react';

const EncodedTable = ({ body }) => {
    console.log(body.Raw.split('&'));
    return (<CalciteListItemGroup heading='Parsed Body'>
        <CalciteListItem>
            <div slot="content">
                <CalciteTable scale="s" striped={true}>
                    <CalciteTableRow slot="table-header">
                        <CalciteTableHeader heading="Key" />
                        <CalciteTableHeader heading="Value" />
                    </CalciteTableRow>
                    {body.Raw.split('&').map((line, rowIndex) => (
                        <CalciteTableRow key={rowIndex}>
                            {line.split('=').map((cell, cellIndex) => (
                                <CalciteTableCell key={cellIndex}>
                                    {decodeURIComponent(cell)}
                                </CalciteTableCell>))}
                        </CalciteTableRow>
                    ))}
                </CalciteTable>
            </div>
        </CalciteListItem>
    </CalciteListItemGroup>);
};

export default EncodedTable;

import {
 CalciteAction, CalciteButton, CalciteInput,
} from '@esri/calcite-components-react';
import styled from 'styled-components';
import { searchChannel, breadcrumbClickChannel } from '../../instances/channels';
import { launchInNewWindow } from '../../services/webhelpers';
import ToggleAction from './ToggleAction';

const PaddedButton = styled(CalciteButton)`
  margin-right: 10px;
`;

const PaddedInput = styled(CalciteInput)`
  margin-right: 10px;
`;

const LeftPaddedDiv = styled.div`
  margin-left: 10px;
  text-transform: uppercase;
`;

const RightPaddedDiv = styled.div`
  margin-right: 10px;
  text-transform: uppercase;
`;

const StyledLink = styled.a`
  text-decoration-style: unset !important;
  color: unset !important;
  margin-left: 10px;
`;

const Breadcrumb = ({ item }) => {
  const handleClick = (e, id) => {
    e.preventDefault();
    breadcrumbClickChannel.publish(id);
  };

  const publishGridSearch = ({ target: { value } }, topic) => searchChannel.publish(topic, value);
  const publishEvent = ({ target: { checked } }) => breadcrumbClickChannel.publish(checked ? item.trueResponse : item.falseResponse);
  const publishClickEvent = (e) => handleClick(e, item.id);
  const launchLink = () => launchInNewWindow(item.href);
  const triggerGridSearch = (e) => publishGridSearch(e, item.topic);

  switch (item.useComponent) {
    case 'span':
      return (
        <LeftPaddedDiv>
          <span id={item.id}></span>
        </LeftPaddedDiv>
      );
    case 'feature':
        return (
          <RightPaddedDiv>
            <span id={item.id}></span>
          </RightPaddedDiv>
        );
    case 'toggle':
      return (
        <ToggleAction
          item={item} />
      );
    case 'button':
      return (
        <PaddedButton
          scale='s'
          kind={item.kind}
          onClick={publishClickEvent}
          id={item.id}
          iconStart={item.icon}>
          {item.text.toUpperCase()}
        </PaddedButton>
      );
    case 'link':
      return (
        <PaddedButton
          scale='s'
          kind={item.kind}
          onClick={launchLink}
          id={item.id}
          iconStart={item.icon}>
          {item.text.toUpperCase()}
        </PaddedButton>
      );
    case 'grid-search':
      return (
        <PaddedInput
          icon="search"
          placeholder="Search..."
          // scale="s"
          onCalciteInputInput={triggerGridSearch} />
      );
    default:
      break;
  }
  if (item.text !== '') {
    if (item.href) {
      return (
        <StyledLink
          href={item.href}>
          {item.text}
        </StyledLink>
      );
    }
    return (
      <CalciteAction
        text={item.text}
        textEnabled={true} />
    );
  }

  return null;
};

export default Breadcrumb;

/* eslint-disable no-param-reassign */
import Swal from 'sweetalert2';
import axios from 'axios';
import { getAccessToken } from './authentication';

const { default: Database } = await import('../instances/database');
const { getState } = await Database.getInstance();

export const launchInNewWindow = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
  return newWindow;
};

export const showTokenExpiredModal = async () => {
  if (Swal.isVisible()) return;

  const result = await Swal.fire({
    title: 'Token Expired!',
    text: 'Refresh in another window?',
    icon: 'warning',

    showCancelButton: true,
    confirmButtonText: 'Refresh Token',
    cancelButtonText: 'Reload',
    reverseButtons: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });

  if (result.isConfirmed) {
    launchInNewWindow('/account/login');
    return;
  }

  if (result.dismiss === Swal.DismissReason.cancel) {
    window.location.reload();
  }
};

export class WebHelpers {
  static getAxiosInstance = async () => {
    if (!this.instance) {
      this.abortController = new AbortController();
      const baseURL = await getState('API_SERVER_URL_OVERRRIDE') || process.env.API_SERVER_URL;

      this.instance = axios.create({ baseURL });
      this.instance.interceptors.request.use(async (config) => {
        if (baseURL !== process.env.API_SERVER_URL) {
          console.warn(`[axios] Using API server override: ${baseURL} for ${config.url}`);
        }

        config.signal = this.abortController.signal;

        if (!config.url.startsWith('/') && config.url.indexOf('rpdy.us') === -1 && config.url.indexOf('localhost:63577') === -1) return config;

        config.headers.Authorization = `Bearer ${await getAccessToken()}`;
        return config;
      });

      this.instance.interceptors.response.use(
        (response) => response,
        async (error) => {
          if (axios.isCancel(error)) {
            console.log('[axios] Request canceled', error.message);
            return;
          }

          switch (error.response.status) {
            case 401:
              await showTokenExpiredModal();
              return;
            case 403:
              {
                let extraInfo = '';
                if (error.response?.data?.Departments) {
                  extraInfo = `<p>You need to be a member of the following roles:</p><p>${error.response.data.Departments.join(', ')}</p>`;
                }
                await Swal.fire({
                  title: 'Access Denied!',
                  html: `<p>You need to be a member of the following roles:</p>${extraInfo}`,
                  icon: 'error',

                  showCancelButton: false,
                  allowOutsideClick: false,
                  allowEscapeKey: false,
                });
                return;
              }
            default:
              break;
          }

          console.log('[axios] error', error);
          throw error;
        },
      );
    }

    return this.instance;
  };

  static abortRequests = () => {
    this.abortController.abort();
  };
}

export const baseURL = async () => await getState('API_SERVER_URL_OVERRRIDE') || process.env.API_SERVER_URL;

export const get = async (uri, params, headers) => {
  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.get(uri, { params, headers });
  return response?.data;
};

export const getWithHeaders = async (uri, headers) => {
  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.get(uri, { headers });
  return response?.data;
};

export const getBlob = async (uri, type) => {
  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.get(uri, { responseType: 'blob' });
  return new Blob([response.data], { type });
};

export const post = async (uri, payload, headers) => {
  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.post(uri, payload, { headers });
  return response?.data;
};

export const put = async (uri, payload, headers) => {
  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.put(uri, payload, { headers });
  return response?.data;
};

export const sendDelete = async (uri, headers) => {
  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.delete(uri, { headers });
  return response?.data;
};

export const fetchWithAuth = async (method, url, data, headers) => {
  const config = data
    ? {
      method, url, data, headers,
    }
    : { method, url, headers };

  const instance = await WebHelpers.getAxiosInstance();
  const response = await instance.request(config);
  return response;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  return JSON.parse(jsonPayload);
};

export const createBlobUrl = (object, type) => {
  const json = typeof object === 'string'
    ? object
    : JSON.stringify(object);

  const blob = type
    ? new Blob([json], { type })
    : new Blob([json]);

  return URL.createObjectURL(blob);
};

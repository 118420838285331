import { CalciteTree, CalciteTreeItem } from '@esri/calcite-components-react';
import StatusToggle from '../UI/StatusToggle';
import Action from './Action';
import TreeItem from './TreeItem';
import { setAddAreaDialogState, setUpdateAreaDialogState } from '../../../../stores/clients';

const Areas = ({ client }) => {
    const addArea = () => {
        setAddAreaDialogState({ ...client });
    };

    const updateArea = (area) => {
        setUpdateAreaDialogState({ client, area });
    };

    return client.areas.length > 0 && (
        <CalciteTreeItem expanded={true}>
            <Action
                slot="actions-end"
                icon="plus"
                scale="s"
                textEnabled={true}
                text="Add Area"
                onClick={addArea}
            />
            Areas
            <CalciteTree slot="children" lines={true}>
                {client.areas.map(((area) => (
                    <TreeItem
                        key={area.areaId}
                        onClick={() => updateArea(area)}
                        iconStart="add-and-update-features"
                    >
                        <StatusToggle enabled={area.enabled} withoutSlot={true} />
                        {area.name}
                    </TreeItem>
                )))}
            </CalciteTree>
        </CalciteTreeItem>
    );
};

export default Areas;

/* eslint-disable react/prop-types */
import {
    CalciteCombobox, CalciteComboboxItem, CalciteInputMessage, CalciteLabel,
} from '@esri/calcite-components-react';
import { Controller } from 'react-hook-form';

const ControlledComboBox = (props) => (
    <Controller
        name={props.name}
        control={props.control}
        render={({ field, fieldState }) => (
            <CalciteLabel>
                {props.label}
                <CalciteCombobox
                    placeholder={props.placeholder}
                    disabled={props.disabled || undefined}
                    placeholderIcon={props.icon}
                    clearDisabled={props.clearDisabled}
                    selectionMode={props.selectionMode ?? 'single'}
                    overlayPositioning={props.overlayPositioning ?? 'absolute'}
                    onCalciteComboboxChange={(e) => {
                        const value = (
                            Array.isArray(e.target.value)
                                ? e.target.value
                                : [e.target.value]
                        ).filter((item) => item !== '');

                        if (value == null) {
                            field.onChange(value);
                            field.onBlur(value);
                            return;
                        }

                        if ((props.selectionMode ?? 'single').indexOf('single') > -1) {
                            field.onChange(value[0]);
                            field.onBlur(value[0]);
                            return;
                        }

                        field.onChange(value);
                        field.onBlur(value);
                    }}
                    {...field}
                >
                    {props.options.map((item) => <CalciteComboboxItem key={item.value} selected={item.value === field.value} value={item.value} textLabel={item.label}>
                        {item.content && (<div slot="content-end">{item.content}</div>)}
                    </CalciteComboboxItem>)}
                </CalciteCombobox>
                {fieldState.error && (
                    <CalciteInputMessage status="invalid" icon="exclamation-mark-triangle-f">{fieldState.error.message}</CalciteInputMessage>
                )}
            </CalciteLabel>
        )}
    />
);

export default ControlledComboBox;

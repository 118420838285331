import { CalciteButton, CalciteDialog } from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import ControlledInputText from '../UI/ControlledInputText';
import { findClient } from '../utility/findClient';
import { useAddEndPoint, useClients } from '../../../../queries/els/queries';
import { setAddEndpointDialogState, useGetClients } from '../../../../stores/clients';
import { useGetEnvironment } from '../../../../stores/environment';
import HeadersTable from './HeadersTable';

const validationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .test('Name-test', 'Name needs to be more than 5 characters', (value) => value.trim().length > 5),
    url: Yup.string()
        .required('Url is required.')
        .test('https-test', 'Url needs to be secure and start with https://', (value) => {
            try {
                const url = new URL(value);
                return url.protocol === 'https:';
            } catch (e) {
                return false;
            }
        }),
});

const AddEndPoint = () => {
    const env = useGetEnvironment();
    const addEndPoint = useAddEndPoint();

    const { clients, loading } = useClients();

    const { showAddEndpoint, client } = useGetClients();

    const [clientName, setClientName] = useState(null);

    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            name: '',
            url: '',
        },
    });

    useEffect(() => {
        if (!clients || !client?.clientId) return;

        setClientName(findClient(clients, client.clientId)?.name ?? 'RapidConnect');
    }, [clients, client, setClientName]);

    useEffect(() => {
        if (!showAddEndpoint) return;
        setValue('name', '');
        setValue('url', '');
    }, [setValue, showAddEndpoint]);

    const onCalciteDialogClose = (e) => {
        e?.preventDefault();
        setAddEndpointDialogState(null);
    };

    const saveClient = async () => {
        const result = await trigger();
        if (!result) return;
        const { name, url } = getValues();

        addEndPoint({
            env, clientId: client.clientId, name, url, headers: '',
        });
        onCalciteDialogClose();
    };

    return (
        <CalciteDialog
            slot="dialogs"
            loading={loading || undefined}
            open={showAddEndpoint || undefined}
            modal={true}
            closeDisabled={true}
            onCalciteDialogClose={onCalciteDialogClose}
            heading={'Add Endpoint'}
            description={clientName}
        >
            <ControlledInputText
                name="name"
                control={control}
                label="Name"
                required={true}
                placeholder="Name"
            />
            <ControlledInputText
                name="url"
                control={control}
                label="Url"
                required={true}
                placeholder="HTTPS Webhook URL"
                icon="webhook"
            />
            <HeadersTable clientId={client?.clientId} />
            <CalciteButton slot="footer-start" kind="neutral" onClick={onCalciteDialogClose}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={saveClient}>
                Add Endpoint
            </CalciteButton>
        </CalciteDialog >
    );
};

export default AddEndPoint;

import {
    CalciteBlock, CalciteButton, CalciteCombobox, CalciteComboboxItem, CalciteFlow, CalciteFlowItem,
    CalciteLabel, CalciteList, CalciteListItem, CalciteListItemGroup, CalciteLoader, CalcitePanel,
    CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import { useState, useEffect } from 'react';
import pluralize from 'pluralize';

import { useLoggingConfigurationEnv, useLoggingConfigurationEnvs, useUpdateLoggingConfiguration } from '../../queries/loggingconfiguration/queries';
import { setPageTitle } from '../../services/pagescripts';

const targetStates = [
    {
        state: 'Trace',
        icon: 'magnifying-glass',
    },
    {
        state: 'Debug',
        icon: 'debug',
    },
    {
        state: 'Info',
        icon: 'information-letter',
    },
    {
        state: 'Warn',
        icon: 'exclamation-mark-triangle',
    },
    {
        state: 'Error',
        icon: 'exclamation-mark-circle',
    },
];

const LoggingConfiguration = () => {
    useEffect(() => {
        setPageTitle('Logging Configuration');
    }, []);

    const [selectedEnvironment, setSelectedEnvironment] = useState(null);
    const [selectedServices, setSelectedServices] = useState([]);
    const [logLevel, setLogLevel] = useState('Warn');
    const [updateEnabled, setUpdatedEnabled] = useState(false);
    const [updateText, setUpdateText] = useState('Update');

    const { environments, environmentsLoading } = useLoggingConfigurationEnvs();
    const { services, servicesLoading } = useLoggingConfigurationEnv(selectedEnvironment);
    const updateLoggingConfiguration = useUpdateLoggingConfiguration(selectedEnvironment);

    const getEnvironmentName = () => {
        const environmentNames = environments?.flatMap(({ children }) => children);
        return environmentNames?.find(({ id }) => id === selectedEnvironment)?.text;
    };

    useEffect(() => {
        if (environmentsLoading || !environments) return;
        setSelectedEnvironment(environments[0].children[0].id);
    }, [environments, environmentsLoading]);

    const resetEnvironment = () => {
        setSelectedServices([]);
        setLogLevel('Warn');
        setUpdatedEnabled(false);
        setUpdateText('Update');
    };

    useEffect(() => {
        if (!services || services.length === 0) return;
        setSelectedServices([]);
    }, [services]);

    useEffect(() => {
        switch (selectedServices.length) {
            case 1:
                setUpdatedEnabled(true);
                setUpdateText(`Update ${selectedServices[0]} to ${logLevel}`);
                break;
            default:
                if (['Warn', 'Error'].includes(logLevel)) {
                    setUpdatedEnabled(true);
                    setUpdateText(`Update ${selectedServices.length} components to ${logLevel}`);
                    break;
                }
                setUpdatedEnabled(false);
                setUpdateText(`Cannot bulk update ${selectedServices.length} components to ${logLevel}`);

                break;
        }
    }, [logLevel, selectedServices]);

    const onEnvironmentSelected = ({ target: { value } }) => {
        resetEnvironment();
        setSelectedEnvironment(value);
    };
    const onServiceSelected = ({ target: { value: service, selected } }) => {
        setSelectedServices(selected ? [...selectedServices, service] : selectedServices.filter((v) => v !== service));
    };
    const onTargetStateSelected = ({ target: { value } }) => setLogLevel(value);

    const updateServices = async () => {
        const selectedComponents = selectedServices.map((service) => ({ service, logLevel }));
        updateLoggingConfiguration(selectedComponents);
    };

    if (environmentsLoading) {
        return (
            <CalciteLoader />
        );
    }

    return (
        <CalciteShell>
            <CalciteShellPanel slot='panel-start' widthScale='s'>
                <CalciteFlow>
                    <CalciteFlowItem heading='Environments' description={`${environments.flatMap(({ children }) => children).length} Environments`}>
                        <CalciteList selectionMode='single' selectionAppearance='border'>
                            {environments.map(({ text: heading, children }) => (<CalciteListItemGroup key={heading} heading={heading}>
                                {children.map(({ id, text }) => (
                                    <CalciteListItem key={id} selected={id === selectedEnvironment} label={text} value={id} onCalciteListItemSelect={onEnvironmentSelected} />
                                ))}
                            </CalciteListItemGroup>))}
                        </CalciteList>
                    </CalciteFlowItem>
                </CalciteFlow>
            </CalciteShellPanel>
            <CalcitePanel
                heading={selectedEnvironment ? `${getEnvironmentName(selectedEnvironment)} Services` : 'Services'}
                description={`${services?.length ?? 0} ${pluralize('service', services?.length ?? 0)}`}
                loading={servicesLoading || undefined}
            >
                {!servicesLoading && services && (<CalciteList selectionMode='multiple' filterEnabled={true}>
                    {services.map((service) => (<CalciteListItem key={service.service} label={service.service} description={service.logLevel} value={service.service} onCalciteListItemSelect={onServiceSelected} />))}
                </CalciteList>
                )}
            </CalcitePanel>
            {selectedServices.length > 0 && (
                <CalciteShellPanel slot='panel-end' widthScale='l'>
                    <CalciteFlow>
                        <CalciteFlowItem heading='Update' description={`${selectedServices.length} ${pluralize('service', services.length)}`}>
                            <CalciteBlock collapsible={false} open={true} heading='Services'>
                                <CalciteLabel>
                                    Target State
                                    <CalciteCombobox onCalciteComboboxChange={onTargetStateSelected} selectionMode='single'>
                                        {targetStates.map(({ state, icon }) => (<CalciteComboboxItem key={state} icon={icon} value={state} textLabel={state} selected={state === logLevel} />))}
                                    </CalciteCombobox>
                                </CalciteLabel>
                                <CalciteList>
                                    {selectedServices.map((service) => (<CalciteListItem key={service} label={service} />))}
                                </CalciteList>
                                <CalciteButton disabled={!updateEnabled || undefined} alignment='start' kind={updateEnabled ? 'brand' : 'danger'} width='auto' onClick={updateServices}>{updateText}</CalciteButton>
                            </CalciteBlock>
                        </CalciteFlowItem>
                    </CalciteFlow>
                </CalciteShellPanel>
            )}
        </CalciteShell>
    );
};

export default LoggingConfiguration;

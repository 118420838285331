/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */

import { get } from './webhelpers';
import { createActionButton } from './pagescripts';
import { notificationsChannel } from '../instances/channels';

let productionEnvironments;
let environments;

const rapidConsumerCustomerMappings = [
  { customerId: 'caloes', environmentName: 'ca' },
  { customerId: 'usgov2', environmentName: 'usg2' },
  { customerId: 'rapiddeployuat', environmentName: 'uat' },
];

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const numberFormat = (value) => new Intl.NumberFormat().format(value);

export const uniqBy = (arr, iteratee) => {
  const seen = new Set();
  return arr.filter((item) => {
    const key = iteratee(item);
    if (!seen.has(key)) {
      seen.add(key);
      return true;
    }
    return false;
  });
};

export const sortBy = (arr, iteratees) => [...arr].sort((a, b) => {
  for (const iteratee of iteratees) {
    const valueA = typeof iteratee === 'function' ? iteratee(a) : a[iteratee];
    const valueB = typeof iteratee === 'function' ? iteratee(b) : b[iteratee];
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
  }
  return 0;
});

export const orderBy = (arr, keys, orders = []) => {
  const keysArray = Array.isArray(keys) ? keys : [keys];
  const ordersArray = Array.isArray(orders) ? orders : [orders];

  return [...arr].sort((a, b) => {
    for (let i = 0; i < keysArray.length; i += 1) {
      const key = keysArray[i];
      const order = ordersArray[i] || 'asc';
      const dir = order === 'desc' ? -1 : 1;
      if (a[key] < b[key]) return -1 * dir;
      if (a[key] > b[key]) return 1 * dir;
    }
    return 0;
  });
};

export const copyToClipboard = (value, text) => {
  navigator.clipboard.writeText(value);

  notificationsChannel.publish('notification', {
    position: 'top-right', duration: 1500, title: text ?? value, message: 'Copied to clipboard...',
  });
};

export const DynamicImport = async (filename) => {
  const { default: Instance } = await import(`../${filename.toLowerCase()}`);
  return new Instance();
};

export const DynamicImportWithInitialize = async (filename, callback) => {
  const { default: Instance } = await import(`../${filename.toLowerCase()}`);
  const instance = new Instance();
  await instance.initialize();
  if (callback) { callback.apply(this, [instance]); }
  return instance;
};

export const GetInstance = async (filename) => {
  const { default: Instance } = await import(`../instances/${filename.toLowerCase()}`);
  return Instance.getInstance();
};

export const clearToasts = () => {
  notificationsChannel.publish('dismiss-notifications');
};

export const showToast = (notification) => {
  notificationsChannel.publish('notification', notification);
};
export const onVisible = (element, callback) => {
  new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        callback(element);
        observer.disconnect();
      }
    });
  }).observe(element);
  if (!callback) return new Promise((r) => { callback = r; });
};

export const getEnvironments = async () => {
  if (environments != null) { return environments; }

  environments = await get('/envs');
  return environments;
};

export const getProductionEnvironments = async (onlyShowNames) => {
  if (productionEnvironments != null) {
    if (onlyShowNames) { return productionEnvironments.map(({ name }) => name); }
    return productionEnvironments;
  }

  const envs = await getEnvironments();
  productionEnvironments = orderBy(envs.filter((env) => env.productionEnvironment), 'name');

  if (onlyShowNames) { return productionEnvironments.map(({ name }) => name); }
  return productionEnvironments;
};

export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const fixFullStoryUrl = (url) => (url && url.replace('https://app.fullstory-relay.rpdy.us', 'https://app.fullstory.com'));

export const formatBytes = (bytes) => {
  if (bytes === null) return null;

  if (!+bytes) return `${bytes} KiB`;

  const k = 1024;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / k ** i).toFixed(2))} ${sizes[i]}`;
};

export const startCase = (str) => str && str
  .replace(/([a-z])([A-Z])/g, '$1 $2') // split cameCase
  .replace(/[_-]+/g, ' ') // replace underscores and hyphens with spaces
  .replace(/\s+/g, ' ') // replace multiple spaces with a single space
  .toLowerCase() // convert to lowercase
  .replace(/(^|\s)\S/g, (t) => t.toUpperCase()); // convert first character of each word to uppercase

export const debounce = (func, wait, immediate) => {
  let timeout;
  return (...args) => {
    const context = this;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const chunkList = (list, chunkSize) => list.reduce((resultArray, item, index) => {
  const chunkIndex = Math.floor(index / chunkSize);

  if (!resultArray[chunkIndex]) {
    resultArray[chunkIndex] = []; // start a new chunk
  }

  resultArray[chunkIndex].push(item);

  return resultArray;
}, []);

export const toInteger = (value) => {
  const number = Number(value);
  if (Number.isNaN(number)) {
    return 0;
  }
  if (number === 0 || !Number.isFinite(number)) {
    return number;
  }
  return Math.sign(number) * Math.floor(Math.abs(number));
};

let idCounter = 0;
export const uniqueId = (prefix = '') => {
  idCounter += 1;
  return `${prefix}${idCounter}`;
};

export const getRapidDeployCustomer = (customerId) => rapidConsumerCustomerMappings.find((row) => row.customerId === customerId)?.environmentName;

export const getRapidConsumerEnvironment = (environmentName) => rapidConsumerCustomerMappings.find((row) => row.environmentName === environmentName)?.customerId;

export const pendoFeatureUsageList = (list) => {
  let userFeatures = list.map((i) => ({
    group: i.group.name.split('|').map((j) => j.trim()).join(':'),
    feature: i.name.split('|').map((k) => k.trim()).join(':'),
  }));

  userFeatures = orderBy(userFeatures, ({ group }) => group, 'asc');

  const groups = Object.groupBy(userFeatures, ({ group }) => group);

  return Object.keys(groups).map((group) => {
    const key = group.indexOf(':') > -1
      ? group.split(':')[1].toUpperCase()
      : group.toUpperCase();

    const groupFeatures = orderBy([...new Set(groups[group].map((m) => m.feature))], (i) => i, 'asc');

    const text = group.indexOf(':') > -1
      ? groupFeatures.map((i) => (i.indexOf(':') === -1 ? i : i.split(':')[1])).filter(onlyUnique).join(', ')
      : groupFeatures.join(', ');

    const features = group.indexOf(':') > -1
      ? groupFeatures.map((i) => (i.indexOf(':') === -1 ? i : i.split(':')[1])).filter(onlyUnique)
      : groupFeatures;

    return ({ key, text, features });
  });
};

export const pendoFeatureUsageLabels = (list) => pendoFeatureUsageList(list).map(({ key, text }) => createActionButton({
  key, text, style: 'info', type: 'html',
})).join('');

export const mapLabel = (expression, minScale) => {
  const label = {
    labelExpressionInfo: {
      expression,
    },
    symbol: {
      type: 'text',
      color: 'white',
      haloColor: 'black',
      haloSize: '3px',
      font: {
        family: 'Ubuntu Mono',
        size: 12,
        weight: 'bold',
      },
    },
  };

  if (minScale !== undefined) {
    label.minScale = minScale;
  }

  return [label];
};

export const auth0clients = [
  {
    env: 'usg2',
    clients: [
      { name: 'Radius', icon: '<i class="m-r-5 fa-duotone fa-desktop" title="Radius"></i>', clientId: 'U0960rVY3z727AnySYf5EklKc9MJ4WZC' },
      { name: 'Lightning', icon: '<i class="m-r-5 fa-duotone fa-mobile" title="Lightning"></i>', clientId: 'kDGowlB3kmQ6iShr995DHgcb1hn8cKPF' },
    ],
  },
  {
    env: 'staging',
    clients: [
      { name: 'Radius', icon: '<i class="m-r-5 fa-duotone fa-desktop" title="Radius"></i>', clientId: '1AcAchPKXOUoUmAmZM4k4QLmTEtQbuND' },
      { name: 'Lightning', icon: '<i class="m-r-5 fa-duotone fa-mobile" title="Lightning"></i>', clientId: 'gi6Enb64cRlLlGBTqDt0tecICX0e0lUb' },
    ],
  },
  {
    env: 'int',
    clients: [
      { name: 'Radius', icon: '<i class="m-r-5 fa-duotone fa-desktop" title="Radius"></i>', clientId: '0rmMOG0dv74kAWGL9zhzrHSlxzyyaN2j' },
      { name: 'Lightning', icon: '<i class="m-r-5 fa-duotone fa-mobile" title="Lightning"></i>', clientId: 'uwd4kJXxqCErxbwjp8RmAm5RZWCldtZ9' },
    ],
  },
];

export const splitPascalCase = (s) => s.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ');

export const extractString = (s) => {
  try {
    return (s.match(/(?<=")(.*?)(?=")/)[0]);
  } catch (error) {
    return s;
  }
};

export const getPlatformVersionName = (platform, platformVersion, browserStack) => {
  let operatingSystem = 'Unknown';
  let version = 'Unknown';
  let icon = 'fa-solid fa-block-question';

  if (platform == null || platformVersion == null) {
    if (browserStack == null) return { operatingSystem, version, icon };
    const { userAgent, os: { code } } = browserStack;

    switch (code) {
      case 'windows_10':
        icon = 'fa-brands fa-windows';
        version = 'Windows 10';
        operatingSystem = 'Microsoft Windows 10';
        break;
      case 'linux':
        icon = 'fa-brands fa-linux';
        version = 'Linux';
        operatingSystem = 'Linux';

        if (userAgent.indexOf('DatadogSynthetics') > -1) {
          version = 'Datadog Synthetics';
          operatingSystem = 'Datadog Synthetics';
        }
        break;
      default:
        console.log('[getPlatformVersionName] null', browserStack);
        break;
    }
    return { operatingSystem, version, icon };
  }

  const [majorPlatformVersion, minorPlatformVersion] = platformVersion.split('.').map((v) => parseInt(v, 10));

  switch (platform) {
    case 'Windows':
      icon = 'fa-brands fa-windows';
      version = majorPlatformVersion >= 13
        ? 'Windows 11'
        : majorPlatformVersion > 0
          ? 'Windows 10'
          : 'Windows 7/8/8.1';
      operatingSystem = `Microsoft ${version}`;

      return { operatingSystem, version, icon };
    case 'macOS':
      icon = 'fa-brands fa-apple';
      switch (majorPlatformVersion) {
        case 15: version = 'Sequoia'; break;
        case 14: version = 'Sonoma'; break;
        case 13: version = 'Ventura'; break;
        case 12: version = 'Monterey'; break;
        case 11: version = 'Big Sur'; break;
        case 10:
          switch (minorPlatformVersion) {
            case 15: version = 'Catalina'; break;
            case 14: version = 'Mojave'; break;
            case 13: version = 'High Sierra'; break;
            case 12: version = 'Sierra'; break;
            case 11: version = 'El Capitan'; break;
            case 10: version = 'Yosemite'; break;
            case 9: version = 'Mavericks'; break;
            case 8: version = 'Mountain Lion'; break;
            case 7: version = 'Lion'; break;
            case 6: version = 'Snow Leopard'; break;
            case 5: version = 'Leopard'; break;
            case 4: version = 'Tiger'; break;
            case 3: version = 'Panther'; break;
            case 2: version = 'Jaguar'; break;
            case 1: version = 'Puma'; break;
            case 0: version = 'Cheetah'; break;
            default: break;
          }
          break;
        default:
          break;
      }

      operatingSystem = `Apple ${version}`;
      return { operatingSystem, version, icon };
    default:
      console.log('[getPlatformVersionName] default', platform, platformVersion);
      return { operatingSystem, version, icon };
  }
};

export const fixBrowserName = (browser) => {
  switch (browser) {
    case 'Chromium': return 'Chrome';
    case 'Google Chrome': return 'Chrome';
    case 'Microsoft Edge': return 'Edge';
    default: return browser;
  }
};

export const getBrowserNameAndVersion = (browserVersion) => {
  const recognizedBrowsers = ['Chrome', 'Firefox', 'Google Chrome', 'Microsoft Edge', 'Opera'];
  const backupBrowsers = ['Chromium'];

  const browser = 'Unknown';
  const version = 'Unknown';

  if (browserVersion == null || browserVersion === '') { return { browser, version }; }

  const list = browserVersion
    .split(',')
    .map((item) => {
      const [Name, Version] = item.split(';');
      return {
        name: fixBrowserName(extractString(Name)),
        version: extractString(Version),
        recognized: recognizedBrowsers.includes(extractString(Name)),
        backup: backupBrowsers.includes(extractString(Name)),
      };
    });

  if (list.filter(({ recognized }) => recognized).length > 0) {
    const [browserAndVersion] = list.filter(({ recognized }) => recognized);
    return { browser: browserAndVersion.name, version: browserAndVersion.version };
  }

  if (list.filter(({ backup }) => backup).length > 0) {
    const [browserAndVersion] = list.filter(({ backup }) => backup);
    return { browser: browserAndVersion.name, version: browserAndVersion.version };
  }

  return { browser, version };
};

export const getBrowserInformation = (_browser, _clientHints) => {
  let { version: platformVersion, operatingSystem, icon } = getPlatformVersionName(_clientHints?.platform, _clientHints?.platformVersion, _browser);
  if (platformVersion === 'Unknown') {
    platformVersion = _browser?.os?.name;
  }

  const state = {
    colorScheme: _clientHints?.colorScheme,
    height: _clientHints?.height,
    width: _clientHints?.width,
    platform: _clientHints?.platform ?? _browser?.os?.family,
    platformVersion,
    operatingSystem,
    icon,
  };

  const browserVersion = _clientHints?.browserVersion;
  if (browserVersion != null && browserVersion !== '') {
    const { browser, version } = getBrowserNameAndVersion(browserVersion);

    state.browser = fixBrowserName(browser);

    try {
      const [majorVersion] = version.split('.').map((item) => parseInt(item, 10));
      state.browserMajorVersion = majorVersion;
    } catch (error) {
      console.log(version, browserVersion, error);
    }
  } else if (_browser?.browser?.version) {
    state.browser = fixBrowserName(_browser?.browser?.name);
    state.browserMajorVersion = parseInt(_browser.browser.version, 10);
  }

  return state;
};

import { useEffect, useMemo, useRef } from 'react';
import Grid from '../grid';

const UtilizationStandardGrid = ({
    columnDefs, onCellClicked, loading, rowData, exportAction,
}) => {
    const ref = useRef();
    const defaultColDef = useMemo(() => ({ filter: true }), []);

    useEffect(() => {
        if (!exportAction) return;
        ref.current?.api?.exportDataAsExcel();
    }, [exportAction]);

    return (
        <Grid
            innerRef={ref}
            defaultColDef={defaultColDef}
            columnDefs={columnDefs}
            onCellClicked={onCellClicked}
            loading={loading}
            rowData={rowData}
        />
    );
};

export default UtilizationStandardGrid;

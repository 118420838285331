import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const ConsoleQueryProvider = ({ children }) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                staleTime: 1000 * 10,
                refetchOnWindowFocus: false, // default: true
            },
        },
    });

    return (
        <QueryClientProvider client={queryClient} contextSharing={true}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />
        </QueryClientProvider>
    );
};

export default ConsoleQueryProvider;

import { CalciteAlert } from '@esri/calcite-components-react';
import { clearAlertState, useAlert } from '../../../../stores/alerts';

const Alerts = () => {
    const alert = useAlert();

    const alertFinished = () => {
        clearAlertState();
    };

    let icon = '';

    switch (alert?.kind) {
        case 'danger':
            icon = 'exclamation-mark-triangle-f';
            break;

        case 'success':
            icon = 'check-square-f';
            break;
        case 'warning':
            icon = 'exclamation-mark-circle-f';
            break;
        case 'brand':
        case 'info':
        default:
            icon = 'information-f';
            break;
    }

    return alert && (
        <CalciteAlert
            slot='alerts'
            icon={icon}
            kind={alert.kind}
            placement={alert.placement}
            open={true}
            autoClose={true}
            onCalciteAlertClose={alertFinished}
        >
            <div slot="title">{alert.title}</div>
            <div slot="message">{alert.message}</div>
        </CalciteAlert>
    );
};

export default Alerts;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '../../services/webhelpers';

export const restDebuggerQueryKeys = createQueryKeys('bins', {
    summary: () => ({
        queryKey: [],
        queryFn: () => get('/restdebugger/summary'),
    }),
    bin: (binId) => ({
        queryKey: [binId],
        queryFn: () => get(`/restdebugger/${binId}`),
    }),
});

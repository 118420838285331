import { queries } from '../queries';
import { useQueryWithPrefixedResults } from '../../customhooks/usequerywithprefixedresults';

export const useDirections = (start, end) => useQueryWithPrefixedResults('directions', queries.directions.directions(start, end), start != null && end != null);
export const useGeocodioReverseGeocodedAddress = (latitude, longitude) => useQueryWithPrefixedResults('reversedAddress', queries.directions.reverseGeocodio(latitude, longitude), latitude != null && longitude != null);
export const useReverseGeocodedAddress = ({ latitude, longitude }) => useQueryWithPrefixedResults('reverseAddress', queries.directions.reverse(latitude, longitude), latitude != null && longitude != null);
export const useForwardGeocodedAddress = ({
    streetNumber, street, city, state, country,
}) => useQueryWithPrefixedResults(
    'forwardAddress',
    queries.directions.forward(streetNumber, street, city, state, country),
    streetNumber != null && street != null && country != null,
);

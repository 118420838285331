import {
    CalciteAction,
    CalciteDialog, CalciteIcon, CalciteList, CalciteListItem,
} from '@esri/calcite-components-react';
import styled from 'styled-components';
import { useChangeLog } from '../../queries/changelog/queries';
import dayjs from '../../services/dayjs';
import { toggleChangeLog, useShowChangeLog } from '../../stores/changelog';

const OnTopCalciteDialog = styled(CalciteDialog)`
    z-index: 10000;
`;

const ChangeLog = () => {
    const showChangeLog = useShowChangeLog();

    const { changeLog: apiChangeLog, changeLogLoading: apiChangeLogLoading } = useChangeLog('API');
    const { changeLog: websiteChangeLog, changeLogLoading: websiteChangeLogLoading } = useChangeLog('Website');

    const changeLog = [
        ...(apiChangeLog ? apiChangeLog.map((item) => ({ ...item, icon: 'server' })) : []),
        ...(websiteChangeLog ? websiteChangeLog.map((item) => ({ ...item, icon: 'web' })) : []),
    ].sort((a, b) => dayjs(b.finishTime).unix() - dayjs(a.finishTime).unix());

    return showChangeLog && (
        <OnTopCalciteDialog
            open={true}
            modal={true}
            heading='Change Log'
            onCalciteDialogClose={() => toggleChangeLog(false)}>
            <CalciteList filterEnabled={true} loading={apiChangeLogLoading || websiteChangeLogLoading}>
                {changeLog?.map(({
                    icon, buildNumber, finishTime, triggerInfo: { message },
                }, index) => (
                    <CalciteListItem key={index} label={message} description={dayjs(finishTime).fromNow()}>
                        <CalciteIcon slot="content-start" icon={icon} />
                        <CalciteAction slot="actions-end" textEnabled={true} text={buildNumber} />
                    </CalciteListItem>
                ))}
            </CalciteList>
        </OnTopCalciteDialog>
    );
};

export default ChangeLog;

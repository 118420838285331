import { CalciteListItem } from '@esri/calcite-components-react';
import ConsoleJsonEditor from '../consolejsoneditor';

const JsonTable = ({ body }) => (
    <CalciteListItem label='Parsed Body'>
        <div slot="content">
            <ConsoleJsonEditor data={JSON.parse(body.Raw)} minWidth="100%" maxWidth="100%" restrictAdd={true} restrictEdit={true} restrictDelete={true} />
        </div>
    </CalciteListItem>
);

export default JsonTable;

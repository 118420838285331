/* eslint-disable no-param-reassign */
import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '../../services/webhelpers';
import { GetInstance } from '../../services/helperscripts';
import { InstanceNames } from '../../instances/names';

const { getCustomerTenants } = await GetInstance(InstanceNames.Cache);

export const consoleQueryKeys = createQueryKeys('console', {
    myDetails: () => ({
        queryKey: [],
        queryFn: () => get('/account/mydetails'),
    }),
    menuItems: () => ({
        queryKey: [],
        queryFn: () => get('/api/menu/items'),
    }),
    tenantGroups: () => ({
        queryKey: [],
        queryFn: async () => {
            const groups = await get('/tenants/groups');
            return groups
                .map((group) => ({
                    ...group,
                    tenants: group.tenants
                        .filter((tenant) => tenant.enabled)
                        .map((tenant) => ({
                            ...tenant,
                            env: group.env,
                            sort: `${group.env} ${tenant.name}`,
                        })),
                }))
                .flatMap(({ tenants }) => tenants)
                .map(({ env, tenantId, name }) => ({ environmentName: env, eccId: tenantId, eccName: name }));
        },
    }),
    customerGroups: () => ({
        queryKey: [],
        queryFn: async () => {
            const customerTenants = await getCustomerTenants();
            return Object.entries(customerTenants).flatMap(([name, customers]) => customers.flatMap(({ eccs }) => eccs).map(({ eccId, name: eccName }) => ({ environmentName: name, eccId, eccName })));
        },
    }),
});

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '../../services/webhelpers';

export const changeLogQueryKeys = createQueryKeys('changeLog', {
    all: (service) => ({
        queryKey: [service],
        queryFn: () => get(`/reports/ado/buildstatus/${service}/filter/completed/10`),
    }),
    latest: (service) => ({
        queryKey: [service],
        queryFn: () => get(`/reports/ado/buildstatus/${service}`),
    }),
});

import { createAuth0Client } from '@auth0/auth0-spa-js';

export const setupAuth0Client = async () => {
  if (window.auth0Client != null) return;

  window.auth0Client = await createAuth0Client({
    domain: process.env.AUTH0_DOMAIN,
    clientId: process.env.AUTH0_CLIENT_ID,
    authorizationParams: {
      audience: process.env.AUTH0_AUDIENCE,
    },
  });
};

export const getAccessToken = async () => {
  const isAuthenticated = await window.auth0Client.isAuthenticated();
  if (!isAuthenticated) {
    await window.auth0Client.loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
      },
    });
    return null;
  }

  const token = await window.auth0Client.getTokenSilently();
  return token;
};

export const handleAuth0Callback = async () => {
  const isAuthenticated = await window.auth0Client.isAuthenticated();
  if (isAuthenticated) {
    return;
  }

  const query = window.location.search;
  if (query.includes('code=') && query.includes('state=')) {
    await window.auth0Client.handleRedirectCallback();
    window.history.replaceState({}, document.title, '/');
  }
};

export const logout = async () => {
  window.auth0Client.logout({
    logoutParams: {
      returnTo: window.location.origin,
    },
  });
};

/* eslint-disable react/prop-types */
import { CalciteAction } from '@esri/calcite-components-react';
import { useEffect, useRef } from 'react';

const Action = (props) => {
    const ref = useRef(null);

    useEffect(() => {
        if (!ref.current) {
            return;
        }

        const buttonElement = ref.current;
        const toggleClickInvoked = () => {
            props.onClick();
        };

        buttonElement.addEventListener('click', toggleClickInvoked);

        return () => buttonElement.removeEventListener('click', toggleClickInvoked);
    }, []);

    return (
        <CalciteAction
            ref={ref}
            {...props}
        />
    );
};

export default Action;

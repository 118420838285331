/* eslint-disable no-param-reassign */
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import WebStyleSymbol from '@arcgis/core/symbols/WebStyleSymbol';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import LayerList from '@arcgis/core/widgets/LayerList';

export const getSymbol = (color, style, overrides = {}) => new SimpleMarkerSymbol({
  style,
  size: 9,
  color,
  outline: {
    width: 1.125,
    color: 'white',
  },
  ...overrides,
});

export const getColorRenderer = (color, style, label) => new SimpleRenderer({
  label,
  symbol: getSymbol(color, style),
});

export const getSimpleFillRenderer = (color, label) => new SimpleRenderer({
  label,
  symbol: new SimpleFillSymbol({
    color,
    outline: new SimpleLineSymbol({
      color: [255, 255, 255],
      width: 1,
    }),
  }),
});

export const getWebStyleSymbol = async (name, styleName, multiplier) => {
  const pointSymbol3D = await new WebStyleSymbol({ name, styleName }).fetchSymbol();

  const symbol = pointSymbol3D.clone();

  const symbolLayer = symbol.symbolLayers.getItemAt(0);
  symbolLayer.height *= multiplier;
  symbolLayer.width *= multiplier;
  symbolLayer.depth *= multiplier;

  return symbol;
};

export const get3DRenderer = async (name, styleName, multiplier) => new SimpleRenderer({
  symbol: await getWebStyleSymbol(name, styleName, multiplier),
  visualVariables: [
    {
      type: 'rotation',
      field: 'course',
      rotationType: 'geographic',
    },
  ],
});

export const listItemCreatedFunction = ({ item }) => {
  if (item.layer.type === 'web-tile' || item.layer.type === 'group') {
    return;
  }
  item.panel = {
    content: 'legend',
  };
};

export const layerListWidget = ({ view, includeLegend, open }) => ((includeLegend ?? true) ? new LayerList({
  view,
  listItemCreatedFunction: ({ item }) => {
    if (item.layer.type === 'web-tile' || item.layer.type === 'group') {
      return;
    }
    item.panel = {
      content: 'legend',
      open: open ?? false,
    };
  },
}) : new LayerList({
  view,
}));

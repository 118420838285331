import { useStore } from '@tanstack/react-store';
import { setStoreState, store, STORE_NAME } from './store';

export const updateConfirmationDialog = (heading, message, kind, confirmText, confirmAction) => {
    setStoreState(STORE_NAME.DIALOGS, 'confirm', {
        heading,
        message,
        confirmText,
        confirmAction,
        kind: kind ?? 'info',
    });
};

export const clearConfirmationDialog = () => {
    setStoreState(STORE_NAME.DIALOGS, 'confirm', null);
};

export const useConfirmationDialog = () => useStore(store, ({ [STORE_NAME.DIALOGS]: dialogs }) => dialogs.confirm);

import {
    CalciteListItem, CalciteListItemGroup, CalciteTable, CalciteTableCell, CalciteTableHeader, CalciteTableRow,
} from '@esri/calcite-components-react';

const HeaderTable = ({ headers }) => (
    <CalciteListItemGroup heading='Headers'>
        <CalciteListItem>
            <div slot="content">
                <CalciteTable striped={true} scale="s">
                    <CalciteTableRow slot="table-header">
                        <CalciteTableHeader heading='Key' />
                        <CalciteTableHeader heading='Value' />
                    </CalciteTableRow>
                    {Object.entries(headers).map((line, rowIndex) => (
                        <CalciteTableRow key={rowIndex}>
                            {line.map((cell, cellIndex) => (
                                <CalciteTableCell key={cellIndex}>
                                    {cell}
                                </CalciteTableCell>))}
                        </CalciteTableRow>
                    ))}
                </CalciteTable>
            </div>
        </CalciteListItem>
    </CalciteListItemGroup >
);

export default HeaderTable;

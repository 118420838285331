// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `calcite-navigation {
    --calcite-navigation-border-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./components/controls/header/index.css"],"names":[],"mappings":"AAAA;IACI,yDAAyD;AAC7D","sourcesContent":["calcite-navigation {\n    --calcite-navigation-border-color: transparent !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

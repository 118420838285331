import { useState } from 'react';

import {
  CalciteNavigation, CalciteNavigationLogo, CalciteShell,
} from '@esri/calcite-components-react';
import DeviceStreamContainer from './DeviceStreamContainer';

const DeviceStream = ({
  connectionId, iotHubId, deviceId,
}) => {
  const [terminalStatus, setTerminalStatus] = useState();

  return (
    <CalciteShell>
      <CalciteNavigation slot='header'>
        {!terminalStatus && (<CalciteNavigationLogo slot="logo" icon='terminal' heading='Device Stream' description={deviceId ? `${deviceId} on ${iotHubId}` : 'Loading...'} />)}
        {terminalStatus && (<CalciteNavigationLogo slot="logo" icon='terminal' heading={`${deviceId} on ${iotHubId}`} description={terminalStatus} />)}
      </CalciteNavigation>
      <DeviceStreamContainer
        connectionId={connectionId}
        iotHubId={iotHubId}
        deviceId={deviceId}
        setTerminalStatus={setTerminalStatus}
      />
    </CalciteShell>
  );
};

export default DeviceStream;

import { useEffect } from 'react';
import { InstanceNames } from '../instances/names';
import { GetInstance } from '../services/helperscripts';

const messagingInstance = await GetInstance(InstanceNames.Messaging);

export const useMqtt = (topic, callback, dependencies) => {
    useEffect(() => {
        if (!topic) return;
        messagingInstance.subscribeToMqtt(topic, callback);

        return () => messagingInstance.unsubscribeFromMqtt(topic, callback);
    }, dependencies || []);
};

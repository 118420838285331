/* eslint-disable react/prop-types */

import { CalciteIcon } from '@esri/calcite-components-react';
import styled from 'styled-components';

const PaddedIcon = styled(CalciteIcon)`
    padding-right: 0.5rem;
`;

const StatusToggle = ({ enabled, withoutSlot }) => (withoutSlot
    ? (<PaddedIcon icon={enabled ? 'toggle-on' : 'toggle-off'} />)
    : (<CalciteIcon slot={'content-start'} icon={enabled ? 'toggle-on' : 'toggle-off'} />));

export default StatusToggle;

import { useState, useEffect, useCallback } from 'react';
import { useInterval } from 'usehooks-ts';
import { Terminal } from '@xterm/xterm';
import { FitAddon } from '@xterm/addon-fit';
import { CalciteAlert } from '@esri/calcite-components-react';
import styled from 'styled-components';
import { GetInstance } from '../../services/helperscripts';
import { InstanceNames } from '../../instances/names';
import { useCreateLegacyStream, useCreateSecureStream } from '../../queries/edg/queries';

import '@xterm/xterm/css/xterm.css';

const messagingInstance = await GetInstance(InstanceNames.Messaging);

const TerminalContainer = styled.div`
    padding: '18px',
    overflow: 'hidden',
    backgroundColor: '#000',
`;

const DeviceStreamTerminal = ({
    isSecure, iotHubId, deviceId, connectionId, setTerminalStatus, workItemReference, reason, username, password,
}) => {
    const columns = 80;
    const rows = 25;

    const [terminal, setTerminal] = useState();
    const [debugText, setDebugText] = useState();

    const createLegacyStream = useCreateLegacyStream();
    const createSecureStream = useCreateSecureStream();

    const messageTypes = {
        terminalInput: 'terminal-input',
        statusmessages: 'statusmessages',
        echo: 'echo',
        terminal: 'terminal',
    };

    const createTopic = useCallback((messageType) => `devicestream/${connectionId}/${messageType}`, [connectionId]);

    const sendKeyStroke = (message) => messagingInstance.publishToMqttWithQoS(1, createTopic(messageTypes.terminalInput), { message });

    useInterval(() => {
        setDebugText(null);
    }, debugText ? 5000 : null);

    const onTerminalKey = (e) => {
        if (e.domEvent.ctrlKey && e.domEvent.type === 'keydown' && ['KeyC', 'KeyV'].includes(e.domEvent.code)) {
            console.log('[xterm] onKey - copy/paste action detected, not sending keystrokes through...', e);
            setDebugText('Copy/Paste action detected, not sending keystrokes through...');
            return;
        }
        sendKeyStroke(e.key);
    };

    const terminalCustomKeyEventHandler = useCallback((e) => {
        if (!e.ctrlKey || e.type !== 'keydown') return true;

        switch (e.code) {
            case 'KeyC':
                console.log('[xterm] attachCustomKeyEventHandler - copy action detected, not sending keystrokes through...', e);
                setDebugText('Copy action detected - updating clipboard and not sending keystrokes through...');
                navigator.clipboard.writeText(terminal.getSelection());
                return false;
            case 'KeyV':
                console.log('[xterm] attachCustomKeyEventHandler - paste action detected, not sending keystrokes through...', e);
                setDebugText('Paste action detected - reading clipboard and not sending keystrokes through...');
                navigator.clipboard.readText().then((text) => {
                    sendKeyStroke(text);
                });
                return false;
            default:
                return true;
        }
    }, [terminal]);

    const terminalContextMenuHandler = useCallback((e) => {
        e.preventDefault();

        if (terminal.hasSelection()) {
            navigator.clipboard.writeText(terminal.getSelection());
            return;
        }
        navigator.clipboard.readText().then((text) => {
            sendKeyStroke(text);
        });
    }, [terminal]);

    const processStatusMessages = (payload) => {
        console.log('[xterm] status message', payload);
        setTerminalStatus(payload.message);
    };

    const processEchoMessages = (payload) => {
        console.log('[xterm] echo message', payload);
    };

    const processTerminalMessages = useCallback((payload) => {
        console.log('[xterm] terminal message', payload);
        terminal.write(payload);
    }, [terminal]);

    useEffect(() => {
        setTerminalStatus('Creating Device Stream Session');
        if (isSecure) {
            createSecureStream({
                iotHubId, deviceId, connectionId, reason, workItemReference, columns, rows,
            });
        } else {
            createLegacyStream({
                iotHubId, deviceId, connectionId, reason, workItemReference, username, password, columns, rows,
            });
        }
        setTerminal(new Terminal());
    }, []);

    useEffect(() => {
        if (!terminal) return;

        const fitAddon = new FitAddon();
        terminal.loadAddon(fitAddon);

        terminal.onKey(onTerminalKey);
        terminal.attachCustomKeyEventHandler(terminalCustomKeyEventHandler);

        const container = document.getElementById('terminal-container');
        container.addEventListener('contextmenu', terminalContextMenuHandler);

        messagingInstance.subscribeToMqtt(createTopic(messageTypes.statusmessages), processStatusMessages);
        messagingInstance.subscribeToMqtt(createTopic(messageTypes.echo), processEchoMessages);
        messagingInstance.subscribeToMqtt(createTopic(messageTypes.terminal), processTerminalMessages);

        terminal.open(container);
        fitAddon.fit();

        return () => {
            messagingInstance?.unsubscribeFromMqttWithoutChecks(createTopic(messageTypes.statusmessages));
            messagingInstance?.unsubscribeFromMqttWithoutChecks(createTopic(messageTypes.echo));
            messagingInstance?.unsubscribeFromMqttWithoutChecks(createTopic(messageTypes.terminal));
        };
    }, [terminal]);

    return (
        <>
            <TerminalContainer id='terminal-container' />
            {debugText && (
                <CalciteAlert
                    open={true}
                    placement='bottom'
                    autoClose={true}
                    autoCloseDuration={'fast'}
                    icon="debug-script" >
                    <div slot="message">{debugText}</div>
                </CalciteAlert>
            )}
        </>
    );
};

export default DeviceStreamTerminal;

import { CalciteButton, CalciteDialog } from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import ControlledInputText from '../UI/ControlledInputText';
import { findClient } from '../utility/findClient';
import { useAddClient, useClients } from '../../../../queries/els/queries';
import { setAddClientDialogState, useGetClients } from '../../../../stores/clients';
import { useGetEnvironment } from '../../../../stores/environment';

const validationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required('Name is required.')
        .test('Name-test', 'Name needs to be more than 5 characters', (value) => value.trim().length > 5),
});

const AddClient = () => {
    const env = useGetEnvironment();
    const addClient = useAddClient();

    const { clients, loading } = useClients();

    const { showAddClient, parentClientId } = useGetClients();

    const [clientName, setClientName] = useState(null);

    const {
        control, trigger, getValues, setValue,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(validationSchema()),
        defaultValues: {
            name: '',
        },
    });

    useEffect(() => {
        if (!clients) return;

        setClientName(findClient(clients, parentClientId)?.name ?? 'RapidConnect');
    }, [clients, parentClientId, setClientName]);

    useEffect(() => {
        if (!showAddClient) return;
        setValue('name', '');
    }, [setValue, showAddClient]);

    const saveClient = async () => {
        const result = await trigger();
        if (!result) return;
        const { name } = getValues();

        addClient({ env, parentClientId, name });
        setAddClientDialogState(null);
    };

    const onCalciteDialogClose = (e) => {
        e.preventDefault();
        setAddClientDialogState(null);
    };

    return (
        <CalciteDialog
            slot="dialogs"
            loading={loading || undefined}
            open={showAddClient || undefined}
            modal={true}
            closeDisabled={true}
            onCalciteDialogClose={onCalciteDialogClose}
            heading={'Add Client'}
            description={clientName}
        >
            <ControlledInputText
                name="name"
                control={control}
                required={true}
                label="Client Name"
                placeholder="Client Name"
            />
            <CalciteButton slot="footer-start" kind="neutral" onClick={onCalciteDialogClose}>
                Cancel
            </CalciteButton>
            <CalciteButton slot="footer-end" onClick={saveClient}>
                Add Client
            </CalciteButton>
        </CalciteDialog >
    );
};

export default AddClient;

/* eslint-disable max-len */
import $ from 'jquery';
import Swal from 'sweetalert2';
import { createRoot } from 'react-dom/client';
import { get } from './webhelpers';
import dayjs from './dayjs';
import { notificationsChannel, pageChannel } from '../instances/channels';
import ConsoleQueryProvider from '../queries/ConsoleQueryProvider';

const { default: Database } = await import('../instances/database');
const { getState, setState } = Database.getInstance();
const GRIDSTATE_VERSION = 10;

export const setBreadcrumbBar = (html) => {
  $('#breadcrumb-bar').empty().append(html);
};

export const fetchHtml = async (tag) => {
  const cacheBusterTag = await getState('build-number') ?? dayjs().toISOString();
  const response = await fetch(`/html/${tag.toLowerCase()}.html?v=${cacheBusterTag}`);
  const text = await response.text();
  return text;
};

export const setBreadcrumbs = (left, right, options = {}) => {
  pageChannel.publish('setBreadcrumbs', { left, right, options });
};

export const setBreadcrumbProgress = ({ percent, currentValue, maxValue }) => {
  pageChannel.publish(
    'setProgressBarPercent',
    percent ? { percent } : { percent: parseInt((currentValue / maxValue) * 100, 10) },
  );
};

export const setBodyContainer = (html) => {
  $('#body-container').empty().append(html);
};

export const setFullBodyContainer = (html) => {
  $('#page-content-wrapper').empty().append(html);
};

export const setBodyContainerFromHtml = async (tag) => {
  const html = await fetchHtml(tag);
  setBodyContainer(html);
};

export const setFullBodyContainerFromHtml = async (tag) => {
  pageChannel.publish('showBreadcrumbs', false);
  const html = await fetchHtml(tag);
  setFullBodyContainer(html);
};

export const setBodyContainerToFullScreen = async () => {
  pageChannel.publish('showBreadcrumbs', false);
  $('.header').hide();
  $('.page-container').hide();

  const body = await fetchHtml('_body');

  $('#page-content-wrapper')
    .empty()
    .removeClass('full-height')
    .html(body);
};

export const resetBodyContainerFromFullScreen = async () => {
  pageChannel.publish('showBreadcrumbs', true);

  $('.header').show();
  $('.page-container').show();

  const body = await fetchHtml('_body');

  $('#page-content-wrapper')
    .empty()
    .removeClass('full-height')
    .html(body);

  return true;
};

export const sharepointLink = (eccName) => {
  const folders = ['ExternalSecuredContainer', 'Shared Documents', 'Console Data Exports', eccName];
  return `https://rapiddeployptyltd.sharepoint.com/:f:/r/sites/${folders.map((folder) => encodeURI(folder)).join('/')}`;
};

export const showAlert = (title, message) => {
  notificationsChannel.publish('alert', { title, message });
};

export const showProgressToast = (options) => {
  notificationsChannel.publish('progress-notification', options);
};

export const showProgressIndicator = ({
  title, text, timer, timerProgressBar, position, progressSteps, currentProgressStep, html, backdrop,
}) => {
  if (Swal.isVisible()) {
    Swal.update({
      title,
      text,
      html,
    });
    return;
  }

  Swal.fire({
    title,
    text,
    imageUrl: 'https://cdn.rpdy.us/staticassets/rapid-deploy-x.png',
    imageWidth: 100,
    imageHeight: 100,
    showConfirmButton: false,
    timer: timer ?? 30000,
    timerProgressBar: timerProgressBar ?? true,
    backdrop: backdrop ?? true,
    position: position ?? 'center',
    progressSteps: progressSteps ?? [],
    currentProgressStep: currentProgressStep ?? undefined,
  });
};

export const getGridState = async (gridId) => {
  const gridState = await getState(`grid-state-${GRIDSTATE_VERSION}-${gridId.replace('#', '')}`);
  return gridState;
};

export const saveGridState = async (params) => {
  const { gridId } = params.api.getGridOption('context');
  await setState(`grid-state-${GRIDSTATE_VERSION}-${gridId}`, params.state);
};

export const setBodyContainerToFullHeight = async () => {
  $('#page-content-wrapper').empty().addClass('full-height');
};

export const setupPageFromContainerType = (containerType) => {
  switch (containerType) {
    case 'full-height':
      setBodyContainerToFullHeight();
      break;
    case 'full-screen':
      setBodyContainerToFullScreen();
      break;
    default:
      break;
  }
};

export const showLoadStatus = (text) => {
  const control = document.querySelector('#root-container > div > div.container-fluid > div > ol.breadcrumb.pull-right.m-r-15 > li > a');
  if (!control) { return; }

  control.innerText = text;
};

export const createActionButton = ({
  key, text, style, selector, handler, type, title,
}) => {
  const control = $('<span>', { class: 'btn-group btn-group-xs m-r-5 p-b-5', title })
    .on('click', selector, handler)
    .append($('<span>', { class: `btn btn-${style} fs-10` }).append(key))
    .append($('<span>', { class: 'btn btn-default fs-10' }).append(text));
  if (type === 'html') return control.get(0).outerHTML;
  return control;
};

export const createIcon = (icon, withPadding = true) => `<i class="${icon} ${withPadding ? 'm-r-5' : ''}"></i>`;

export const createLabel = (style, title, contents, withPadding = true) => `<span class="label label-${style} ${withPadding ? 'm-r-5' : ''}" title="${title}">${contents ?? title}</span>`;
export const createIconLabel = (style, title, icon, withPadding = true) => `<span class="label label-${style} ${withPadding ? 'm-r-5' : ''}" title="${title}">${createIcon(icon, false)}</span>`;

export const performPriviledgedAction = async ({
  icon, title, confirmButtonText, requireWarp, callback, showReasonAndReferenceControls, preChecks, target,
}) => {
  const html = (showReasonAndReferenceControls == null || showReasonAndReferenceControls === true)
    ? `<p>Please include a reason and workitem reference for this action.</p>
      <div class="form-group-attached">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group form-group-default">
              <label for="priviledgedaction-workItemReference">Workitem Reference</label>
              <input id="priviledgedaction-workItemReference" type="text" class="form-control" autocomplete="off" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group form-group-default required">
              <label for="priviledgedaction-reason">Reason</label>
              <textarea id="priviledgedaction-reason" class="form-control" style="height: 70px;" autocomplete="off"></textarea>
            </div>
          </div>
        </div>
      </div>`
    : '';
  const { value: formValues } = await Swal.fire({
    title,
    icon: icon || 'info',
    confirmButtonText,
    showCloseButton: true,
    showCancelButton: false,
    focusConfirm: false,
    reverseButtons: true,
    showDenyButton: false,
    showConfirmButton: false,
    target: target || 'body',
    denyButtonText: 'Refresh IP Address details',
    customClass: {
      htmlContainer: 'text-left',
      footer: 'alert alert-danger text-left',
    },
    didOpen: async () => {
      if (preChecks) {
        Swal.showValidationMessage('Performing checks...');
        Swal.showLoading();
        const { result, reason } = await preChecks();
        Swal.hideLoading();
        if (result === false) {
          Swal.update({ showConfirmButton: false, html: '' });
          Swal.showValidationMessage(reason);
          return;
        }
      }
      Swal.resetValidationMessage();
      Swal.update({
        html,
        showDenyButton: requireWarp != null,
        showConfirmButton: requireWarp == null,
      });

      window.setTimeout(() => {
        document.getElementById('priviledgedaction-reason')?.focus();
      }, 0);

      if (requireWarp == null) return;

      Swal.update({ footer: 'Verifying connection information...' });

      const addressCheckResult = await get(`/${requireWarp}/check-support-accessip`);
      if (addressCheckResult.allowed) {
        Swal.update({ footer: '', showConfirmButton: true, showDenyButton: false });
        return;
      }

      const allowedAddresses = addressCheckResult.allowedAddresses
        .map(({ cidr, description }) => `<li><b class="m-r-10">${cidr}</b>${description}</li>`)
        .reduce((accumulator, currentValue) => accumulator + currentValue, '');

      const footerHtml = `
      <strong class="all-caps">Your current address (${addressCheckResult.myIPAddress}) is not whitelisted.</strong>
      <p>Connect from the office VPN or by running Cloudflare WARP.</p>
      <p>Allowed Addresses</p>
      <ul id="ip-warning-address-list">${allowedAddresses}</ul>
      `;

      Swal.update({ footer: footerHtml });
    },
    preDeny: async () => {
      const addressCheckResult = await get(`/${requireWarp}/check-support-accessip`);
      if (addressCheckResult.allowed) {
        Swal.update({ footer: '', showConfirmButton: true });
        return false;
      }

      const allowedAddresses = addressCheckResult.allowedAddresses
        .map(({ cidr, description }) => `<li><b class="m-r-10">${cidr}</b>${description}</li>`)
        .reduce((accumulator, currentValue) => accumulator + currentValue, '');

      const footerHtml = `
      <strong class="all-caps">Your current address (${addressCheckResult.myIPAddress}) is not whitelisted.</strong>
      <p>Connect from the office VPN or by running Cloudflare WARP.</p>
      <p>Allowed Addresses</p>
      <ul id="ip-warning-address-list">${allowedAddresses}</ul>
      `;

      Swal.update({ footer: footerHtml });
      return false;
    },
    preConfirm: async () => {
      if (preChecks) {
        const { result, reason } = await preChecks();
        if (result === false) {
          Swal.update({ showConfirmButton: false, html: '' });
          Swal.showValidationMessage(reason);
          return false;
        }
      }

      if (showReasonAndReferenceControls != null && showReasonAndReferenceControls !== true) {
        return true;
      }

      const workItemReference = document.getElementById('priviledgedaction-workItemReference').value;
      const reason = document.getElementById('priviledgedaction-reason').value;

      if (reason.length === 0 || reason === ' ') {
        Swal.showValidationMessage('Please complete the reason');
        return false;
      }

      return {
        workItemReference,
        reason,
      };
    },
  });
  if (formValues == null) return;
  callback.apply(this, [formValues]);
};

export const adjustGridHeights = (bufferState) => {
  setTimeout(() => {
    let buffer = 25;
    const bodyHeight = document.body.clientHeight;

    const visibleGrids = Array.from($('.ag-theme-balham:visible'));

    visibleGrids.forEach((elem) => {
      const gridId = $(elem).attr('id');
      const gridHeights = $(elem).offset().top;

      if (bufferState != null) {
        buffer += bufferState.find(({ grid }) => grid === gridId)?.buffer ?? 0;
      } else if (gridId?.startsWith('deviceinfo-')) {
        buffer = 80;
      }

      const newGridHeight = bodyHeight - gridHeights - buffer;
      const existingGridHeight = $(elem).height();

      if (existingGridHeight === newGridHeight) return;
      $(elem).height(newGridHeight);
    });
  }, 500);
};

export const setPageTitle = (documentTitle, title, attributes) => {
  pageChannel.publish('title', { documentTitle, title, attributes });
};
export const setMenuBar = (extraMenuItems) => {
  pageChannel.publish('menubar', { extraMenuItems: extraMenuItems ?? [] });
  $.Pages.initHorizontalMenu();
};

export const slugify = (str) => String(str)
  .normalize('NFKD') // split accented characters into their base characters and diacritical marks
  .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
  .trim() // trim leading or trailing whitespace
  .toLowerCase() // convert to lowercase
  .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
  .replace(/\s+/g, '-') // replace spaces with hyphens
  .replace(/-+/g, '-'); // remove consecutive hyphens

export const formatTimeWithTimeZone = (timeZone, timeStampUtc) => (timeStampUtc
  ? dayjs(timeStampUtc).tz(timeZone).format('ll LTS')
  : dayjs().tz(timeZone).format('ll LTS'));

export const countries = () => [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D\'Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People\'S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People\'S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
];

export const labelFormatter = ({ id, text, selected }) => {
  const label = id && id !== '{null}' ? `<span class="label m-r-5">${id}</span>${text}` : text;

  return (selected
    ? `<span>${label}<span class="label label-danger m-l-5 all-caps fs-9">selected</span></span>`
    : `<span>${label}</span>`);
};

const countriesSelect2Template = ({ id, text }) => {
  if (!id) return text;
  const outerSpan = document.createElement('span');
  const innerSpan = document.createElement('span');
  innerSpan.classList.add('fi', `fi-${id.toLowerCase()}`, 'm-r-5');
  outerSpan.appendChild(innerSpan);
  outerSpan.append(text);

  return outerSpan;
};

export const countriesSelect2Params = () => ({
  data: countries().map(({ name: text, code: id }) => ({ id, text })),
  templateResult: countriesSelect2Template,
  templateSelection: countriesSelect2Template,
});

export const toTitleCase = (str) => str
  .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
  .map((x) => x.slice(0, 1).toUpperCase() + x.slice(1))
  .join(' ');

export const USStates = () => ({
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
});

export const initializeReactControl = (control, id) => {
  const root = createRoot(document.getElementById(id || 'container'));
  root.render(
    <ConsoleQueryProvider>
      {control}
    </ConsoleQueryProvider>,
  );
  return root;
};

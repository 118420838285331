/* eslint-disable no-param-reassign */
export default class Router {
  constructor(options) {
    const settings = this.getSettings(options);

    this.notFoundHandler = settings.page404;
    this.mode = (!window.history || !window.history.pushState) ? 'hash' : settings.mode;
    this.root = settings.root === '/' ? '/' : `/${this.trimSlashes(settings.root)}/`;
    this.beforeHook = settings.hooks.before;
    this.securityHook = settings.hooks.secure;

    this.routes = [];
    if (settings.routes && settings.routes.length > 0) {
      const thisState = this;
      settings.routes.forEach((route) => {
        thisState.add(route.rule, route.handler, route.options);
      });
    }

    this.pageState = null;
    this.currentPage = null;
    this.skipCheck = false;
    this.action = null;

    this.historyStack = [];
    this.historyIdx = 0;
    this.historyState = 'add';

    return this;
  }

  Page = function (uri, query, params, state, options) {
    this.uri = uri || '';
    this.query = query || {};
    this.params = params || [];
    this.state = state || null;
    this.options = options || {};
  };

  getSettings = (options = {}) => {
    const defaults = {
      routes: [],
      mode: 'hash',
      root: '/',
      hooks: {
        before() {
        },
        secure() {
          return true;
        },
      },
      page404(page) {
        console.error({
          page,
          message: '404. Page not found',
        });
      },
    };

    const settings = Object.assign({
      hooks: { ...defaults.hooks, ...options.hooks || {} },
    }, ...['routes', 'mode', 'root', 'page404'].map((key) => ({ [key]: options[key] || defaults[key] })));

    return settings;
  };

  /**
     * Get URI for router "hash" mode
     *
     * @private
     * @returns {string}
     */
  getHashFragment = () => {
    const hash = window.location.hash.substr(1).replace(/(\?.*)$/, '');
    return this.trimSlashes(hash);
  };

  /**
     * Get current URI
     *
     * @private
     * @returns {string}
     */
  getFragment = () => this.getHashFragment();

  /**
     * Trim slashes for path
     *
     * @private
     * @param {string} path
     * @returns {string}
     */
  trimSlashes = (path) => {
    if (typeof path !== 'string') {
      return '';
    }
    return path.toString().replace(/\/$/, '').replace(/^\//, '');
  };

  /**
     * 404 Page Handler
     *
     * @private
     */
  page404 = (path) => {
    this.currentPage = new this.Page(path);
    this.notFoundHandler(path);
  };

  /**
     * Convert the string route rule to RegExp rule
     *
     * @param {string} route
     * @returns {RegExp}
     * @private
     */
  parseRouteRule = (route) => {
    if (typeof route !== 'string') {
      return route;
    }
    const uri = this.trimSlashes(route);
    const rule = uri
      .replace(/([\\\/\-\_\.\\@])/g, '\\$1')
      .replace(/\{[a-zA-Z]+\}/g, '(:any)')
      .replace(/\:any/g, '[\\w\\-\\_\\.\\@]+')
      .replace(/\:word/g, '[a-zA-Z]+')
      .replace(/\:num/g, '\\d+');

    return new RegExp(`^${rule}$`, 'i');
  };

  /**
     * Parse query string and return object for it
     *
     * @param {string} query
     * @returns {object}
     * @private
     */
  parseQuery = (query) => {
    const parsedQuery = {};
    if (typeof query !== 'string') {
      return parsedQuery;
    }

    if (query[0] === '?') {
      query = query.substr(1);
    }

    this.queryString = query;
    query.split('&').forEach((row) => {
      const parts = row.split('=');
      if (parts[0] !== '') {
        if (parts[1] === undefined) {
          parts[1] = true;
        }
        parsedQuery[decodeURIComponent(parts[0])] = parts[1];
      }
    });
    return parsedQuery;
  };

  /**
     * Get query for `hash` mode
     *
     * @returns {Object}
     * @private
     */
  getHashQuery = () => {
    const index = window.location.hash.indexOf('#');
    const query = (index !== -1) ? window.location.hash.substr(index) : '';
    return this.parseQuery(query);
  };

  /**
     * Get query as object
     *
     * @private
     * @returns {Object}
     */
  getQuery = () => this.getHashQuery();

  /**
     * Add route to routes list
     *
     * @param {string|RegExp} rule
     * @param {function} handler
     * @param {{}} options
     * @returns {Router}
     */
  add = (rule, handler, options) => {
    this.routes.push({
      rule: this.parseRouteRule(rule),
      handler,
      options,
    });
    return this;
  };

  /**
     * Remove a route from routes list
     *
     * @param param
     * @returns {Router}
     */
  remove = (param) => {
    const thisState = this;
    if (typeof param === 'string') {
      param = this.parseRouteRule(param).toString();
    }
    this.routes.some((route, i) => {
      if (route.handler === param || route.rule.toString() === param) {
        thisState.routes.splice(i, 1);
        return true;
      }
      return false;
    });

    return this;
  };

  /**
     * Reset the state of Router
     *
     * @returns {Router}
     */
  reset = () => {
    this.routes = [];
    this.mode = null;
    this.root = '/';
    this.pageState = {};
    this.removeUriListener();

    return this;
  };

  /**
     * Add current page in history stack
     * @private
     */
  pushHistory = () => {
    const thisState = this;
    const fragment = this.getFragment();

    if (this.historyState === 'add') {
      if (this.historyIdx !== this.historyStack.length - 1) {
        this.historyStack.splice(this.historyIdx + 1);
      }

      this.historyStack.push({
        path: fragment,
        state: thisState.pageState,
      });

      this.historyIdx = this.historyStack.length - 1;
    }
    this.historyState = 'add';
  };

  /**
     *
     * @param asyncRequest boolean
     * @returns {PromiseResult<boolean> | boolean}
     * @private
     */
  unloadCallback = (asyncRequest) => {
    let result;

    if (this.skipCheck) {
      return asyncRequest ? Promise.resolve(true) : true;
    }

    if (this.currentPage && this.currentPage.options && this.currentPage.options.unloadCb) {
      result = this.currentPage.options.unloadCb(this.currentPage, asyncRequest);
      if (!asyncRequest || result instanceof Promise) {
        return result;
      }
      return result ? Promise.resolve(result) : Promise.reject(result);
    }
    return asyncRequest ? Promise.resolve(true) : true;
  };

  /**
     * Check if router has the action for current path
     *
     * @returns {boolean}
     * @private
     */
  findRoute = () => {
    const thisState = this;
    const fragment = this.getFragment();

    return this.routes.some((route) => {
      const match = fragment.match(route.rule);
      if (match) {
        match.shift();
        const query = thisState.getQuery();
        const page = new this.Page(fragment, query, match, thisState.pageState, route.options);

        if (!thisState.securityHook(page)) {
          return false;
        }

        thisState.currentPage = page;
        if (thisState.skipCheck) {
          thisState.skipCheck = false;
          return true;
        }
        thisState.beforeHook(page);
        route.handler.apply(page, match);
        thisState.pageState = null;

        window.onbeforeunload = function (ev) {
          if (thisState.unloadCallback(false)) {
            return;
          }
          ev.returnValue = true;
          return true;
        };

        return true;
      }
      return false;
    });
  };

  /**
     * Check if router has the action for current path
     *
     * @returns {boolean}
     * @private
     */
  getPage = () => {
    const thisState = this;
    const fragment = this.getFragment();

    return this.routes.find((route) => {
      const match = fragment.match(route.rule);
      if (!match) {
        return false;
      }
      match.shift();
      const query = thisState.getQuery();
      return new this.Page(fragment, query, match, thisState.pageState, route.options);
    });
  };

  /**
     *
     */
  treatAsync = () => {
    let result;

    result = this.currentPage.options.unloadCb(this.currentPage, true);
    if (!(result instanceof Promise)) {
      result = result ? Promise.resolve(result) : Promise.reject(result);
    }
    result
      .then(this.processUri.bind(this))
      .catch(this.resetState.bind(this));
  };

  /**
     *
     * @private
     */
  resetState = () => {
    this.skipCheck = true;
    this.navigateTo(this.current, this.currentPage.state, true);
  };

  /**
     * Replace current page with new one
     */
  processUri = () => {
    const fragment = this.getFragment();
    let found;

    this.current = fragment;
    this.pushHistory();

    found = this.findRoute.call(this);
    if (!found) {
      this.page404(fragment);
    }
  };

  /**
     * Check the URL and execute handler for its route
     *
     * @returns {Router}
     */
  check = () => {
    if (this.skipCheck) return this;

    // if page has unload cb treat as promise
    if (this.currentPage && this.currentPage.options && this.currentPage.options.unloadCb) {
      this.treatAsync();
    } else {
      this.processUri();
    }
    return this;
  };

  /**
     * Add the URI listener
     *
     * @returns {Router}
     */
  addUriListener = () => {
    window.onhashchange = this.check.bind(this);
    return this;
  };

  /**
     * Remove the URI listener
     *
     * @returns {Router}
     */
  removeUriListener = () => {
    window.onpopstate = null;
    window.onhashchange = null;
    return this;
  };

  /**
     * Redirect to a page with replace state
     *
     * @param {string} path
     * @param {object} state
     * @param {boolean} silent
     *
     * @returns {Router}
     */
  redirectTo = (path, state, silent) => {
    path = this.trimSlashes(path) || '';
    this.pageState = state || null;
    this.skipCheck = !!silent;

    this.historyIdx--;
    window.location.hash = path;

    return this;
  };

  /**
     * Navigate to a page
     *
     * @param {string} path
     * @param {object} state
     * @param {boolean} silent
     *
     * @returns {Router}
     */
  navigateTo = (path, state, silent) => {
    path = this.trimSlashes(path) || '';
    this.pageState = state || null;
    this.skipCheck = !!silent;

    window.location.hash = path;

    return this;
  };

  /**
     * Refresh page with recall route handler
     * @returns {Router}
     */
  refresh = () => {
    if (!this.currentPage) {
      return this;
    }
    const path = `${this.currentPage.uri}?${this.queryString}`;
    return this.navigateTo(path, this.currentPage.state);
  };

  /**
     * Go Back in browser history
     * Simulate "Back" button
     *
     * @returns {Router}
     */
  back = () => this.go(this.historyIdx - 1);

  /**
     * Go Forward in browser history
     * Simulate "Forward" button
     *
     * @returns {Router}
     */
  forward = () => this.go(this.historyIdx + 1);

  /**
     * Go to a specific history page
     *
     * @param {number} count
     * @returns {Router}
     */
  go = (count) => {
    const page = this.historyStack[count];
    if (!page) {
      return this;
    }

    this.historyIdx = count;
    this.historyState = 'hold';
    return this.navigateTo(page.path, page.state);
  };
}

import { useState, useEffect, useCallback } from 'react';
import {
  CalciteAction, CalciteDropdown, CalciteDropdownGroup, CalciteDropdownItem,
  CalciteNavigation, CalciteNavigationUser, CalciteProgress,
} from '@esri/calcite-components-react';

import EnvironmentsBar from './environmentsbar';
import PageTitle from './pagetitle';
import Menu from './menu';
import Breadcrumbs from './breadcrumbs';

import { launchInNewWindow } from '../../services/webhelpers';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { GetInstance, showToast } from '../../services/helperscripts';

import { pageChannel, pushChannel } from '../../instances/channels';
import { InstanceNames } from '../../instances/names';
import { toggleChangeLog } from '../../stores/changelog';
import { toggleQuickView } from '../../stores/quickview';
import { useBuildStatus } from '../../queries/changelog/queries';
import { useMyDetails } from '../../queries/console/queries';

import './index.css';

const {
  dbCachePurge, clearState,
} = await GetInstance(InstanceNames.Database);

const openQuickView = (e) => {
  e.preventDefault();
  toggleQuickView(true);
};

const registerForPushNotifications = (e) => {
  e.preventDefault();
  pushChannel.publish('registerForPushNotifications');
};

const clearGridSettings = async (e) => {
  e.preventDefault();

  await clearState();

  showToast({
    type: 'success',
    title: 'Grid settings cleared...',
    position: 'top-right',
    timer: 1000,
  });

  window.location.reload();
};

const clearBrowserCache = async (e) => {
  e.preventDefault();
  await dbCachePurge();

  showToast({
    type: 'success',
    text: 'Browser Database cache cleared...',
    position: 'top-right',
    timer: 1000,
  });
  window.location.reload();
};

const Header = () => {
  const [stateIsReady, setStateIsReady] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const [pushNotificationRegistered, setPushNotificationRegistered] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [progressBarPercent, setProgressBarPercent] = useState(0);

  const [completedBuildNumber, setCompletedBuildNumber] = useState('Unknown');
  const [completedBuildMessage, setCompletedBuildMessage] = useState(null);

  const [inProgressBuildId, setInProgressBuildId] = useState(null);
  const [inProgressBuildNumber, setInProgressBuildNumber] = useState(null);
  const [inProgressBuildMessage, setInProgressBuildMessage] = useState(null);
  const [showInProgressBuild, setShowInProgressBuild] = useState(false);

  usePostalEvent(pushChannel, 'isPushSubscribed', setPushNotificationRegistered);
  usePostalEvent(pushChannel, 'hasUnreadNotifications', setHasUnreadNotifications);

  usePostalEvent(pageChannel, 'setProgressBarPercent', ({ percent }) => {
    setShowProgressBar(!(percent >= 100));

    if (percent >= 100) {
      return;
    }

    setProgressBarPercent(percent / 100);
  });

  usePostalEvent(pageChannel, 'setCompletedBuildDetails', ({ buildNumber, message }) => {
    setCompletedBuildNumber(buildNumber);
    setCompletedBuildMessage(message);
  });

  usePostalEvent(pageChannel, 'setInProgressBuildDetails', ({ id, buildNumber, message }) => {
    setShowInProgressBuild(buildNumber !== null);
    setInProgressBuildId(id);
    setInProgressBuildNumber(buildNumber);
    setInProgressBuildMessage(message);
  });

  useEffect(() => {
    if (stateIsReady) {
      return;
    }

    setShowHeader(!window.location.hash.startsWith('#/device-stream/'));
    pushChannel.publish('checkPushMessagingStatus');
    setStateIsReady(true);
  }, [stateIsReady]);

  const launchInProgressBuild = (e) => {
    e.preventDefault();
    launchInNewWindow(`https://dev.azure.com/disp/RD/_build/results?buildId=${inProgressBuildId}`, '_blank');
  };

  const { myDetailsLoading, myDetails } = useMyDetails();

  const { buildStatusLoading: apiBuildStatusLoading, buildStatus: apiBuildStatus } = useBuildStatus('API');
  const { buildStatusLoading: websiteBuildStatusLoading, buildStatus: websiteBuildStatus } = useBuildStatus('Website');

  const [thumbnail, setThumbnail] = useState(null);

  const getThumbnail = useCallback(async () => {
    if (!myDetails) return null;
    const { getPhoto } = await GetInstance(InstanceNames.Cache);
    const blob = await getPhoto(myDetails.emailAddress);
    return URL.createObjectURL(blob);
  }, [myDetails]);

  useEffect(() => {
    getThumbnail().then(setThumbnail);
  }, [getThumbnail]);

  return showHeader && (
    <>
      <CalciteNavigation slot="header" className="calcite-mode-dark">
        {showProgressBar && (<CalciteProgress slot="progress" value={progressBarPercent} />)}
        <EnvironmentsBar />
        {showInProgressBuild && (
          <CalciteAction
            slot="user"
            icon="code-branch"
            onClick={launchInProgressBuild}
            textEnabled={true}
            text={`${inProgressBuildNumber} ${inProgressBuildMessage}`} />
        )}
        {!myDetailsLoading && (
          <CalciteDropdown width="m" slot="user">
            <CalciteNavigationUser
              slot="trigger"
              thumbnail={thumbnail}
              fullName={`${myDetails?.firstName || ''} ${myDetails?.lastName || ''}`}
              username={myDetails?.emailAddress || ''} />
            <CalciteDropdownGroup>
              {!apiBuildStatusLoading && apiBuildStatus?.triggerInfo?.message && (
                <CalciteDropdownItem
                  iconStart="code-branch"
                  onClick={(e) => { e.preventDefault(); toggleChangeLog(true); }}>
                    <strong>{apiBuildStatus?.buildNumber || 'Loading...'}</strong>&nbsp;
                    {apiBuildStatus?.triggerInfo?.message || 'Loading...'}
                </CalciteDropdownItem>
              )}
              {!websiteBuildStatusLoading && websiteBuildStatus?.triggerInfo?.message && (
                <CalciteDropdownItem
                  iconStart="code-branch"
                  onClick={(e) => { e.preventDefault(); toggleChangeLog(true); }}>
                    <strong>{websiteBuildStatus?.buildNumber || 'Loading...'}</strong>&nbsp;
                    {websiteBuildStatus?.triggerInfo?.message || 'Loading...'}
                </CalciteDropdownItem>
              )}
            </CalciteDropdownGroup>
            <CalciteDropdownGroup>
              <CalciteDropdownItem
                iconStart="users"
                onClick={openQuickView}>
                Online Users
              </CalciteDropdownItem>
              <CalciteDropdownItem
                iconStart="envelope"
                onClick={registerForPushNotifications}>
                  {pushNotificationRegistered ? 'Unregister from Push Notifications' : 'Register for Push Notifications'}
              </CalciteDropdownItem>
              <CalciteDropdownItem
                iconStart="erase"
                onClick={clearGridSettings}>
                  Clear Grid Settings
              </CalciteDropdownItem>
              <CalciteDropdownItem
                iconStart="erase"
                onClick={clearBrowserCache}>
                  Clear Browser Database Cache
              </CalciteDropdownItem>
            </CalciteDropdownGroup>
            <CalciteDropdownGroup>
              <CalciteDropdownItem
                iconStart="book"
                href="https://rapiddeploy.atlassian.net/wiki/spaces/PTD/pages/1425899548/RapidDeploy+Admin+Console"
                target="_blank"
                rel="noopener noreferrer">
                  Documentation
              </CalciteDropdownItem>
            </CalciteDropdownGroup>
            <CalciteDropdownGroup>
              <CalciteDropdownItem
                iconStart="sign-out"
                href="/#/logout">
                  Logout
              </CalciteDropdownItem>
            </CalciteDropdownGroup>
          </CalciteDropdown>
        )}
      </CalciteNavigation>
      <CalciteNavigation slot="header" className="calcite-mode-dark">
        <PageTitle />
        <Menu />
      </CalciteNavigation>
      <CalciteNavigation slot="header" className="calcite-mode-light">
        <Breadcrumbs />
      </CalciteNavigation>
    </>
  );
};

export default Header;

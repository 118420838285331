import { CalciteButton, CalciteDialog } from '@esri/calcite-components-react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import ControlledComboBox from '../UI/ControlledComboBox';

const StyledCalciteDialog = styled(CalciteDialog)`
    z-index: 1000 !important;
`;

const arrayRange = (start, stop, step) => Array.from(
    { length: (stop - start) / step + 1 },
    (_, index) => start + index * step,
  );

const backRange = arrayRange(1, 30, 1).map((value) => ({ value: `${value}`, label: `${value}` }));
const typeRange = [{ value: 'minute', label: 'Minutes' }, { value: 'hour', label: 'Hours' }, { value: 'day', label: 'Days' }];
const selectionRange = [{ value: 'minute', label: 'Per Minute' }, { value: 'hour', label: 'Per Hour' }, { value: 'day', label: 'Per Day' }];

const ReportValuesDialog = ({ reportValues, setReportValues, setShowDialog }) => {
    const {
        control, getValues,
    } = useForm({
        mode: 'onBlur',
        defaultValues: { ...reportValues },
    });

    const update = () => {
        setReportValues(getValues());
        setShowDialog(false);
    };

   return (
    <StyledCalciteDialog
        open={true}
        modal={true}
        heading="Update Date Filter">
            <ControlledComboBox
                name="back"
                control={control}
                clearDisabled={true}
                placeholder="Back"
                label="Back"
                options={backRange}
                selectionMode="single-persist"
            />
            <ControlledComboBox
                name="type"
                control={control}
                clearDisabled={true}
                placeholder="Type"
                label="Type"
                options={typeRange}
                selectionMode="single-persist"
            />
            <ControlledComboBox
                name="interval"
                control={control}
                clearDisabled={true}
                placeholder="Interval"
                label="Interval"
                options={selectionRange}
                selectionMode="single-persist"
            />
        <CalciteButton slot="footer-start" kind="neutral" onClick={() => setShowDialog(false)}>
            Cancel
        </CalciteButton>
        <CalciteButton slot="footer-end" onClick={update}>
            Update
        </CalciteButton>
    </StyledCalciteDialog>
   );
};

export default ReportValuesDialog;

import { CalcitePanel } from '@esri/calcite-components-react';
import dayjs from 'dayjs';
import { useCallback, useMemo, useRef } from 'react';
import { useEDGInvalidLogsForInvalidCoords } from '../../queries/edg/queries';
import { useCustomerGroups, useTenantGroups } from '../../queries/console/queries';
import Grid from '../grid';

const InvalidCoordinatesReport = ({ setPayloadJson }) => {
    const ref = useRef();
    const defaultColDef = useMemo(() => ({ filter: true }), []);

    const { invalidLogsLoading, invalidLogs } = useEDGInvalidLogsForInvalidCoords();
    const { tenantGroupsLoading, tenantGroups } = useTenantGroups();
    const { customerGroupsLoading, customerGroups } = useCustomerGroups();

    const { eccsLoading, eccs } = useMemo(() => {
        if (customerGroupsLoading || tenantGroupsLoading) return { eccsLoading: true, eccs: null };
        const customerEccIds = customerGroups.map(({ eccId }) => eccId);
        const tenantsAlreadyMigrated = tenantGroups.filter(({ eccId }) => !customerEccIds.includes(eccId));
        return { eccsLoading: false, eccs: [...tenantsAlreadyMigrated, ...customerGroups] };
    }, [customerGroupsLoading, tenantGroupsLoading, tenantGroups, customerGroups]);

    const tenantValueGetter = useCallback(({ data: { customerId, tenantId } }) => {
        if (eccsLoading) return [];

        const ecc = eccs.find((f) => f.eccId.toLowerCase() === tenantId.toLowerCase());

        return ecc
            ? ['valid:true', `tenantId:${tenantId}`, `name:${ecc.eccName}`, `env:${ecc.environmentName}`]
            : ['valid:false', `tenantId:${tenantId}`, `customerId:${customerId}`];
      }, [eccsLoading, eccs]);

      const tenantCellRenderer = useCallback(({ data: { customerId, tenantId } }) => {
        if (eccsLoading) return [];

        const ecc = eccs.find((f) => f.eccId.toLowerCase() === tenantId.toLowerCase());

        return ecc
            ? ecc.eccName
            : (
                <>
                    <span className={'label-danger label m-l-5 m-r-5 all-caps'}>{customerId}</span>
                    {tenantId}
                </>
                );
      }, [eccsLoading, eccs]);

    const columnDefs = useMemo(() => [
      {
        filter: 'agDateColumnFilter',
        headerName: 'Timestamp',
        field: 'timestamp',
        valueGetter: ({ data: { timestamp } }) => new Date(timestamp),
        cellRenderer: ({ data: { timestamp } }) => `${dayjs(timestamp).format()} - ${dayjs(timestamp).fromNow()}`,
      },
      {
        headerName: 'Device',
        field: 'deviceId',
      },
      {
        headerName: 'Tenant',
        field: 'tenantId',
        valueGetter: tenantValueGetter,
        cellRenderer: tenantCellRenderer,
      },
      {
        headerName: 'Latitude',
        field: 'latitude',
      },
      {
        headerName: 'Longitude',
        field: 'longitude',
      },
      {
        headerName: 'Custom Dimensions',
        field: 'customDimensions',
      },
    ]);

    const onCellClicked = ({ data: { customDimensions } }) => setPayloadJson(JSON.parse(customDimensions));

    return (
        <CalcitePanel heading='Invalid Coordinates Report' description='1 day back'>
          <Grid
              innerRef={ref}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              onCellClicked={onCellClicked}
              loading={invalidLogsLoading}
              rowData={invalidLogs} />
        </CalcitePanel>
    );
};

export default InvalidCoordinatesReport;

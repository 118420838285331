import { CalciteList, CalciteListItem } from '@esri/calcite-components-react';
import pluralize from 'pluralize';
import styled from 'styled-components';

const StyledLink = styled.a`
  text-decoration-style: unset !important;
  color: unset !important;
  font-weight: bold;
`;

const EnvironmentsBarLabel = ({
  label, name, count, liveCalls, showLink, link,
}) => (
  <CalciteList slot="content-start">
    <CalciteListItem>
      {showLink
      ? (<StyledLink href={link} slot="content-start" title={label}>{name.toUpperCase()}</StyledLink>)
      : (<StyledLink slot="content-start" title={label}>{name.toUpperCase()}</StyledLink>)}
      <div slot="content" title={`online users for ${name}`}>
        {count.toLocaleString()} {pluralize('user', count)}
        {liveCalls > 0 && (
          <div className="description" title={`live calls for ${name}`}>
            {liveCalls.toLocaleString()} active {pluralize('call', liveCalls)}
          </div>
        )}
      </div>
    </CalciteListItem>
  </CalciteList>
);

export default EnvironmentsBarLabel;

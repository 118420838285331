import { createQueryKeys } from '@lukemorales/query-key-factory';
import { post } from '../../services/webhelpers';

export const DirectionsQueryKeys = createQueryKeys('directions', {
    directions: (start, end) => ({
        queryKey: [start, end],
        queryFn: async () => {
            const { routes: [route] } = await post('/routing/directions', { start, end });
            return route;
        },
    }),
    reverseGeocodio: (latitude, longitude) => ({
        queryKey: [latitude, longitude],
        queryFn: async () => {
            const { results: [{ formatted_address: address }] } = await post('/geocoders/reverse/geocodio', { latitude, longitude });
            return address;
        },
    }),
    reverse: (latitude, longitude) => ({
        queryKey: [latitude, longitude],
        queryFn: () => post('/geocoders/reverse', { latitude, longitude }),
    }),
    forward: (streetNumber, street, city, state, country) => ({
        queryKey: [streetNumber, street, city, state, country],
        queryFn: () => post('/geocoders/forward', {
            streetNumber, street, city, state, country,
        }),
    }),
});

import { CalciteActionBar, CalciteActionGroup } from '@esri/calcite-components-react';
import ActionTooltip from '../UI/ActionTooltip';
import {
    setAddAreaDialogState, setAddClientDialogState, setAddEndpointDialogState,
    setUpdateChartClient,
    setUpdateClientDialogState,
} from '../../../../stores/clients';

const ClientActions = ({ client }) => {
    const addClient = () => {
        setAddClientDialogState({ parentClientId: client.clientId });
    };

    const addEndpoint = () => {
        setAddEndpointDialogState({ ...client });
    };

    const addArea = () => {
        setAddAreaDialogState({ ...client });
    };

    const showClientChart = () => {
        setUpdateChartClient({ clientId: client.clientId, name: client.name });
    };

    const startUpdatingClient = () => {
        setUpdateClientDialogState(client);
    };

    return (
        <CalciteActionBar
            slot="actions-end"
            layout="horizontal"
            scale="s"
            expandDisabled={true}
            overflowActionsDisabled={true}
        >
            <CalciteActionGroup>
                <ActionTooltip
                    visible={client.endpoints.length === 0}
                    icon="webhook"
                    tooltip="Add Endpoint"
                    state={client}
                    onClick={addEndpoint}
                />
                <ActionTooltip
                    visible={client.areas.length === 0}
                    icon="add-and-update-features"
                    tooltip="Add Area"
                    state={client}
                    onClick={addArea}
                />
                <ActionTooltip
                    visible={client.clients.length === 0}
                    icon="organization"
                    tooltip="Add Client"
                    state={client}
                    onClick={addClient}
                />
            </CalciteActionGroup>
            <CalciteActionGroup>
                <ActionTooltip
                    visible={true}
                    icon="graph-time-series"
                    tooltip="Show Client Metrics"
                    state={client}
                    onClick={showClientChart}
                />
                <ActionTooltip
                    visible={true}
                    icon="update-project"
                    tooltip="Update Client"
                    state={client}
                    onClick={startUpdatingClient}
                />
            </CalciteActionGroup>
        </CalciteActionBar>
    );
};

export default ClientActions;

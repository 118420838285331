import dayjs from 'dayjs';
import { useEffect, useMemo, useRef } from 'react';
import Grid from '../grid';

const UtilizationPivotGrid = ({
    columnDefs, loading, rowData, exportAction, autoGroupColumnDef,
}) => {
    const ref = useRef();
    const defaultColDef = useMemo(() => ({ filter: true }), []);

    useEffect(() => {
        if (!exportAction) return;
        ref.current?.api?.exportDataAsExcel();
    }, [exportAction]);

    const processPivotResultColGroupDef = (colGroupDef) => {
        colGroupDef.headerName = dayjs.unix(Number(colGroupDef.headerName)).format('MMM YYYY');
    };

    return <Grid
        innerRef={ref}
        autoGroupColumnDef={autoGroupColumnDef}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        pivotMode={true}
        loading={loading}
        suppressAggFuncInHeader={true}
        rowData={rowData}
        processPivotResultColGroupDef={processPivotResultColGroupDef}
    />;
};

export default UtilizationPivotGrid;

import {
 useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
 CalciteBlock, CalcitePanel, CalciteShell, CalciteShellPanel,
} from '@esri/calcite-components-react';
import dayjs from 'dayjs';
import { setPageTitle } from '../../services/pagescripts';
import ConsoleJsonEditor from '../consolejsoneditor';
import { useMqtt } from '../../customhooks/useMQTT';
import { useNcrStream } from '../../queries/edg/queries';
import Grid from '../grid';

const NormalizedCallRecordStream = ({ router }) => {
    const [environment, setEnvironment] = useState();

    useEffect(() => {
        setEnvironment(router.currentPage.params[0]);
    }, []);

    useEffect(() => {
        if (!environment) return;

        const label = environment.replaceAll('Ncr', '');
        setPageTitle(`Normalized Call Record Stream - ${label}`);
    }, [environment]);

    const [payloadJson, setPayloadJson] = useState({});
    const gridRef = useRef();
    const defaultColDef = useMemo(() => ({
        resizable: true,
        sortable: true,
        filter: true,
        enableCellChangeFlash: true,
    }), []);

    const columnDefs = useMemo(() => [
      {
        field: 'id',
        headerName: 'Id',
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        field: 'IngestionUnixTimestamp',
        headerName: 'Ingestion Timestamp',
        filter: 'agDateColumnFilter',
        cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
      },
      {
        field: 'CorrelationUnixTimestamp',
        headerName: 'Correlation Timestamp',
        filter: 'agDateColumnFilter',
        cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
      },
      {
        field: 'MessageId',
        headerName: 'MessageId',
      },
      {
        field: 'TimezoneOffset',
        headerName: 'Timezone Offset',
      },
      {
        field: 'Medium',
        headerName: 'Medium',
      },
      {
        field: 'ReplayTimestamp',
        headerName: 'Replay Timestamp',
      },
      {
        field: 'IsCorrection',
        headerName: 'Is Correction',
      },
      {
        field: 'CallId',
        headerName: 'CallId',
      },
      {
        field: 'SipCallId',
        headerName: 'Sip CallId',
      },
      {
        field: 'CallType',
        headerName: 'Call Type',
      },
      {
        field: 'Direction',
        headerName: 'Direction',
      },
      {
        field: 'Disposition',
        headerName: 'Disposition',
      },
      {
        field: 'Abandoned',
        headerName: 'Abandoned',
      },
      {
        field: 'OriginationType',
        headerName: 'Origination Type',
      },
      {
        field: 'Trunk',
        headerName: 'Trunk',
      },
      {
        field: 'Line',
        headerName: 'Line',
      },
      {
        field: 'Queue',
        headerName: 'Queue',
      },
      {
        headerName: 'Timestamps',
        children: [
          {
            field: 'SourceUnixTimestamp',
            headerName: 'Source',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'InitiatedUnixTimestamp',
            headerName: 'Initiated',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'StartUnixTimestamp',
            headerName: 'Start',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'EndUnixTimestamp',
            headerName: 'End',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'CallAnswerUnixTimestamp',
            headerName: 'Call Answer',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'CallArrivalUnixTimestamp',
            headerName: 'Call Arrival',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'CallDialUnixTimestamp',
            headerName: 'Call Dial',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'CallDisconnectUnixTimestamp',
            headerName: 'Call Disconnect',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'InitialQueueUnixTimestamp',
            headerName: 'Initial Queue',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'HoldStartUnixTimestamp',
            headerName: 'Hold Start',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'HoldRetrievedUnixTimestamp',
            headerName: 'Hold Retrieved',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Hold Elapsed',
        children: [
          {
            field: 'HoldElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'HoldElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
          {
            field: 'TotalHoldCount',
            headerName: 'Total Hold Count',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Answer Elapsed',
        children: [
          {
            field: 'AnswerElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'AnswerElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'CallTaker Process',
        children: [
          {
            field: 'CallTakerProcessElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'CallTakerProcessElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Psap Process',
        children: [
          {
            field: 'PsapProcessElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'PsapProcessElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Ring Elapsed',
        children: [
          {
            field: 'RingElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'RingElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Queue Elapsed',
        children: [
          {
            field: 'QueueElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'QueueElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Talk Elapsed',
        children: [
          {
            field: 'TalkElapsedTime',
            headerName: 'Time',
          },
          {
            field: 'TalkElapsedTimeMs',
            headerName: 'Time (ms)',
            cellRenderer: ({ value }) => value?.toLocaleString(),
          },
        ],
      },
      {
        headerName: 'Workstation',
        children: [
          {
            field: 'Position',
            headerName: 'Position',
          },
          {
            field: 'Workstation',
            headerName: 'Workstation',
          },
          {
            field: 'SystemId',
            headerName: 'SystemId',
          },
        ],
      },
      {
        headerName: 'Location',
        children: [
          {
            field: 'ANI',
            headerName: 'ANI',
          },
          {
            field: 'ContactName',
            headerName: 'Contact Name',
          },
          {
            field: 'pANI',
            headerName: 'pANI',
          },
          {
            field: 'StreetAddress',
            headerName: 'Street Address',
          },
          {
            field: 'StreetNumber',
            headerName: 'Street Number',
          },
          {
            field: 'City',
            headerName: 'City',
          },
          {
            field: 'Zipcode',
            headerName: 'Zip Code',
          },
          {
            field: 'ESN',
            headerName: 'ESN',
          },
          {
            field: 'ClassOfService',
            headerName: 'Class Of Service',
          },
          {
            field: 'Carrier',
            headerName: 'Carrier',
          },
          {
            field: 'Community',
            headerName: 'Community',
          },
          {
            field: 'Latitude',
            headerName: 'Latitude',
          },
          {
            field: 'Longitude',
            headerName: 'Longitude',
          },
          {
            field: 'HorizontalConfidence',
            headerName: 'Horizontal Confidence',
          },
          {
            field: 'HorizontalUncertainty',
            headerName: 'Horizontal Uncertainty',
          },
          {
            field: 'AltitudeInMeters',
            headerName: 'Altitude (m)',
          },
          {
            field: 'VerticalConfidence',
            headerName: 'Vertical Confidence',
          },
          {
            field: 'VerticalUncertainty',
            headerName: 'Vertical Uncertainty',
          },
          {
            field: 'CellId',
            headerName: 'CellId',
          },
          {
            field: 'SectorId',
            headerName: 'SectorId',
          },
          {
            field: 'PoliceElt',
            headerName: 'Police ELT',
          },
          {
            field: 'FireElt',
            headerName: 'Fire ELT',
          },
          {
            field: 'EmsElt',
            headerName: 'EMS ELT',
          },
        ],
      },
      {
        headerName: 'Transfer',
        children: [
          {
            field: 'IsTransferred',
            headerName: 'Is Transferred',
          },
          {
            field: 'TransferInitiatedUnixTimestamp',
            headerName: 'Transfer Initiated Time',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
          {
            field: 'TransferTargetNumber',
            headerName: 'Target Number',
          },
          {
            field: 'TransferTargetAgency',
            headerName: 'Target Agency',
          },
          {
            field: 'TransferFromAgency',
            headerName: 'From Agency',
          },
          {
            field: 'TransferFromIngestionId',
            headerName: 'From IngestionId',
          },
          {
            field: 'IsLanguageLineTransferred',
            headerName: 'Is Language Line Transferred',
          },
          {
            field: 'LanguageLineTransferInitiatedUnixTimestamp',
            headerName: 'Language Line Transfer Initiated Time',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          }, {
            field: 'LanguageLineTransferTarget',
            headerName: 'Language Line Transfer Target',
          },
        ],
      },
      {
        field: 'TextType',
        headerName: 'Text Type',
      },
      {
        field: 'Texts',
        headerName: 'Texts',
        cellRenderer: ({ value }) => value && Number(value.length).toLocaleString(),
      },
      {
        field: 'Dials',
        headerName: 'Dials',
      },
      {
        field: 'IsCallback',
        headerName: 'Is Callback',
      },
      {
        field: 'CustomerId',
        headerName: 'CustomerId',
      },
      {
        field: 'TenantId',
        headerName: 'TenantId',
      },
      {
        field: 'DeviceId',
        headerName: 'DeviceId',
      },
      {
        field: 'PsapId',
        headerName: 'PsapId',
      },
      {
        field: 'IngestionType',
        headerName: 'Ingestion Type',
      },
      {
        field: 'IngestionTimestamp',
        headerName: 'Ingestion Timestamp',
        filter: 'agDateColumnFilter',
        cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
      },
      {
        field: 'CorrelationTimestamp',
        headerName: 'Correlation Timestamp',
        filter: 'agDateColumnFilter',
        cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
      },
      {
        field: 'CorrelationId',
        headerName: 'CorrelationId',
      },
      {
        field: 'IngestionId',
        headerName: 'IngestionId',
      },
      {
        field: 'ParsedResultPolicyKey',
        headerName: 'Parsed Result Policy Key',
      },
      {
        field: 'Vendor',
        headerName: 'Vendor',
      },
      {
        field: 'Timezone',
        headerName: 'Timezone',
      },
      {
        field: 'SubscriptionId',
        headerName: 'SubscriptionId',
      },
      {
        field: 'JobInstanceId',
        headerName: 'JobInstanceId',
      },
      {
        field: 'RequestorId',
        headerName: 'RequestorId',
      },
      {
        field: 'IsDependency',
        headerName: 'Is Dependency',
      },
      {
        field: 'CallTakerId',
        headerName: 'CallTaker Id',
      },
      {
        field: 'CallTakerName',
        headerName: 'CallTaker Name',
      },
      {
        field: 'CallTakerRole',
        headerName: 'CallTaker Role',
      },
      {
        field: 'PsapName',
        headerName: 'Psap Name',
      },
    ]);

    const detailCellRendererParams = useMemo(() => ({
      detailGridOptions: {
        columnDefs: [
          { field: 'Message' },
          { field: 'Source' },
          { field: 'Station' },
          {
            field: 'Timestamp',
            filter: 'agDateColumnFilter',
            cellRenderer: ({ value }) => value && dayjs(value).toLocaleString(),
          },
        ],
      },
      getDetailRowData: ({ successCallback, data: { Texts } }) => successCallback(Texts ?? []),
    }), []);

    const getRowId = useCallback(({ data: { id } }) => id, []);
    const onCellClicked = useCallback(({ data }) => { setPayloadJson(data); }, []);

    const { streamLoading, stream } = useNcrStream(environment);

    const processMqttMessage = (item) => {
        const exists = stream?.some((a) => a.IngestionId === item.IngestionId);
        gridRef.current.api.applyTransaction(exists ? { update: [item] } : { add: [item] });
    };

    useMqtt(environment ? `ncr/logs/${environment}` : null, processMqttMessage, [environment, gridRef]);

    const onGridReady = () => {
        gridRef.current.api.applyColumnState({
            state: [{ colId: 'IngestionUnixTimestamp', sort: 'desc' }],
            defaultState: { sort: null },
        });
    };

    return (
        <CalciteShell>
            <CalciteShellPanel slot="panel-end" position="end" widthScale="l">
                <CalciteBlock open={true} heading='Payload'>
                    <ConsoleJsonEditor
                        data={payloadJson}
                        setData={setPayloadJson}
                        collapse={false}
                        restrictAdd={true}
                        restrictEdit={true}
                        restrictDelete={true} />
                </CalciteBlock>
            </CalciteShellPanel>
            <CalcitePanel>
              <Grid
                  innerRef={gridRef}
                  masterDetail={true}
                  defaultColDef={defaultColDef}
                  columnDefs={columnDefs}
                  detailCellRendererParams={detailCellRendererParams}
                  getRowId={getRowId}
                  onCellClicked={onCellClicked}
                  onGridReady={onGridReady}
                  loading={streamLoading}
                  rowData={stream}
              />
            </CalcitePanel>
        </CalciteShell>
    );
};

export default NormalizedCallRecordStream;

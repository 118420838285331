import { post, put, sendDelete } from '../../services/webhelpers';
import { queries } from '../queries';
import { useQueryWithPrefixedResults } from '../../customhooks/usequerywithprefixedresults';
import { useUpdate } from '../../customhooks/useupdate';

const addClient = ({ env, parentClientId, name }) => post('/ELS/Clients', { parentClientId, name }, { env });

const updateClient = ({
    env, clientId, name, hashKey, enabled,
}) => put('/ELS/Clients', {
    clientId, name, hashKey, enabled,
}, { env });

const addEndpoint = ({
    env, clientId, name, url, headers,
}) => post('/ELS/Endpoints', {
    clientId, name, url, headers,
}, { env });

const updateEndpoint = ({
    env, endpointId, clientId, name, url, headers, enabled,
}) => put('/ELS/Endpoints', {
    endpointId, clientId, name, url, headers, enabled,
}, { env });

const deleteEndpoint = ({ env, endpointId }) => sendDelete(`/ELS/Endpoints/${endpointId}`, { env });

const addArea = async ({
    env, clientId, name, area,
}) => post('/ELS/Areas', { clientId, name, area }, { env });

const updateArea = ({
    env, areaId, name, enabled, areaStatusId,
}) => put('/ELS/Areas', {
    areaId, name, enabled, areaStatusId,
}, { env });

const deleteArea = ({ env, areaId }) => sendDelete(`/ELS/Areas/${areaId}`, { env });

export const useInboundMetrics = (env, back, type, interval) => useQueryWithPrefixedResults('inboundMetrics', queries.elsCharts.inbound(env, back, type, interval));
export const useOutboundMetrics = (env, clientId, back, type, interval) => useQueryWithPrefixedResults('outboundMetrics', queries.elsCharts.outbound(env, clientId, back, type, interval));

export const useArea = (env, areaId) => useQueryWithPrefixedResults('area', queries.elsArea.detail(env, areaId));
export const useAreaSize = (env, payload, type) => useQueryWithPrefixedResults('areaSize', queries.elsArea.shape(env, payload, type));
export const useStatuses = (env) => useQueryWithPrefixedResults('statuses', queries.elsStatuses.all(env));
export const useClients = (env) => useQueryWithPrefixedResults('clients', queries.elsClients.all(env));

export const useAddArea = () => useUpdate(addArea, queries.elsClients._def);
export const useUpdateArea = (areaId) => useUpdate(updateArea, [queries.elsClients._def, queries.elsArea.detail(areaId)._def]);
export const useDeleteArea = () => useUpdate(deleteArea, [queries.elsClients._def]);

export const useAddClient = () => useUpdate(addClient, queries.elsClients._def);
export const useUpdateClient = () => useUpdate(updateClient, queries.elsClients._def);

export const useAddEndPoint = () => useUpdate(addEndpoint, queries.elsClients._def);
export const useUpdateEndpoint = () => useUpdate(updateEndpoint, queries.elsClients._def);
export const useDeleteEndpoint = () => useUpdate(deleteEndpoint, queries.elsClients._def);

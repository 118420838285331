import { useEffect, useState } from 'react';
import DeviceStream from './devicestream';
import Header from './header';
import QuickView from './quickview';
import OverlaySearch from './overlaysearch';
import Notifications from './notifications';
import EDGDeviceInfo from './edgdeviceinfo';
import ConsoleQueryProvider from '../queries/ConsoleQueryProvider';
import ChangeLog from './changelog';
import { deviceInfoChannel, mqttChannel, pageChannel } from '../instances/channels';
import { useShowChangeLog } from '../stores/changelog';
import { useShowQuickView } from '../stores/quickview';
import { usePostalEvent } from '../customhooks/usePostalEvent';
import { GetInstance } from '../services/helperscripts';
import { InstanceNames } from '../instances/names';

const messagingInstance = await GetInstance(InstanceNames.Messaging);

const App = () => {
  const showChangeLog = useShowChangeLog();
  const showQuickView = useShowQuickView();

  const [deviceInfoState, setDeviceInfoState] = useState();
  const [deviceStreamsState, setDeviceStreamsState] = useState();

  useEffect(() => {
    const subscription = deviceInfoChannel.subscribe('load-device-info', (device) => {
      setDeviceInfoState(device);
    });

    return () => {
      setDeviceInfoState(null);
      subscription.unsubscribe();
    };
  }, []);

  usePostalEvent(pageChannel, 'startDeviceStream', (request) => {
    setDeviceStreamsState({ ...request });
  });

  const [mqttStatus, setMqttStatus] = useState(null);
  const [connectionId, setConnectionId] = useState(null);

  usePostalEvent(mqttChannel, 'connectStatus', (status) => {
    setMqttStatus(status);
  });

  useEffect(() => {
    if (mqttStatus !== 'connected') {
      setConnectionId(null);
      return;
    }
    setConnectionId(messagingInstance.client.options.clientId);
    return () => {
      setConnectionId(null);
    };
  }, [mqttStatus]);

  return (
    <ConsoleQueryProvider>
      <Notifications />
      {deviceStreamsState && (
        <DeviceStream
          connectionId={connectionId}
          iotHubId={deviceStreamsState.iotHubId}
          deviceId={deviceStreamsState.deviceId}
        />
      )}
      {deviceInfoState && (
        <EDGDeviceInfo
          showModal={deviceInfoState}
          iotHubId={deviceInfoState.ioTHubId}
          deviceId={deviceInfoState.deviceId}
          onClose={() => setDeviceInfoState(null)} />
      )}
      {showQuickView && (<QuickView />)}
      <Header />
      <OverlaySearch />
      {showChangeLog && (<ChangeLog />)}
      <span className="hide">
        <audio id="audio-player" controls="" src="/sounds/arpeggio.mp3"></audio>
      </span>
    </ConsoleQueryProvider>
  );
};

export default App;

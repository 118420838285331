import {
    CalciteTable, CalciteTableCell, CalciteTableHeader, CalciteTableRow,
} from '@esri/calcite-components-react';
import styled from 'styled-components';

const StyledCalciteTable = styled(CalciteTable)`
    width: 100%;
`;

const HeadersTable = ({ clientId }) => <StyledCalciteTable slot="content-bottom">
    <CalciteTableRow slot='table-header'>
        <CalciteTableHeader heading='Header' />
        <CalciteTableHeader heading='Value' />
    </CalciteTableRow>
    <CalciteTableRow>
        <CalciteTableCell>x-clientid</CalciteTableCell>
        <CalciteTableCell>{clientId}</CalciteTableCell>
    </CalciteTableRow>
</StyledCalciteTable>;

export default HeadersTable;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import { get } from '../../services/webhelpers';

export const edgQueryKeys = createQueryKeys('edg', {
    ncr: (environment) => ({
        queryKey: [environment],
        queryFn: () => {
            if (!environment) return Promise.resolve([]);
            return get(`/reports/ncr/${environment}`);
        },
    }),
    notes: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: () => get(`/devices/${iotHubId}/${deviceId}/notes`),
    }),
    statuses: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: () => get(`/devices/${iotHubId}/${deviceId}/statuses`),
    }),
    ping: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: () => get(`/devices/${iotHubId}/${deviceId}/ping`),
    }),
    device: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: () => get(`/devices/${iotHubId}/${deviceId}`),
    }),
    interfaces: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: async () => {
            const data = await get(`/devices/${iotHubId}/${deviceId}/interfaces`);
            return Object.entries(data).map(([key, value]) => ({ key, values: Object.entries(value).filter(([, b]) => b) }));
        },
    }),
    components: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: async () => {
            const { components } = await get(`/devices/${iotHubId}/${deviceId}/config`);
            return components;
        },
    }),
    gridEvents: (iotHubId, deviceId, slug) => ({
        queryKey: [iotHubId, deviceId, slug],
        queryFn: () => get(`/devices/${iotHubId}/${deviceId}/${slug}`),
    }),
    parser: (iotHubId, deviceId, parserId) => ({
        queryKey: [iotHubId, deviceId, parserId],
        queryFn: () => get(`/devices/${iotHubId}/${deviceId}/parser/${parserId}`),
    }),
    hasSavedCredentials: (iotHubId, deviceId) => ({
        queryKey: [iotHubId, deviceId],
        queryFn: async () => {
            const { success } = await get(`/devices/${iotHubId}/${deviceId}/has-saved-credentials`);
            return success;
        },
    }),
    invalidLogsForSourceDateTime: () => ({
        queryKey: [],
        queryFn: () => get('/devices/invalid-logs/source-datetime'),
    }),
    invalidLogsForNoAgentId: () => ({
        queryKey: [],
        queryFn: () => get('/devices/invalid-logs/no-agentid'),
    }),
    invalidLogsForInvalidCoords: () => ({
        queryKey: [],
        queryFn: () => get('/devices/invalid-logs/coordinates'),
    }),
});

import { CalciteLink, CalcitePanel } from '@esri/calcite-components-react';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useHubSpotTenants, useRadiusEccs, useRadiusMonthlyUtilization } from '../../queries/utilization/queries';
import UtilizationGrid from './UtilizationGrid';
import { getHubSpotCompanyLink } from '../../services/hubspot';

const RadiusUtilization = ({
    startDate, endDate, onCellClicked, pivot,
}) => {
    const { hubspotLoading, hubspot } = useHubSpotTenants();
    const { eccsLoading, eccs } = useRadiusEccs();
    const { reportLoading, report } = useRadiusMonthlyUtilization(startDate, endDate);

    const columnDefs = useMemo(() => (
        pivot
            ? [
                {
                    headerName: 'Ecc',
                    field: 'tenantId',
                    rowGroup: true,
                    enableRowGroup: true,
                },
                {
                    headerName: 'Date',
                    filter: 'agDateColumnFilter',
                    field: 'date',
                    valueGetter: ({ data: { date } }) => dayjs(date).unix(),
                    pivotComparator: (a, b) => b - a,
                    pivot: true,
                },
                {
                    headerName: 'Users',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    valueGetter: ({ data: { avgUsers } }) => Math.round(avgUsers),
                    aggFunc: 'sum',
                    cellStyle: ({ value, node: { key: tenantId } }) => {
                        const company = hubspot?.find((hs) => hs.tenantId === tenantId);
                        if (!company || !(company.concurrentSeats || company.seats)) return { color: '#000000', backgroundColor: '#dfdfdf' };
                        return (value > Number(company.concurrentSeats || company.seats)) && { color: '#ffffff', backgroundColor: '#d90012' };
                    },
                },
            ]
            : [
                {
                    headerName: 'Date',
                    filter: 'agDateColumnFilter',
                    cellRenderer: ({ value }) => dayjs(value).format('MMM YYYY'),
                    valueGetter: ({ data: { date } }) => dayjs(date).toDate(),
                },
                {
                    headerName: 'EccId',
                    hide: true,
                    field: 'tenantId',
                },
                {
                    headerName: 'Ecc',
                    valueGetter: ({ data: { tenantId } }) => eccs?.find(({ eccId }) => eccId === tenantId)?.name,
                    cellRenderer: ({ value, data: { tenantId } }) => {
                        const company = hubspot?.find((hs) => hs.tenantId === tenantId);
                        return !company
                            ? value
                            : (
                                <CalciteLink href={getHubSpotCompanyLink(company.id)} target="_blank" rel="noreferrer">
                                    {value}
                                </CalciteLink>
                            );
                    },
                },
                {
                    headerName: 'HubSpot Seats',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    valueGetter: ({ data: { tenantId } }) => hubspot?.find((hs) => hs.tenantId === tenantId)?.seats,
                },
                {
                    headerName: 'HubSpot Concurrent Seats',
                    filter: 'agNumberColumnFilter',
                    type: 'rightAligned',
                    valueGetter: ({ data: { tenantId } }) => hubspot?.find((hs) => hs.tenantId === tenantId)?.concurrentSeats,
                },
                {
                    headerName: 'Average',
                    children: [
                        {
                            headerName: 'Average Users',
                            filter: 'agNumberColumnFilter',
                            type: 'rightAligned',
                            valueGetter: ({ data: { avgUsers } }) => Math.round(avgUsers),
                            cellStyle: ({ value, data: { tenantId } }) => {
                                const company = hubspot?.find((hs) => hs.tenantId === tenantId);
                                if (!company || !(company.concurrentSeats || company.seats)) return { color: '#000000', backgroundColor: '#dfdfdf' };
                                return (value > Number(company.concurrentSeats || company.seats)) && { color: '#ffffff', backgroundColor: '#d90012' };
                            },
                        },
                    ],
                },
                {
                    headerName: 'Min',
                    children: [
                        {
                            headerName: 'Users',
                            filter: 'agNumberColumnFilter',
                            type: 'rightAligned',
                            valueGetter: ({ data: { minUsers } }) => Math.round(minUsers),
                            cellStyle: ({ value, data: { tenantId } }) => {
                                const company = hubspot?.find((hs) => hs.tenantId === tenantId);
                                if (!company || !(company.concurrentSeats || company.seats)) return { color: '#000000', backgroundColor: '#dfdfdf' };
                                return (value > Number(company.concurrentSeats || company.seats)) && { color: '#ffffff', backgroundColor: '#d90012' };
                            },
                        },
                        {
                            headerName: 'Time',
                            cellRenderer: ({ value }) => dayjs(value).format('L LTS'),
                            valueGetter: ({ data: { minTimeStamp } }) => dayjs(minTimeStamp).toDate(),
                        },
                    ],
                },
                {
                    headerName: 'Max',
                    children: [{
                        headerName: 'Users',
                        filter: 'agNumberColumnFilter',
                        type: 'rightAligned',
                        valueGetter: ({ data: { maxUsers } }) => Math.round(maxUsers),
                        cellStyle: ({ value, data: { tenantId } }) => {
                            const company = hubspot?.find((hs) => hs.tenantId === tenantId);
                            if (!company || !(company.concurrentSeats || company.seats)) return { color: '#000000', backgroundColor: '#dfdfdf' };
                            return (value > Number(company.concurrentSeats || company.seats)) && { color: '#ffffff', backgroundColor: '#d90012' };
                        },
                    },
                    {
                        headerName: 'Time',
                        cellRenderer: ({ value }) => dayjs(value).format('L LTS'),
                        valueGetter: ({ data: { maxTimeStamp } }) => dayjs(maxTimeStamp).toDate(),
                    }],
                },
            ]
    ), [pivot, eccs, report, hubspot]);

    const autoGroupColumnDef = useMemo(() => ({
        headerName: 'ECC',
        minWidth: 400,
        cellRendererParams: {
            suppressCount: true,
            innerRenderer: ({ value }) => {
                const ecc = eccs?.find(({ eccId }) => eccId === value);
                const company = hubspot?.find((hs) => hs.tenantId === value);
                return !company ? ecc?.name : (
                    <>
                        <CalciteLink
                            href={getHubSpotCompanyLink(company.id)}
                            target="_blank"
                            rel="noreferrer">
                            {ecc?.name}
                        </CalciteLink>
                        {(company.concurrentSeats || company.seats)
                            ? (<span className="label label-default m-l-10" title="Hubspot Seat Count">{company.concurrentSeats || company.seats}</span>)
                            : (<span className="label label-danger m-l-10" title="Hubspot Seat Count">-</span>)}
                    </>
                );
            },
        },
    }));

    return (
        <CalcitePanel
            heading='Radius Utilization'
            description={`${dayjs(startDate).format('MMM YYYY')} to ${dayjs(endDate).format('MMM YYYY')}`}
            loading={reportLoading || eccsLoading || hubspotLoading || undefined}>
            <UtilizationGrid
                pivot={pivot}
                columnDefs={columnDefs}
                autoGroupColumnDef={autoGroupColumnDef}
                loading={reportLoading && eccsLoading && hubspotLoading}
                rowData={report}
                removePivotHeaderRowWhenSingleValueColumn={true}
                onCellClicked={onCellClicked} />
        </CalcitePanel>
    );
};

export default RadiusUtilization;

import { CalciteAction, CalciteTooltip } from '@esri/calcite-components-react';
import {
    useCallback, useEffect, useId, useRef,
} from 'react';

const ActionTooltip = (props) => {
    const id = useId();

    const ref = useRef(null);

    // Hack until arcgis tree control is fixed
    const toggleClickInvoked = useCallback(() => {
        props.onClick();
    }, [props]);

    useEffect(() => {
        if (!ref.current || props.slot) {
            return;
        }

        const buttonElement = ref.current;

        buttonElement.addEventListener('click', toggleClickInvoked);

        return () => buttonElement.removeEventListener('click', toggleClickInvoked);
    }, [props, toggleClickInvoked]);

    return (props.visible ?? true) && (
        <>
            <CalciteAction
                id={id}
                ref={ref}
                scale="s"
                {...props}
            />
            <CalciteTooltip
                referenceElement={id}
                overlayPositioning="fixed">
                {props.tooltip}
            </CalciteTooltip>
        </>
    );
};

export default ActionTooltip;

import styled from 'styled-components';

const RequiredSpan = styled.span`
  color: red;
  margin-left: 5px;
`;

const RequiredIcon = () => (<RequiredSpan>*</RequiredSpan>);

export default RequiredIcon;

import { createQueryKeys } from '@lukemorales/query-key-factory';
import dayjs from 'dayjs';
import { get, post } from '../../services/webhelpers';

export const callHandlingQueryKeys = createQueryKeys('callHandling', {
    status: () => ({
        queryKey: ['status'],
        queryFn: () => get('/reports/callhandling/sync'),
    }),
    summary: (startDate, endDate) => ({
        queryKey: [startDate, endDate],
        queryFn: () => post('/reports/callhandling/summary', {
            startDate: dayjs(startDate).startOf('day').toJSON(),
            endDate: dayjs(endDate).endOf('day').toJSON(),
        }),
    }),
});

import { CalciteBlock } from '@esri/calcite-components-react';
import styled from 'styled-components';

const FullHeightCalciteBlock = styled(CalciteBlock)`
  height: ${(props) => {
    let defaultHeight = '720px;';

    try {
      const { panel, block } = props;
      const panelDimensions = panel?.getBoundingClientRect();
      const blockDimensions = block?.getBoundingClientRect();

      if (panelDimensions && blockDimensions) {
        defaultHeight = `${panelDimensions.height - 70}px`;
      }
    } catch (error) { }

    return defaultHeight;
  }}
`;

export default FullHeightCalciteBlock;

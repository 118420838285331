import { useState } from 'react';
import {
 CalciteActionBar, CalciteMenu, CalciteNavigation,
} from '@esri/calcite-components-react';
import { v4 as uuidv4 } from 'uuid';
import Breadcrumb from './breadcrumb';
import { usePostalEvent } from '../../customhooks/usePostalEvent';
import { pageChannel } from '../../instances/channels';

const Breadcrumbs = () => {
  const [breadcrumbsLeft, setBreadcrumbsLeft] = useState([]);
  const [breadcrumbsRight, setBreadcrumbsRight] = useState([]);
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(true);

  usePostalEvent(pageChannel, 'setBreadcrumbs', ({ left, right }) => {
    const leftItems = (left ?? []).map((item) => (typeof item === 'object' ? item : { text: item }));
    const rightItems = (right ?? []).map((item) => (typeof item === 'object' ? item : { text: item }));

    setShowBreadcrumbs(true);
    setBreadcrumbsLeft(leftItems);
    setBreadcrumbsRight(rightItems);
  });

  usePostalEvent(pageChannel, 'showBreadcrumbs', setShowBreadcrumbs);

  return showBreadcrumbs && (
    <CalciteNavigation slot="navigation-tertiary">
      <CalciteMenu slot="content-start">
        {breadcrumbsLeft.map((item) => <Breadcrumb key={uuidv4()} item={item} />)}
      </CalciteMenu>
      <CalciteActionBar
        slot="content-end"
        expandDisabled={true}
        expanded={true}
        overflowActionsDisabled={true}>
        {breadcrumbsRight.map((item) => <Breadcrumb key={uuidv4()} item={item} />)}
      </CalciteActionBar>
    </CalciteNavigation>
  );
};

export default Breadcrumbs;

import { useStore } from '@tanstack/react-store';
import {
    setStoreStateValues, store, STORE_NAME,
} from './store';

export const useGetArea = () => useStore(store, ({ [STORE_NAME.CLIENTS]: clients }) => clients?.area);

export const useGetGeoJSON = () => useStore(store, ({ [STORE_NAME.CLIENTS]: clients }) => clients?.geojson);

export const useGetClients = () => useStore(store, ({ [STORE_NAME.CLIENTS]: clients }) => clients);

export const useShouldShowAddArea = () => useStore(store, ({ [STORE_NAME.CLIENTS]: clients }) => clients?.showAddArea);

export const useShouldShowUpdateArea = () => useStore(store, ({ [STORE_NAME.CLIENTS]: clients }) => clients?.showUpdateArea);

export const setAddClientDialogState = (payload) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        parentClientId: payload?.parentClientId,
        showAddClient: payload != null,
    });
};

export const setUpdateClientDialogState = (client) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        client,
        showUpdateClient: client != null,
    });
};

export const setUpdateChartClient = (chartClient) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        chartClient,
    });
};

export const setAddEndpointDialogState = (client) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        client,
        showAddEndpoint: client != null,
    });
};

export const setUpdateEndPointDialogState = (endpoint) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        endpoint,
        showUpdateEndpoint: endpoint != null,
    });
};

export const setAddAreaDialogState = (client) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        client,
        geojson: null,
        showAddArea: client != null,
        showUpdateArea: false,
    });
};

export const setUpdateAreaDialogState = (payload) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        client: payload?.client,
        area: payload?.area,
        geojson: null,
        showAddArea: false,
        showUpdateArea: payload != null,
    });
};

export const setArea = (geojson) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        geojson,
        areaIsValid: false,
        error: null,
    });
};

export const setAreaState = ({ area, areaIsValid, error }) => {
    setStoreStateValues(STORE_NAME.CLIENTS, {
        geojson: area,
        areaIsValid,
        error,
    });
};

import { CalcitePanel } from '@esri/calcite-components-react';
import {
    ArcgisBasemapGallery, ArcgisExpand, ArcgisMap, ArcgisSearch,
} from '@arcgis/map-components-react';
import {
    useEffect, useRef, useState,
} from 'react';
import esriConfig from '@arcgis/core/config';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import SimpleRenderer from '@arcgis/core/renderers/SimpleRenderer';
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import FullHeightCalciteBlock from '../UI/FullHeightCalciteBlock';
import { createBlobUrl } from '../utility/createBlobUrl';
import { updateAlertState } from '../../../../stores/alerts';
import { setAreaState, useGetGeoJSON } from '../../../../stores/clients';
import { useAreaSize } from '../../../../queries/els/queries';
import { useGetEnvironment } from '../../../../stores/environment';

const getSimpleFillRenderer = (color, lineColor) => new SimpleRenderer({
    symbol: new SimpleFillSymbol({
        color,
        outline: new SimpleLineSymbol({
            color: lineColor || color,
            width: 1,
        }),
    }),
});

const MapPanel = () => {
    const env = useGetEnvironment();
    const panelRef = useRef();
    const blockRef = useRef();
    const [map, setMap] = useState(null);
    const [view, setView] = useState(null);

    const geojson = useGetGeoJSON();

    useEffect(() => {
        esriConfig.apiKey = process.env.ARCGIS_ADMINCONSOLE_APIKEY;
        esriConfig.portalUrl = process.env.ARCGIS_PORTALURL;
    }, []);

    const onArcgisViewReadyChange = ({ target }) => {
        setMap(target.map);
        setView(target.view);
    };

    const { areaSize, areaSizeIsFetching } = useAreaSize(env, geojson, 'geojson');

    useEffect(() => {
        if (!map || !geojson || areaSizeIsFetching || !areaSize) return;

        const geofencesLayer = new GeoJSONLayer({
            url: createBlobUrl(geojson, 'application/json'),
            renderer: getSimpleFillRenderer([0, 97, 155, 0.6], [255, 255, 255]),
        });
        geofencesLayer.on('layerview-create', (event) => {
            if (event.layerView.layer.geometryType !== 'polygon') {
                map.remove(geofencesLayer);
                updateAlertState('Error', 'This layer does not contain polygons.', 'danger', 'top');
                setAreaState({ area: geojson, areaIsValid: false, error: 'This layer does not contain polygons.' });
            }

            if (areaSize > 28941418464412) {
                setAreaState({ area: geojson, areaIsValid: false, error: 'Area is too large. Is the right-hand rule being applied?' });
            } else {
                setAreaState({ area: geojson, areaIsValid: true });
            }

            view.goTo(event.layerView.layer.fullExtent, { animate: false })
                .catch((error) => {
                    if (error.name != 'AbortError') {
                        console.error(error);
                    }
                });
        });

        map.add(geofencesLayer);

        return () => {
            map.remove(geofencesLayer);
        };
    }, [geojson, map, view, areaSizeIsFetching, areaSize]);

    return (
        <CalcitePanel
            ref={panelRef}
            heading="Coverage Map"
        >
            <FullHeightCalciteBlock
                ref={blockRef}
                panel={panelRef.current}
                block={blockRef.current}
                open={true}
            >
                <ArcgisMap
                    basemap={'streets-vector'}
                    center={[-97.72787, 30.2611423]}
                    zoom={12}
                    onArcgisViewReadyChange={onArcgisViewReadyChange}
                >
                    <ArcgisExpand position="bottom-left">
                        <ArcgisBasemapGallery />
                    </ArcgisExpand>
                    <ArcgisSearch position="top-right" />
                </ArcgisMap>
            </FullHeightCalciteBlock>
        </CalcitePanel>
    );
};

export default MapPanel;

import { Store } from '@tanstack/react-store';

export const STORE_NAME = {
    ENVIRONMENT: 'environment',
    ALERTS: 'alerts',
    DIALOGS: 'dialogs',
    CHANGE_LOG: 'changeLog',
    EDG_DEVICE_INFO: 'edgDeviceInfo',
    QUICK_VIEW: 'quickView',
    CLIENTS: 'clients',
};

export const store = new Store({
    [STORE_NAME.ENVIRONMENT]: {
        env: null,
    },
    [STORE_NAME.ALERTS]: {
        alert: null,
    },
    [STORE_NAME.DIALOGS]: {
        confirm: null,
    },
    [STORE_NAME.CHANGE_LOG]: {
        show: false,
    },
    [STORE_NAME.EDG_DEVICE_INFO]: {
        iotHubId: null,
        deviceId: null,
        show: false,
    },
    [STORE_NAME.QUICK_VIEW]: {
        show: false,
    },
    [STORE_NAME.CLIENTS]: {
        parentClientId: null,
        showUpdateClient: false,
        showAddClient: false,
        showAddEndpoint: false,
        showUpdateEndpoint: false,
        showAddArea: false,
        showUpdateArea: false,
        client: null,
        endpoint: null,
        area: null,
        geojson: null,
        areaIsValid: null,
        chartClient: null,
    },
});

export const setStoreState = (node, key, value) => {
    store.setState((state) => ({
        ...state,
        [node]: {
            ...state[node],
            [key]: value,
        },
    }));
};

export const setStoreStateValues = (node, values) => {
    store.setState((state) => ({
        ...state,
        [node]: {
            ...state[node],
            ...values,
        },
    }));
};
